import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $get,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const productName = {
  idot: "WondersLab AI Assistant - Idot",
  sdot: "WondersLab AI Assistant - Sdot",
  wdot: "WondersLab AI Assistant - Wdot",
  p1: "WondersLab AI Assistant - Package",
};

const funcData = {
  wdot: [
    "21 Types of Documents",
    "Table of Contents",
    "Title Generation",
    "Image Generation",
    "Research",
    "Memo",
    "Replacement Sentence",
    "Make Longer",
    "Make Shorter",
    "List Writing",
    "Visualize as a table",
    "Continue Writing",
    "Request",
    "Link Analysis",
    "Statistical Image Analysis",
    "File Analysis",
    "SEO",
    "PDF",
    "DOCX",
    "Multilingual Support",
    "Editor Functionality",
  ],
  idot: [
    "Photography 36 types",
    "Illustration 9 types",
    "Drawing 5 types",
    "Interior 22 types",
    "Exterior19 types",
    "Multilingual Support",
  ],
  sdot: [
    "Project Generation",
    "Today's Content Topic Suggestions",
    "Feed Content Recommendations",
    "Generate Instagram Feed",
    "Generate Facebook Feed",
    "Generate LinkedIn Feed",
    "Generate Shortform Scenario",
    "Generate Video Scenario",
    "Make Longer",
    "Make Shorter",
    "Translate",
    "Multilingual Support",
  ],
  p1: [
    "All functions of Wdot",
    "All functions of Idot",
    "All functions of Sdot",
  ],
};

function Pricing() {
  const appServerUrl = process.env.REACT_APP_SERVER_URL;
  const userLocalLang = navigator.language || navigator.userLanguage;
  // const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoggedIn, userData, login, logout } = useAuth();
  const [priceType, setPriceType] = useState("yearly");
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [currency, setCurrency] = useState("USD");

  const getUserAssistantList = async () => {
    const assistantList = (await $get("/api/subscription")).data;
    return assistantList;
  };

  const getAssistantMasterList = async () => {
    const assistantMasterList = (await $get("/api/assistant")).data;

    if (userData && isLoggedIn) {
      const myAssistantList = await getUserAssistantList();
      // assistantMasterList에서 myAssistantListt에 있는 assistant_code에 대해서는 assistantMasterList에 subscription_yn을 Y로 설정
      assistantMasterList.forEach((item) => {
        const myAssistant = myAssistantList.find(
          (x) => x.assistant_code === item.code
        );
        if (myAssistant) {
          item.subscription_yn = "Y";
        } else {
          item.subscription_yn = "N";
        }
      });
    } else {
      assistantMasterList.forEach((item) => {
        item.subscription_yn = "N";
      });
    }

    setSubscriptionList(assistantMasterList);
  };

  // const setSalesPartnerKey = async (salesPartnerKey) => {
  //   const result = await $put("/api/user/sales-partner-key", {
  //     sales_partner_key: salesPartnerKey,
  //   });
  // };

  // const getAssistantMasterList = async () => {
  //   const assistantMasterList = (await $get("/api/assistant-master")).data;
  //   setSubscriptionList(assistantMasterList);
  // };

  useEffect(() => {
    getAssistantMasterList();

    if (userLocalLang.startsWith("ko")) {
      setCurrency("KRW");
    } else {
      setCurrency("USD");
    }
  }, []);

  return (
    <main>
      <div className="pattern-square"></div>
      <section className="py-5 py-lg-8">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-12 col-12">
              <div className="text-center">
                <h1 className="mb-1 mb-lg-3">Get Started Today</h1>
                <p>
                  Please refer to the{" "}
                  <a
                    href="#"
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    link
                  </a>{" "}
                  for our refund policy regarding subscription cancellations.
                </p>
                {/* {currency === "KRW" && (
                  <p>
                    해외 신용카드 소지자는 여기를{" "}
                    <a
                      onClick={() => setCurrency("USD")}
                      style={{
                        textDecoration: "underline",
                        color: "var(--w-primary-main)",
                      }}
                    >
                      (해외카드결제)
                    </a>{" "}
                    클릭하세요.
                  </p>
                )} */}
                <p>
                  Once you complete the subscription, the account will be
                  activated for the subscribed service with the email account
                  you are currently logged in with.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-4 mt-lg-n8">
        <div className="container price-wrapper">
          {subscriptionList.length > 0 && (
            <div className="container price-wrapper">
              <div className="row">
                <div className="col-12 z-1">
                  <div className="mb-5">
                    <div
                      className="price-switcher-wrapper switcher"
                      onClick={() => {
                        setPriceType(
                          priceType === "monthly" ? "yearly" : "monthly"
                        );
                      }}
                    >
                      <p className="mb-0 pe-3">Monthly</p>
                      <div className="price-switchers">
                        <div
                          className={
                            priceType === "monthly"
                              ? "price-switcher price-switcher-active"
                              : "price-switcher"
                          }
                        ></div>
                        <div
                          className={
                            priceType === "yearly"
                              ? "price-switcher price-switcher-active"
                              : "price-switcher"
                          }
                        ></div>
                        <div className="switcher-button bg-primary"></div>
                      </div>
                      <p className="mb-0 ps-3">Yearly(Save 20% off)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive-xl">
                <div className="row pb-4 me-5 me-lg-0">
                  {subscriptionList.map((item, index) => (
                    <div
                      className="col-lg-3 col-md-6 col-12 mb-3"
                      key={`subscription-${index}`}
                    >
                      <div className="card pricing">
                        <div className="card-body">
                          {item.code === "p1" && (
                            <span class="badge text-bg-warning text-uppercase py-2 mb-2">
                              extra {priceType === "monthly" ? "20%" : "39%"}{" "}
                              off
                            </span>
                          )}
                          <h2 className="d-flex align-items-center">
                            <div className="price-text">
                              <div
                                className={
                                  priceType === "monthly"
                                    ? "price price-show"
                                    : "price price-hide price-hidden"
                                }
                              >
                                {currency === "USD" && <span>$</span>}
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {currency === "USD"
                                    ? item.price_monthly
                                    : $convertNumberFormat(
                                        item.price_won_monthly
                                      ) + "원"}
                                </span>
                                <span className="price-duration fs-6 text-body mt-auto mb-2">
                                  /month
                                </span>
                              </div>
                              <div
                                className={
                                  priceType === "yearly"
                                    ? "price price-show"
                                    : "price price-hide price-hidden"
                                }
                              >
                                {currency === "USD" && <span>$</span>}
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {currency === "USD"
                                    ? item.price_yearly
                                    : $convertNumberFormat(
                                        item.price_won_yearly
                                      ) + "원"}
                                </span>
                                <span
                                  className="price-duration fs-6 text-body mt-auto mb-2"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  /month
                                </span>
                              </div>
                            </div>
                          </h2>
                          <p
                            style={{
                              display:
                                priceType === "yearly" ? "block" : "none",
                            }}
                          >
                            (
                            {currency === "USD"
                              ? "$" + item.price_yearly_total
                              : $convertNumberFormat(
                                  item.price_won_yearly_total
                                ) + "원"}
                            /year)
                          </p>
                          <div className="mt-4 mb-6">
                            {/* <h3>{item.assistant_name}</h3> */}
                            <h3 className="notranslate">{item.title}</h3>
                            <p className="mb-0">{item.description}</p>
                          </div>
                          <div className="d-grid mt-6">
                            <a
                              onClick={() => {
                                if (isLoggedIn) {
                                  navigate("/account-subscription");
                                } else {
                                  Swal.fire({
                                    title: "Login Required",
                                    text: "Please login to subscribe.",
                                    iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                                    showCancelButton: true,
                                    confirmButtonText: "Sign In",
                                    cancelButtonText: "Cancel",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      navigate("/signin");
                                    }
                                  });
                                }
                              }}
                              className="btn btn-primary"
                            >
                              Subscribe
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* <div className="col-md-6 col-12">
                          <div className="card pricing">
                            <div className="card-body">
                              <h2 className="mb-4 d-flex align-items-center">
                                <div className="price-text">
                                  <div
                                    className={
                                      priceType === "monthly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>15.99</span>
                                    <span className="price-duration fs-6 text-body mt-auto mb-2">
                                      /month
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      priceType === "yearly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>8.99</span>
                                    <span
                                      className="price-duration fs-6 text-body mt-auto mb-2"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      /month ($107.88/year)
                                    </span>
                                  </div>
                                </div>
                              </h2>
                              <div className="mb-6">
                                <h3>Idot</h3>
                                <p className="mb-0">
                                  Idot is an AI assistant designed to simplify
                                  the process of creating professional image
                                  prompts.
                                </p>
                              </div>
                              <div className="d-grid mt-6">
                                <a
                                  onClick={() => requsetGlobalPay("idot")}
                                  className="btn btn-primary"
                                >
                                  {priceType === "monthly"
                                    ? "$15.99 /month"
                                    : "$8.99 /month"}
                                </a>
                              </div>
                              <div className="d-grid mt-6">
                                <div id="paypal-button-container-P-6BD734461R287933FMXP47EI"></div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                </div>
                {/* <div className="row flex-nowrap pb-4 me-5 me-lg-0">
                        <div className="col-md-6 col-12">
                          <div className="card pricing">
                            <div className="card-body">
                              <h2 className="mb-4 d-flex align-items-center">
                                <div className="price-text">
                                  <div
                                    className={
                                      priceType === "monthly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>15.99</span>
                                    <span className="price-duration fs-6 text-body mt-auto mb-2">
                                      /month
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      priceType === "yearly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>8.99</span>
                                    <span
                                      className="price-duration fs-6 text-body mt-auto mb-2"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      /month ($107.88/year)
                                    </span>
                                  </div>
                                </div>
                              </h2>
                              <div className="mb-6">
                                <h3>Sdot</h3>
                                <p className="mb-0">
                                  Sdot is an AI assistant for social media
                                  channels, optimized for creating
                                  brand-specific content.
                                </p>
                              </div>
                              <div className="d-grid mt-6">
                                <a
                                  onClick={() => requsetGlobalPay("sdot")}
                                  className="btn btn-primary"
                                >
                                  {priceType === "monthly"
                                    ? "$15.99 /month"
                                    : "$8.99 /month"}
                                </a>
                              </div>
                              <div className="d-grid mt-6">
                                <div id="paypal-button-container-P-5R646105HA3367356MXP472Q"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="card pricing">
                            <div className="card-body">
                              <h2 className="mb-4 d-flex align-items-center">
                                <div className="price-text">
                                  <div
                                    className={
                                      priceType === "monthly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>71.99</span>
                                    <span className="price-duration fs-6 text-body mt-auto mb-2">
                                      /month
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      priceType === "yearly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>46.99</span>
                                    <span
                                      className="price-duration fs-6 text-body mt-auto mb-2"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      /month ($563.88/year)
                                    </span>
                                  </div>
                                </div>
                              </h2>
                              <div className="mb-6">
                                <h3>Package</h3>
                                <p className="mb-0">
                                  Subscribe to a package that includes AI
                                  assistants like Wdot, Idot, and Sdot.
                                </p>
                              </div>
                              <div className="d-grid mt-6">
                                <a
                                  onClick={() => requsetGlobalPay("p1")}
                                  className="btn btn-primary"
                                >
                                  {priceType === "monthly"
                                    ? "$71.99 /month"
                                    : "$46.99 /month"}
                                </a>
                              </div>
                              <div className="d-grid mt-6">
                                <div id="paypal-button-container-P-49F12586HN6807044MXP5AWY"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
              </div>
            </div>
          )}
          <h4>Functionalities</h4>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="notranslate">Wdot</th>
                <th className="notranslate">Idot</th>
                <th className="notranslate">Sdot</th>
                <th className="notranslate">W.I.S. Package</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {subscriptionList.map((subscription, index) => (
                  <td>
                    <ul className="list-unstyled mb-0">
                      {funcData[subscription.code].map((func, index) => (
                        <li className="mb-2" key={`func_${index}`}>
                          <i className="bi bi-check-circle-fill text-primary"></i>
                          <span className="ms-3">{func}</span>
                        </li>
                      ))}
                    </ul>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>

          <h4>Enterprise Plan Pricing Information</h4>
          <ul className="list-unstyled mb-0">
            <li className="mb-2">
              To support your business's success, we offer special discount
              benefits based on the number of users: (
              <a href="mailto:wyou@wonderslab.kr">wyou@wonderslab.kr</a>)
            </li>
            <li className="mb-2">
              <i className="bi bi-check-circle-fill text-primary"></i>
              <span className="ms-3">
                10% additional discount for 10 or more
              </span>
            </li>
            <li className="mb-2">
              <i className="bi bi-check-circle-fill text-primary"></i>
              <span className="ms-3">
                15% additional discount for 30 or more
              </span>
            </li>
            <li className="mb-2">
              <i className="bi bi-check-circle-fill text-primary"></i>
              <span className="ms-3">
                20% additional discount for 50 or more
              </span>
            </li>
          </ul>
        </div>
      </section>
      <section className="mb-xl-9 mb-4 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
              <div className="text-center mb-7">
                <h2>Frequently asked questions</h2>
                <p className="mb-0">
                  Can’t find any answer for your question?
                  <br />
                  Ask our{" "}
                  <a
                    onClick={() => navigate("/contact")}
                    className="text-primary"
                  >
                    customer support
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
              <div className="accordion" id="accordionExample">
                <div className="border mb-2 rounded-3 p-3">
                  <h2 className="h5 mb-0">
                    <a
                      href="#"
                      className="text-reset d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is the difference between monthly and yearly
                      subscriptions?
                      <span className="chevron-arrow">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </a>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="mt-3">
                      The monthly subscription involves paying a fixed amount
                      each month, whereas the yearly subscription offers the
                      same monthly service at a more affordable rate. The yearly
                      subscription is billed monthly, but at a discounted rate
                      compared to the monthly subscription.
                    </div>
                  </div>
                </div>

                <div className="border mb-2 rounded-3 p-3">
                  <h2 className="h5 mb-0">
                    <a
                      href="#"
                      className="text-reset d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What happens if I cancel my subscription early?
                      <span className="chevron-arrow">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </a>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="mt-3">
                      Please refer to the{" "}
                      <a
                        href="https://wonderslab.notion.site/Refund-Policy-081c23f58fe34015997f870f1085fe23"
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        link
                      </a>{" "}
                      for our refund policy regarding subscription
                      cancellations. If you want to refund your subscription,
                      please contact us at{" "}
                      <a href="mailto:wyou@wonderslab.kr">wyou@wonderslab.kr</a>
                      . Refunds are only possible within 24 hours after payment.
                    </div>
                  </div>
                </div>
                <div className="border mb-2 rounded-3 p-3">
                  <h2 className="h5 mb-0">
                    <a
                      href="#"
                      className="text-reset d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Do I have to pay for the whole year at once when I start a
                      yearly subscription?
                      <span className="chevron-arrow">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </a>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="mt-3">
                      Yes, you will be billed for the whole year at once when
                      you start a yearly subscription.
                    </div>
                  </div>
                </div>
                <div className="border mb-2 rounded-3 p-3">
                  <h2 className="h5 mb-0">
                    <a
                      href="#"
                      className="text-reset d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      When will I be billed for the subscription?
                      <span className="chevron-arrow">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </a>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="mt-3">
                      You will be billed on the date corresponding to the start
                      of your subscription each month. This applies to both
                      monthly and yearly subscriptions.
                    </div>
                  </div>
                </div>
                <div className="border mb-2 rounded-3 p-3">
                  <h2 className="h5 mb-0">
                    <a
                      href="#"
                      className="text-reset d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Can I change my subscription plan?
                      <span className="chevron-arrow">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </a>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="mt-3">
                      Yes, you can switch from a monthly to a yearly
                      subscription, or vice versa, at any time. The change will
                      take effect from the next billing date.
                    </div>
                  </div>
                </div>
                <div className="border mb-2 rounded-3 p-3">
                  <h2 className="h5 mb-0">
                    <a
                      href="#"
                      className="text-reset d-flex justify-content-between align-items-center"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      Can I cancel my subscription at any time?
                      <span className="chevron-arrow">
                        <i className="bi bi-chevron-down"></i>
                      </span>
                    </a>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="mt-3">
                      Yes, you can cancel your subscription at any time.
                      Cancellation will take effect from the next billing date.
                      You can use the service until the end of the current
                      billing period.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Pricing;
