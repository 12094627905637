import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import {
  $post,
  $put,
  $upload,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
import Swal from "sweetalert2";
import AccountMenu from "../component/AccountMenu";

const productName = {
  idot: "WondersLab AI Assistant - Idot",
  sdot: "WondersLab AI Assistant - Sdot",
  wdot: "WondersLab AI Assistant - Wdot",
  p1: "WondersLab AI Assistant - Package",
};

function AccountSubscriptionPartner() {
  const appUrl = process.env.REACT_APP_URL;
  const appServerUrl = process.env.REACT_APP_SERVER_URL;
  const userLocalLang = navigator.language || navigator.userLanguage;
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const [priceType, setPriceType] = useState("yearly");
  const [accessToken, setAccessToken] = useState(null);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [currency, setCurrency] = useState("USD");
  // const { partnerKey } = useParams();
  const [partnerKey, setPartnerKey] = useState(null);
  const [partnerKeyValid, setPartnerKeyValid] = useState(false);

  const getUserAssistantList = async () => {
    const assistantList = (await $get("/api/subscription")).data;
    return assistantList;
  };

  const getAssistantMasterList = async () => {
    const assistantMasterList = (await $get("/api/assistant")).data;

    const myAssistantList = await getUserAssistantList();
    // assistantMasterList에서 myAssistantListt에 있는 assistant_code에 대해서는 assistantMasterList에 subscription_yn을 Y로 설정
    assistantMasterList.forEach((item) => {
      const myAssistant = myAssistantList.find(
        (x) => x.assistant_code === item.code
      );
      if (myAssistant) {
        item.subscription_yn = "Y";
      } else {
        item.subscription_yn = "N";
      }
    });

    setSubscriptionList(assistantMasterList);
  };

  const getAccessToken = async () => {
    const result = await $get("/api/subscription/access-token");
    if (result.data.status === "success") {
      return result.data.access_token;
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: result.data.message,
      });
      return null;
    }
  };

  const getPrice = (code, priceType) => {
    const subscription = subscriptionList.find((item) => item.code === code);
    if (subscription) {
      return priceType === "monthly"
        ? subscription.partner_price_monthly
        : subscription.partner_price_yearly_total;
    }
    return 0;
  };

  const insertOpportunity = async (assistantCode, currency) => {
    try {
      const result = await $post("/api/subscription/opportunity", {
        assistant_code: assistantCode,
        subscribe_type: priceType,
        assistant_title: productName[assistantCode],
        currency: currency,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const requsetGlobalPay = async (assistantCode) => {
    await insertOpportunity(assistantCode, "USD");
    const accessToken = await getAccessToken();
    if (accessToken === null) return;

    let obj = new Object();

    // Pay Request Parameters
    obj.Authorization = accessToken;
    obj.service_id = process.env.REACT_APP_PAYPLE_SERVICE_ID;
    // obj.service_oid = "test120220608512351";
    obj.comments = productName[assistantCode] + "/" + priceType;
    obj.serviceDefine = priceType;
    obj.totalAmount = getPrice(assistantCode, priceType);
    // obj.totalAmount = 1;
    obj.currency = "USD";
    obj.firstName = "";
    obj.lastName = "";
    obj.email = userData.email;
    // obj.resultUrl = appUrl + "/subscription";
    obj.resultUrl = appServerUrl + "/api/subscription/payment";
    // obj.isDirect = "Y";
    /*
     *  테스트 결제인 경우에만 필수로 보내는 파라미터(payCls)
     *  payCls는 파트너 인증 토큰발급 응답값으로 반환되는 값이며,
     *  테스트 결제시에만 필요합니다.
     */
    // obj.payCls = "demo"; // 파트너 인증 토큰발급 응답값으로 오는 payCls 그대로 전송

    window.paypleGpayPaymentRequest(obj);
  };

  const getResultPayple = (result) => {
    if (result.resultCode === "0000") {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Payment is successful.",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: result.resultMsg,
      });
    }
  };

  const getPriceWon = (code, priceType) => {
    const subscription = subscriptionList.find((item) => item.code === code);
    if (subscription) {
      return priceType === "monthly"
        ? subscription.partner_price_won_monthly
        : subscription.partner_price_won_yearly_total;
    }
    return 0;
  };

  // const setSalesPartnerKey = async (salesPartnerKey) => {
  //   const result = await $put("/api/user/sales-partner-key", {
  //     sales_partner_key: salesPartnerKey,
  //   });
  // };

  const requestPayplePay = async (assistantCode) => {
    await insertOpportunity(assistantCode, "KRW");
    // try {
    //   if (partnerKey) {
    //     await setSalesPartnerKey(partnerKey);
    //   } else {
    //     await setSalesPartnerKey("");
    //   }
    // } catch (error) {
    //   console.error(error);
    // }

    let obj = new Object();

    // 결제수단 등록 (빌링키 발급) Request Parameters
    obj.PCD_PAY_TYPE = "card";
    obj.PCD_PAY_WORK = "PAY";

    /* 01 : 페이플 간편결제 */
    obj.PCD_CARD_VER = "01";
    obj.PCD_PAY_GOODS = productName[assistantCode] + "/" + priceType;
    obj.PCD_PAY_TOTAL = getPriceWon(assistantCode, priceType);
    // obj.PCD_PAY_TOTAL = 100;
    // obj.PCD_RST_URL = appServerUrl + "/api/subscription/payment_won";
    obj.PCD_RST_URL = "/api/subscription/payment_won";

    // obj.PCD_PAYER_NO = "1234";
    obj.PCD_PAYER_NAME = userData.email;
    // obj.PCD_PAYER_HP = "01012345678";
    obj.PCD_PAYER_EMAIL = userData.email;
    // obj.PCD_PAY_ISTAX = "Y";

    obj.clientKey = process.env.REACT_APP_PAYPLE_CLIENT_KEY;

    // 결제요청 함수 호출
    window.PaypleCpayAuthCheck(obj);
  };

  const initPaypleScript = () => {
    // <script src="https://demo-gpay.payple.kr/common/js/gpay-1.0.1.js"></script> // 테스트(TEST)
    // <script src="https://gpay.payple.kr/common/js/gpay-1.0.1.js"></script> // 운영(REAL)
  };

  const checkPartnerKey = async () => {
    const result = await $get("/api/partner/partner_key/" + partnerKey);
    if (result.data.length > 0) {
      setPartnerKeyValid(true);
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Success",
        text: "Partner key is valid.",
      });
    } else {
      setPartnerKeyValid(false);
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Error",
        text: "Partner key is invalid.",
      });
    }
  };

  useEffect(() => {
    getAssistantMasterList();

    if (userLocalLang.startsWith("ko")) {
      setCurrency("KRW");
    } else {
      setCurrency("USD");
    }
    // getAccessToken();
    // script 가 로드되어 있지 않으면 로드
    // const script = document.querySelector(
    //   'script[src="https://demo-gpay.payple.kr/common/js/gpay-1.0.1.js"]'
    // );

    // console.log("script", script);

    // if (!script) {
    //   const script = document.createElement("script");
    //   script.src = "https://demo-gpay.payple.kr/common/js/gpay-1.0.1.js";
    //   script.async = true;
    //   script.onload = () => {
    //     // initPaypleScript();
    //   };
    //   document.body.appendChild(script);
    // } else {
    //   // initPaypleScript();
    // }
    // // 페이지를 빠져나갈때 script 삭제
    // return () => {
    //   const script = document.querySelector(
    //     'script[src="https://demo-gpay.payple.kr/common/js/gpay-1.0.1.js"]'
    //   );
    //   if (script) {
    //     script.remove();

    //     console.log("window.objGpayReqParams", window.Payple);
    //     // Identifier 'objGpayReqParams' has already been declared
    //     // objGpayReqParams 삭제
    //     delete window.Payple;
    //   }
    // };
  }, []);
  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AccountMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <h1 className="mb-0 h3">Manage Subscription</h1>
                <p>
                  Please refer to the{" "}
                  <a
                    href="#"
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    link
                  </a>{" "}
                  for our refund policy regarding subscription cancellations.
                </p>
                {currency === "KRW" && (
                  <p>
                    해외 신용카드 소지자는 여기를{" "}
                    <a
                      onClick={() => setCurrency("USD")}
                      style={{
                        textDecoration: "underline",
                        color: "var(--w-primary-main)",
                      }}
                    >
                      (해외카드결제)
                    </a>{" "}
                    클릭하세요.
                  </p>
                )}
                {/* {currency === "KRW" && <p>협력사 회원이신가요?</p>} */}
                <p>
                  Once you complete the subscription, the account will be
                  activated for the subscribed service with the email account
                  you are currently logged in with.
                </p>
              </div>
              <div>
                <p>Enter the Partner Key</p>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Partner key"
                    aria-label="Partner key"
                    aria-describedby="button-addon2"
                    value={partnerKey}
                    onChange={(e) => setPartnerKey(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        checkPartnerKey();
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="button-addon2"
                    onClick={() => {
                      checkPartnerKey();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              {partnerKeyValid && subscriptionList.length > 0 && (
                <div className="card border-0 shadow-sm mb-4">
                  <div className="card-body p-lg-5">
                    <div className="container price-wrapper">
                      <div className="row">
                        <div className="col-12 z-1">
                          <div className="mb-5">
                            <div
                              className="price-switcher-wrapper switcher"
                              onClick={() => {
                                setPriceType(
                                  priceType === "monthly" ? "yearly" : "monthly"
                                );
                              }}
                            >
                              <p className="mb-0 pe-3">Monthly</p>
                              <div className="price-switchers">
                                <div
                                  className={
                                    priceType === "monthly"
                                      ? "price-switcher price-switcher-active"
                                      : "price-switcher"
                                  }
                                ></div>
                                <div
                                  className={
                                    priceType === "yearly"
                                      ? "price-switcher price-switcher-active"
                                      : "price-switcher"
                                  }
                                ></div>
                                <div className="switcher-button bg-primary"></div>
                              </div>
                              <p className="mb-0 ps-3">Yearly(Save 20% off)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive-xl">
                        <div className="row pb-4 me-5 me-lg-0">
                          {subscriptionList.map((item, index) => (
                            <div
                              className="col-md-6 col-12 mb-3"
                              key={`subscription-${index}`}
                            >
                              <div className="card pricing">
                                <div className="card-body">
                                  {item.code === "p1" && (
                                    <span className="badge text-bg-warning text-uppercase py-2 mb-2">
                                      extra{" "}
                                      {priceType === "monthly" ? "20%" : "39%"}{" "}
                                      off
                                    </span>
                                  )}
                                  <h2 className="d-flex align-items-center">
                                    <div className="price-text">
                                      <div
                                        className={
                                          priceType === "monthly"
                                            ? "price price-show"
                                            : "price price-hide price-hidden"
                                        }
                                      >
                                        {currency === "USD" && <span>$</span>}
                                        <span style={{ whiteSpace: "nowrap" }}>
                                          {currency === "USD"
                                            ? item.partner_price_monthly
                                            : $convertNumberFormat(
                                                item.partner_price_won_monthly
                                              ) + "원"}
                                        </span>
                                        <span className="price-duration fs-6 text-body mt-auto mb-2">
                                          /month
                                        </span>
                                      </div>
                                      <div
                                        className={
                                          priceType === "yearly"
                                            ? "price price-show"
                                            : "price price-hide price-hidden"
                                        }
                                      >
                                        {currency === "USD" && <span>$</span>}
                                        <span style={{ whiteSpace: "nowrap" }}>
                                          {currency === "USD"
                                            ? item.partner_price_yearly
                                            : $convertNumberFormat(
                                                item.partner_price_won_yearly
                                              ) + "원"}
                                        </span>
                                        <span
                                          className="price-duration fs-6 text-body mt-auto mb-2"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          /month
                                        </span>
                                      </div>
                                    </div>
                                  </h2>
                                  <p
                                    style={{
                                      display:
                                        priceType === "yearly"
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    (
                                    {currency === "USD"
                                      ? "$" + item.partner_price_yearly_total
                                      : $convertNumberFormat(
                                          item.partner_price_won_yearly_total
                                        ) + "원"}
                                    /year)
                                  </p>
                                  <div className="mt-4 mb-6">
                                    {/* <h3>{item.assistant_name}</h3> */}
                                    <h3 className="notranslate">
                                      {item.title}
                                    </h3>
                                    <p className="mb-0">{item.description}</p>
                                  </div>
                                  <div className="d-grid mt-6">
                                    {item.subscription_yn === "N" &&
                                    currency === "USD" ? (
                                      <a
                                        onClick={() =>
                                          requsetGlobalPay(item.code)
                                        }
                                        className="btn btn-dark"
                                      >
                                        Subscribe
                                      </a>
                                    ) : item.subscription_yn === "N" &&
                                      currency === "KRW" ? (
                                      <a
                                        onClick={() =>
                                          requestPayplePay(item.code)
                                        }
                                        className="btn btn-dark"
                                      >
                                        Subscribe
                                      </a>
                                    ) : (
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          navigate("/account-billing")
                                        }
                                      >
                                        Active
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          {/* <div className="col-md-6 col-12">
                          <div className="card pricing">
                            <div className="card-body">
                              <h2 className="mb-4 d-flex align-items-center">
                                <div className="price-text">
                                  <div
                                    className={
                                      priceType === "monthly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>15.99</span>
                                    <span className="price-duration fs-6 text-body mt-auto mb-2">
                                      /month
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      priceType === "yearly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>8.99</span>
                                    <span
                                      className="price-duration fs-6 text-body mt-auto mb-2"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      /month ($107.88/year)
                                    </span>
                                  </div>
                                </div>
                              </h2>
                              <div className="mb-6">
                                <h3>Idot</h3>
                                <p className="mb-0">
                                  Idot is an AI assistant designed to simplify
                                  the process of creating professional image
                                  prompts.
                                </p>
                              </div>
                              <div className="d-grid mt-6">
                                <a
                                  onClick={() => requsetGlobalPay("idot")}
                                  className="btn btn-primary"
                                >
                                  {priceType === "monthly"
                                    ? "$15.99 /month"
                                    : "$8.99 /month"}
                                </a>
                              </div>
                              <div className="d-grid mt-6">
                                <div id="paypal-button-container-P-6BD734461R287933FMXP47EI"></div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        </div>
                        {/* <div className="row flex-nowrap pb-4 me-5 me-lg-0">
                        <div className="col-md-6 col-12">
                          <div className="card pricing">
                            <div className="card-body">
                              <h2 className="mb-4 d-flex align-items-center">
                                <div className="price-text">
                                  <div
                                    className={
                                      priceType === "monthly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>15.99</span>
                                    <span className="price-duration fs-6 text-body mt-auto mb-2">
                                      /month
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      priceType === "yearly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>8.99</span>
                                    <span
                                      className="price-duration fs-6 text-body mt-auto mb-2"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      /month ($107.88/year)
                                    </span>
                                  </div>
                                </div>
                              </h2>
                              <div className="mb-6">
                                <h3>Sdot</h3>
                                <p className="mb-0">
                                  Sdot is an AI assistant for social media
                                  channels, optimized for creating
                                  brand-specific content.
                                </p>
                              </div>
                              <div className="d-grid mt-6">
                                <a
                                  onClick={() => requsetGlobalPay("sdot")}
                                  className="btn btn-primary"
                                >
                                  {priceType === "monthly"
                                    ? "$15.99 /month"
                                    : "$8.99 /month"}
                                </a>
                              </div>
                              <div className="d-grid mt-6">
                                <div id="paypal-button-container-P-5R646105HA3367356MXP472Q"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="card pricing">
                            <div className="card-body">
                              <h2 className="mb-4 d-flex align-items-center">
                                <div className="price-text">
                                  <div
                                    className={
                                      priceType === "monthly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>71.99</span>
                                    <span className="price-duration fs-6 text-body mt-auto mb-2">
                                      /month
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      priceType === "yearly"
                                        ? "price price-show"
                                        : "price price-hide price-hidden"
                                    }
                                  >
                                    <span>$</span>
                                    <span>46.99</span>
                                    <span
                                      className="price-duration fs-6 text-body mt-auto mb-2"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      /month ($563.88/year)
                                    </span>
                                  </div>
                                </div>
                              </h2>
                              <div className="mb-6">
                                <h3>Package</h3>
                                <p className="mb-0">
                                  Subscribe to a package that includes AI
                                  assistants like Wdot, Idot, and Sdot.
                                </p>
                              </div>
                              <div className="d-grid mt-6">
                                <a
                                  onClick={() => requsetGlobalPay("p1")}
                                  className="btn btn-primary"
                                >
                                  {priceType === "monthly"
                                    ? "$71.99 /month"
                                    : "$46.99 /month"}
                                </a>
                              </div>
                              <div className="d-grid mt-6">
                                <div id="paypal-button-container-P-49F12586HN6807044MXP5AWY"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AccountSubscriptionPartner;
