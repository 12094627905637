import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";

function Signin() {
  const appUrl = process.env.REACT_APP_URL;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const googleLoginRef = useRef();

  const onGoogleSuccess = async (response) => {
    const resData = jwtDecode(response.credential);
    let userLanguage = "EN";

    const res = await $post("/api/google-login", {
      // tokenId: resData.azp,
      email: resData.email,
      nickname: resData.name,
      profile_image_url: resData.picture,
      access_token_expires_in: resData.exp,
      lang: userLanguage,
    });
    // 서버로 토큰 전송

    const userInfo = res.data[0];
    // setLoading(false);

    if (res.status === 200) {
      login({
        ...userInfo,
      });
    }
  };

  const onGoogleFailure = (response) => {
    console.log("Login Failed:", response);
  };

  const loginWithGoogle = async () => {
    let url = "https://accounts.google.com/o/oauth2/v2/auth";
    // client_id는 위 스크린샷을 보면 발급 받았음을 알 수 있음
    // 단, 스크린샷에 있는 ID가 아닌 당신이 직접 발급 받은 ID를 사용해야 함.
    url += `?client_id=${googleClientId}`;
    // 아까 등록한 redirect_uri
    // 로그인 창에서 계정을 선택하면 구글 서버가 이 redirect_uri로 redirect 시켜줌
    url += `&redirect_uri=${serverUrl}/api/google-login`;
    // 필수 옵션.
    url += "&response_type=code";
    // 구글에 등록된 유저 정보 email, profile을 가져오겠다 명시
    url += "&scope=email profile";
    // 완성된 url로 이동
    // 이 url이 위에서 본 구글 계정을 선택하는 화면임.

    // 브라우저 url을 이동
    document.location.href = url;
  };

  const signin = async (e) => {
    e.preventDefault();
    const res = await $post("/api/user/signin", { email, password });
    if (res.status === 200) {
      if (res.data.status === "success") {
        login(res.data.user);
        navigate("/account-assistant");
      } else {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: "Signin Failed",
          text: res.data.message,
        });
      }
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Signin Failed",
        text: "Please try again",
      });
    }
  };

  return (
    <main>
      <div className="pattern-square"></div>
      {appUrl.includes("localhost") && (
        <section className="py-5 py-lg-8">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 offset-xl-4 col-md-12 col-12">
                <div className="text-center">
                  <a href="index.html">
                    <img
                      src="./assets/images/logo/wdot-logo.png"
                      style={{ height: "80px" }}
                      alt="brand"
                      className="mb-3"
                    />
                  </a>
                  <p className="mb-0">
                    Don’t have an account yet?{" "}
                    <a
                      onClick={() => navigate("/signup")}
                      className="text-primary"
                    >
                      Register here
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="py-5 py-lg-8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-8 col-12">
              <div className="card shadow-sm mb-6">
                <div className="card-body">
                  {appUrl.includes("localhost") && (
                    <div className="needs-validation mb-6" noValidate>
                      <div className="mb-3">
                        <label
                          htmlFor="signinEmailInput"
                          className="form-label"
                        >
                          Email
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="signinEmailInput"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <div className="invalid-feedback">
                          Please enter email.
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="formSignUpPassword"
                          className="form-label"
                        >
                          Password<span className="text-danger">*</span>
                        </label>
                        <div className="password-field position-relative">
                          <input
                            type="password"
                            className="form-control fakePassword"
                            id="formSignUpPassword"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                if (e.nativeEvent.isComposing) {
                                  return;
                                }
                                signin(e);
                              }
                            }}
                            required
                          />

                          <span>
                            <i className="bi bi-eye-slash passwordToggler"></i>
                          </span>
                          <div className="invalid-feedback">
                            Please enter password.
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 d-flex align-items-center justify-content-between">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="rememberMeCheckbox"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rememberMeCheckbox"
                          >
                            Remember me
                          </label>
                        </div>

                        <div>
                          <a
                            onClick={() => navigate("/forget-password")}
                            className="text-primary"
                          >
                            Forgot Password
                          </a>
                        </div>
                      </div>

                      <div className="d-grid">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={(e) => signin(e)}
                        >
                          Sign In
                        </button>
                      </div>
                    </div>
                  )}

                  <span>Sign in with your social network.</span>
                  <div className="d-grid mt-3">
                    <a
                      className="btn btn-google"
                      onClick={() => {
                        if (navigator.userAgent.includes("Safari")) {
                          loginWithGoogle();
                        } else {
                          googleLoginRef.current
                            .querySelector("[role=button]")
                            .click();
                        }
                      }}
                    >
                      <span className="me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-google"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                        </svg>
                      </span>
                      Continue with Google
                    </a>
                    <div
                      className="google-login"
                      style={{ display: "none" }}
                      ref={googleLoginRef}
                    >
                      {/* {!navigator.userAgent.includes("Safari") && ( */}
                      <GoogleOAuthProvider clientId={googleClientId}>
                        <GoogleLogin
                          onSuccess={onGoogleSuccess}
                          onError={onGoogleFailure}
                          size="large"
                          shape="rectangular"
                          logo_alignment="left"
                          type="standard"
                          width="300px"
                          height="52px"
                          useOneTap={false}
                        />
                      </GoogleOAuthProvider>
                      {/* )} */}

                      {/* <img
                      className="google-login-button"
                      src="./assets/images/sign-in/google-login-button-en.png"
                      width="300px"
                      height="auto"
                      alt="구글 로그인 버튼"
                      onClick={() => {
                        if (navigator.userAgent.includes("Safari"))
                          loginWithGoogle();
                      }}
                    /> */}
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="mt-3 d-flex align-items-center justify-content-between">
                      {/* <div className="form-check"> */}
                      {/* <input
                            className="form-check-input"
                            type="checkbox"
                            id="signupCheckTextCheckbox"
                            checked={terms}
                            onClick={() => setTerms(!terms)}
                          /> */}
                      <label
                        className="form-check-label ms-2"
                        htmlFor="signupCheckTextCheckbox"
                      >
                        <a
                          href="https://wonderslab.notion.site/Terms-of-Service-7c37ba77e0cf4760b57a29d2bae6692a"
                          target="_blank"
                        >
                          Terms of Use
                        </a>{" "}
                        &{" "}
                        <a
                          href="https://wonderslab.notion.site/Privacy-Policy-11f4c06c50ef492f95f8fbf1f7f7d0ad"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      </label>
                      {/* </div> */}
                    </div>
                  </div>

                  {/* <div className="d-grid mt-2">
                    <a href="#" className="btn btn-facebook">
                      <span className="me-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-facebook"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                        </svg>
                      </span>
                      Continue with Facebook
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Signin;
