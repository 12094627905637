import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  $get,
  $post,
  $convertNumberFormat,
  $dateTimezone,
} from "../utils/common";
import Chart from "react-apexcharts";
import AdminMenu from "../component/AdminMenu";

function WdotStatistics() {
  const [funcUsage, setFuncUsage] = useState(null);
  const [funcUsageByMonth, setFuncUsageByMonth] = useState(null);
  const [docUsage, setDocUsage] = useState(null);
  const [docUsageByMonth, setDocUsageByMonth] = useState(null);
  const [searchType, setSearchType] = useState("");
  const [wdotUserMonthlyTrend, setWdotUserMonthlyTrend] = useState(null);

  const getRandomColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getFuncUsage = async () => {
    const res = await $get(`/api/statistics/wdot-func-usage${searchType}`);
    if (res.status === 200) {
      setFuncUsage({
        series: [
          {
            data: res.data.map((item) => item.count),
          },
        ],
        options: {
          chart: {
            type: "bar",
            // height: 430,
          },
          colors: res.data.map((item) => getRandomColor()),
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: res.data.map((item) => item.func_title),
          },
        },
      });
    }
  };

  const getDocUsage = async () => {
    const res = await $get(`/api/statistics/wdot-doctype-usage${searchType}`);
    if (res.status === 200) {
      setDocUsage({
        series: [
          {
            data: res.data.map((item) => item.count),
          },
        ],
        options: {
          chart: {
            type: "bar",
            // height: 430,
          },
          colors: res.data.map((item) => getRandomColor()),
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: res.data.map((item) => item.title),
          },
        },
      });
    }
  };

  const getDocUsageByMonth = async () => {
    const res = await $get(
      `/api/statistics/wdot-doctype-usage-by-month${searchType}`
    );
    if (res.status === 200 && res.data.length > 0) {
      let minYearMonth =
        res.data[0].year + String(res.data[0].month).padStart(2, "0");
      let maxYearMonth =
        res.data[0].year + String(res.data[0].month).padStart(2, "0");

      res.data.forEach((item) => {
        let yearMonth = item.year + String(item.month).padStart(2, "0");
        if (yearMonth < minYearMonth) {
          minYearMonth = yearMonth;
        }
        if (yearMonth > maxYearMonth) {
          maxYearMonth = yearMonth;
        }
      });

      // from minYearMonth to maxYearMonth까지 키를 갖는 객체 생성
      const yearMonthData = {};
      for (let i = Number(minYearMonth); i <= Number(maxYearMonth); i++) {
        yearMonthData[i] = 0;
      }

      const workTypeData = {};
      res.data.forEach((item) => {
        if (!workTypeData[item.work_title]) {
          workTypeData[item.work_title] = { ...yearMonthData };
        }
        workTypeData[item.work_title][
          item.year + String(item.month).padStart(2, "0")
        ] = item.count;
      });

      setDocUsageByMonth(workTypeData);
    }
  };

  const getFuncUsageByMonth = async () => {
    const res = await $get(
      `/api/statistics/wdot-func-usage-by-month${searchType}`
    );
    if (res.status === 200 && res.data.length > 0) {
      let minYearMonth =
        res.data[0].year + String(res.data[0].month).padStart(2, "0");
      let maxYearMonth =
        res.data[0].year + String(res.data[0].month).padStart(2, "0");

      res.data.forEach((item) => {
        let yearMonth = item.year + String(item.month).padStart(2, "0");
        if (yearMonth < minYearMonth) {
          minYearMonth = yearMonth;
        }
        if (yearMonth > maxYearMonth) {
          maxYearMonth = yearMonth;
        }
      });

      // from minYearMonth to maxYearMonth까지 키를 갖는 객체 생성
      const yearMonthData = {};
      for (let i = Number(minYearMonth); i <= Number(maxYearMonth); i++) {
        yearMonthData[i] = 0;
      }

      const funcTypeData = {};
      res.data.forEach((item) => {
        if (!funcTypeData[item.func_title]) {
          funcTypeData[item.func_title] = { ...yearMonthData };
        }
        funcTypeData[item.func_title][
          item.year + String(item.month).padStart(2, "0")
        ] = item.count;
      });

      setFuncUsageByMonth(funcTypeData);
    }
  };

  const getUserMonthlyTrend = async () => {
    const res = await $get("/api/statistics/user-new");
    if (res.status === 200) {
      setWdotUserMonthlyTrend({
        series: [
          {
            name: "New Users",
            type: "column",
            data: res.data.wdot.map((item) => item.total_new_users),
          },
          // {
          //   name: "Unused Users",
          //   type: "column",
          //   data: res.data.wdot.map((item) => item.unused_user_count),
          // },
          {
            name: "Subscribed Users",
            type: "line",
            data: res.data.wdot.map((item) => item.subscribed_users),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
          },
          stroke: {
            width: [0, 4],
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels: res.data.wdot.map((item) => item.year + "-" + item.month),
          // xaxis: {
          //   type: "datetime",
          // },
          yaxis: [
            {
              title: {
                text: "New Users",
              },
            },
            {
              opposite: true,
              title: {
                text: "Subscribed Users",
              },
            },
          ],
        },
      });
    }
  };

  useEffect(() => {
    getUserMonthlyTrend();
    getDocUsage();
    getDocUsageByMonth();
    getFuncUsage();
    getFuncUsageByMonth();
  }, [searchType]);

  // useEffect(() => {
  //   getDocUsage();
  //   getDocUsageByMonth();
  //   getFuncUsage();
  //   getFuncUsageByMonth();
  // }, []);

  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AdminMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="searchAll"
                    value="all"
                    checked={searchType === ""}
                    onChange={() => {
                      setSearchType("");
                    }}
                  />
                  <label class="form-check-label" for="searchAll">
                    All Users
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="searchSubscriber"
                    value="subscriber"
                    checked={searchType === "/subscriber"}
                    onChange={() => {
                      setSearchType("/subscriber");
                    }}
                  />
                  <label class="form-check-label" for="searchSubscriber">
                    Subscribers
                  </label>
                </div>
              </div>

              <div className="mb-4">
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">User Monthly Trend</h3>
                    {wdotUserMonthlyTrend !== null && (
                      <Chart
                        options={wdotUserMonthlyTrend.options}
                        series={wdotUserMonthlyTrend.series}
                        type="line"
                        height={350}
                      />
                    )}
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Document Type Usage</h3>
                    {docUsage !== null && (
                      <Chart
                        options={docUsage.options}
                        series={docUsage.series}
                        type="bar"
                        // height={720}
                      />
                    )}
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Document Type Usage By Month</h3>
                    {docUsageByMonth !== null && (
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="table-dark">
                              <th>Type</th>
                              {Object.keys(
                                docUsageByMonth[Object.keys(docUsageByMonth)[0]]
                              ).map((item) => (
                                <th>
                                  {item.substring(0, 4) +
                                    "-" +
                                    item.substring(4, 6)}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(docUsageByMonth).map((key) => (
                              <tr>
                                <td>{key}</td>
                                {Object.keys(docUsageByMonth[key]).map(
                                  (item) => (
                                    <td>{docUsageByMonth[key][item]}</td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">AI Feature Usage</h3>
                    {funcUsage !== null && (
                      <Chart
                        options={funcUsage.options}
                        series={funcUsage.series}
                        type="bar"
                        // height={720}
                      />
                    )}
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">AI Feature Usage By Month</h3>
                    {funcUsageByMonth !== null && (
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="table-dark">
                              <th>Type</th>
                              {Object.keys(
                                funcUsageByMonth[
                                  Object.keys(funcUsageByMonth)[0]
                                ]
                              ).map((item) => (
                                <th>
                                  {item.substring(0, 4) +
                                    "-" +
                                    item.substring(4, 6)}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(funcUsageByMonth).map((key) => (
                              <tr>
                                <td>{key}</td>
                                {Object.keys(funcUsageByMonth[key]).map(
                                  (item) => (
                                    <td>{funcUsageByMonth[key][item]}</td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default WdotStatistics;
