import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  $get,
  $post,
  $convertNumberFormat,
  $dateTimezone,
} from "../utils/common";
import Chart from "react-apexcharts";
import AdminMenu from "../component/AdminMenu";

function SdotStatistics() {
  const [categoryUsage, setCategoryUsage] = useState(null);
  const [postUsage, setPostUsage] = useState(null);
  const [sdotUserMonthlyTrend, setSdotUserMonthlyTrend] = useState(null);
  const [searchType, setSearchType] = useState("");

  const getRandomColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getCategoryUsage = async () => {
    const res = await $get(`/api/statistics/sdot-category-usage${searchType}`);
    if (res.status === 200) {
      setCategoryUsage({
        series: [
          {
            data: res.data.map((item) => item.count),
          },
        ],
        options: {
          chart: {
            type: "bar",
            // height: 430,
          },
          colors: res.data.map((item) => getRandomColor()),
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: res.data.map((item) => item.title),
          },
        },
      });
    }
  };

  const getPostUsage = async () => {
    const res = await $get(`/api/statistics/sdot-posttype-usage${searchType}`);
    if (res.status === 200) {
      setPostUsage({
        series: res.data.map((item) => item.count),
        options: {
          chart: {
            type: "polarArea",
          },
          labels: res.data.map((item) => item.post_type),
          stroke: {
            colors: ["#fff"],
          },
          fill: {
            opacity: 0.8,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      });
    }
  };

  const getUserMonthlyTrend = async () => {
    const res = await $get("/api/statistics/user-new");
    if (res.status === 200) {
      setSdotUserMonthlyTrend({
        series: [
          {
            name: "New Users",
            type: "column",
            data: res.data.sdot.map((item) => item.total_new_users),
          },
          {
            name: "Subscribed Users",
            type: "line",
            data: res.data.sdot.map((item) => item.subscribed_users),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
          },
          stroke: {
            width: [0, 4],
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels: res.data.sdot.map((item) => item.year + "-" + item.month),
          // xaxis: {
          //   type: "datetime",
          // },
          yaxis: [
            {
              title: {
                text: "New Users",
              },
            },
            {
              opposite: true,
              title: {
                text: "Subscribed Users",
              },
            },
          ],
        },
      });
    }
  };

  useEffect(() => {
    getUserMonthlyTrend();
    getCategoryUsage();
    getPostUsage();
  }, [searchType]);

  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AdminMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="searchAll"
                    value="all"
                    checked={searchType === ""}
                    onChange={() => {
                      setSearchType("");
                    }}
                  />
                  <label class="form-check-label" for="searchAll">
                    All Users
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="searchSubscriber"
                    value="subscriber"
                    checked={searchType === "/subscriber"}
                    onChange={() => {
                      setSearchType("/subscriber");
                    }}
                  />
                  <label class="form-check-label" for="searchSubscriber">
                    Subscribers
                  </label>
                </div>
              </div>
              <div className="mb-4">
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">User Monthly Trend</h3>
                    {sdotUserMonthlyTrend !== null && (
                      <Chart
                        options={sdotUserMonthlyTrend.options}
                        series={sdotUserMonthlyTrend.series}
                        type="line"
                        height={350}
                      />
                    )}
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Category Usage</h3>
                    {categoryUsage !== null && (
                      <Chart
                        options={categoryUsage.options}
                        series={categoryUsage.series}
                        type="bar"
                        // height={720}
                      />
                    )}
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Post Type Usage</h3>
                    {postUsage !== null && (
                      <Chart
                        options={postUsage.options}
                        series={postUsage.series}
                        type="polarArea"
                        // height={720}
                      />
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SdotStatistics;
