import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  $get,
  $post,
  $convertNumberFormat,
  $dateTimezone,
} from "../utils/common";
import Chart from "react-apexcharts";
import AdminMenu from "../component/AdminMenu";

function UserStatistics() {
  const [userStatistics, setUserStatistics] = useState(null);
  const [wdotUserMonthlyTrend, setWdotUserMonthlyTrend] = useState(null);
  const [idotUserMonthlyTrend, setIdotUserMonthlyTrend] = useState(null);
  const [sdotUserMonthlyTrend, setSdotUserMonthlyTrend] = useState(null);

  const getUserStatistics = async () => {
    const res = await $get("/api/statistics/user");
    if (res.status === 200) {
      // console.log(res.data);
      setUserStatistics({
        series: [
          {
            name: "Total Users",
            data: [
              res.data.wdot.total_user_count,
              res.data.idot.total_user_count,
              res.data.sdot.total_user_count,
            ],
          },
          {
            name: "Active Users",
            data: [
              res.data.wdot.active_user_count,
              res.data.idot.active_user_count,
              res.data.sdot.active_user_count,
            ],
          },
          {
            name: "Unused Users",
            data: [
              res.data.wdot.total_user_no_token_count,
              res.data.idot.total_user_no_token_count,
              res.data.sdot.total_user_no_token_count,
            ],
          },
          {
            name: "Subscription Users",
            data: [
              res.data.wdot.total_subscription_count,
              res.data.idot.total_subscription_count,
              res.data.sdot.total_subscription_count,
            ],
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 430,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: ["Wdot", "Idot", "Sdot"],
          },
        },
      });
    }
  };

  const getUserMonthlyTrend = async () => {
    const res = await $get("/api/statistics/user-new");
    if (res.status === 200) {
      setWdotUserMonthlyTrend({
        series: [
          {
            name: "New Users",
            type: "column",
            data: res.data.wdot.map((item) => item.total_new_users),
          },
          // {
          //   name: "Unused Users",
          //   type: "column",
          //   data: res.data.wdot.map((item) => item.unused_user_count),
          // },
          {
            name: "Subscribed Users",
            type: "line",
            data: res.data.wdot.map((item) => item.subscribed_users),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
          },
          stroke: {
            width: [0, 4],
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels: res.data.wdot.map((item) => item.year + "-" + item.month),
          // xaxis: {
          //   type: "datetime",
          // },
          yaxis: [
            {
              title: {
                text: "New Users",
              },
            },
            {
              opposite: true,
              title: {
                text: "Subscribed Users",
              },
            },
          ],
        },
      });

      setIdotUserMonthlyTrend({
        series: [
          {
            name: "New Users",
            type: "column",
            data: res.data.idot.map((item) => item.total_new_users),
          },
          {
            name: "Subscribed Users",
            type: "line",
            data: res.data.idot.map((item) => item.subscribed_users),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
          },
          stroke: {
            width: [0, 4],
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels: res.data.idot.map((item) => item.year + "-" + item.month),
          // xaxis: {
          //   type: "datetime",
          // },
          yaxis: [
            {
              title: {
                text: "New Users",
              },
            },
            {
              opposite: true,
              title: {
                text: "Subscribed Users",
              },
            },
          ],
        },
      });

      setSdotUserMonthlyTrend({
        series: [
          {
            name: "New Users",
            type: "column",
            data: res.data.sdot.map((item) => item.total_new_users),
          },
          {
            name: "Subscribed Users",
            type: "line",
            data: res.data.sdot.map((item) => item.subscribed_users),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
          },
          stroke: {
            width: [0, 4],
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          labels: res.data.sdot.map((item) => item.year + "-" + item.month),
          // xaxis: {
          //   type: "datetime",
          // },
          yaxis: [
            {
              title: {
                text: "New Users",
              },
            },
            {
              opposite: true,
              title: {
                text: "Subscribed Users",
              },
            },
          ],
        },
      });
    }
  };

  useEffect(() => {
    getUserStatistics();
    getUserMonthlyTrend();
  }, []);

  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AdminMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">User Statistics</h3>
                    {userStatistics !== null && (
                      <Chart
                        options={userStatistics.options}
                        series={userStatistics.series}
                        type="bar"
                        height={430}
                      />
                    )}
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Wdot Monthly Trend</h3>
                    {wdotUserMonthlyTrend !== null && (
                      <Chart
                        options={wdotUserMonthlyTrend.options}
                        series={wdotUserMonthlyTrend.series}
                        type="line"
                        height={350}
                      />
                    )}
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Sdot Monthly Trend</h3>
                    {sdotUserMonthlyTrend !== null && (
                      <Chart
                        options={sdotUserMonthlyTrend.options}
                        series={sdotUserMonthlyTrend.series}
                        type="line"
                        height={350}
                      />
                    )}
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Idot Monthly Trend</h3>
                    {idotUserMonthlyTrend !== null && (
                      <Chart
                        options={idotUserMonthlyTrend.options}
                        series={idotUserMonthlyTrend.series}
                        type="line"
                        height={350}
                      />
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default UserStatistics;
