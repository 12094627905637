import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

function AccountMenu() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="col-lg-3 col-md-4">
      <div className="d-flex align-items-center mb-4 justify-content-center justify-content-md-start">
        <img
          src={
            userData &&
            userData.profile_image_url &&
            userData.profile_image_url.startsWith("/static/images")
              ? `${serverUrl}${userData.profile_image_url}`
              : userData.profile_image_url !== null &&
                userData.profile_image_url !== ""
              ? userData.profile_image_url
              : "./assets/images/avatar/fallback.jpg"
          }
          alt="avatar"
          className="avatar avatar-lg rounded-circle"
        />
        <div className="ms-3">
          <h5 className="mb-0">
            {userData.nickname ? userData.nickname : userData.email}
          </h5>
          <small>
            {userData.role_level === 0 ? "Admin" : "Personal"} account
          </small>
          {/* <small>Personal account</small> */}
        </div>
      </div>
      <div className="d-md-none text-center d-grid">
        <button
          className="btn btn-light mb-3 d-flex align-items-center justify-content-between"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseAccountMenu"
          aria-expanded="false"
          aria-controls="collapseAccountMenu"
        >
          Account Menu
          <i className="bi bi-chevron-down ms-2"></i>
        </button>
      </div>
      <div className="collapse d-md-block" id="collapseAccountMenu">
        <ul className="nav flex-column nav-account">
          <li className="nav-item">
            <a
              className={
                location.pathname === "/account-assistant"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/account-assistant")}
            >
              <i className="align-bottom bx bx-message-dots"></i>
              <span className="ms-2">My Assistant</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/account-profile"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/account-profile")}
            >
              <i className="align-bottom bx bx-user"></i>
              <span className="ms-2">Profile</span>
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              className={
                location.pathname === "/account-security"
                  ? "nav-link active"
                  : "nav-link"
              }
              aria-current="page"
              onClick={() => navigate("/account-security")}
            >
              <i className="align-bottom bx bx-lock-alt"></i>
              <span className="ms-2">Security</span>
            </a>
          </li> */}
          <li className="nav-item">
            <a
              className={
                location.pathname === "/account-billing"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/account-billing")}
            >
              <i className="align-bottom bx bx-credit-card-front"></i>
              <span className="ms-2">Billing</span>
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              className={
                location.pathname === "/account-team"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/account-team")}
            >
              <i className="align-bottom bx bx-user-plus"></i>
              <span className="ms-2">Team</span>
            </a>
          </li> */}
          <li className="nav-item">
            <a
              className={
                location.pathname === "/account-subscription"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/account-subscription")}
            >
              <i className="align-bottom bx bx-dollar-circle"></i>
              <span className="ms-2">Subscription</span>
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              className={
                location.pathname === "/account-session"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/account-session")}
            >
              <i className="align-bottom bx bx-time"></i>
              <span className="ms-2">Session</span>
            </a>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="./index.html">
              <i className="align-bottom bx bx-log-out"></i>
              <span className="ms-2">Sign Out</span>
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default AccountMenu;
