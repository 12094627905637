import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  $get,
  $post,
  $convertNumberFormat,
  $dateTimezone,
} from "../utils/common";
import Chart from "react-apexcharts";
import AdminMenu from "../component/AdminMenu";

function SubscriptionStatistics() {
  const [subscriptionStatistics, setSubscriptionStatistics] = useState(null);
  const [subscriptionTypeStatistics, setSubscriptionTypeStatistics] =
    useState(null);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [billingAmountList, setBillingAmountList] = useState([]);

  const getSubscriptionStatistics = async () => {
    const res = await $get("/api/statistics/subscription");
    if (res.status === 200) {
      // wdot, idot, sdot 3개의 assistant_code에 대한 구독자 수
      // year, month, count
      // 최근 12개월
      // 해당 년월이 없으면 0으로 표시

      const subscriptionData = {
        wdot: {},
        idot: {},
        sdot: {},
      };

      res.data.wdot.forEach((item) => {
        subscriptionData.wdot[`${item.year}-${item.month}`] = item.count;
      });

      res.data.idot.forEach((item) => {
        subscriptionData.idot[`${item.year}-${item.month}`] = item.count;
      });

      res.data.sdot.forEach((item) => {
        subscriptionData.sdot[`${item.year}-${item.month}`] = item.count;
      });

      // subscriptionData의 wdot, idot, sdot 데이터를 모두 확인해서 min year-month를 찾는다.
      const yearMonthArray = [];
      for (const key in subscriptionData.wdot) {
        yearMonthArray.push({
          year: parseInt(key.split("-")[0]),
          month: parseInt(key.split("-")[1]),
        });
      }
      for (const key in subscriptionData.idot) {
        yearMonthArray.push({
          year: parseInt(key.split("-")[0]),
          month: parseInt(key.split("-")[1]),
        });
      }
      for (const key in subscriptionData.sdot) {
        yearMonthArray.push({
          year: parseInt(key.split("-")[0]),
          month: parseInt(key.split("-")[1]),
        });
      }

      const minYearMonth = yearMonthArray.reduce((prev, current) =>
        prev.year < current.year
          ? prev
          : prev.year > current.year
          ? current
          : prev.month < current.month
          ? prev
          : current
      );

      // subscriptionData의 wdot, idot, sdot 데이터를 모두 확인해서 max year-month를 찾는다.
      const maxYearMonth = yearMonthArray.reduce((prev, current) =>
        prev.year > current.year
          ? prev
          : prev.year < current.year
          ? current
          : prev.month > current.month
          ? prev
          : current
      );

      // min year-month부터 max year-month까지의 데이터를 만든다.
      const monthArray = [];
      for (let year = minYearMonth.year; year <= maxYearMonth.year; year++) {
        for (
          let month = 1;
          month <= (year === maxYearMonth.year ? maxYearMonth.month : 12);
          month++
        ) {
          monthArray.push({
            year,
            month,
          });
        }
      }

      const wdot = [];
      const idot = [];
      const sdot = [];
      monthArray.forEach((month) => {
        wdot.push(subscriptionData.wdot[`${month.year}-${month.month}`] || 0);
        idot.push(subscriptionData.idot[`${month.year}-${month.month}`] || 0);
        sdot.push(subscriptionData.sdot[`${month.year}-${month.month}`] || 0);
      });

      setSubscriptionStatistics({
        series: [
          {
            name: "Wdot",
            data: wdot,
          },
          {
            name: "Idot",
            data: idot,
          },
          {
            name: "Sdot",
            data: sdot,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          // title: {
          //   text: "Product Trends by Month",
          //   align: "left",
          // },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: monthArray.map(
              (item) => `${item.year}-${String(item.month).padStart(2, "0")}`
            ),
          },
        },
      });
    }
  };

  const getSubscriptionTypeStatistics = async () => {
    const res = await $get("/api/statistics/type");
    if (res.status === 200) {
      setSubscriptionTypeStatistics(res.data);
    }
  };

  const getBillingAmountStatistics = async () => {
    const res = await $get("/api/statistics/billing_amount");
    if (res.status === 200) {
      setBillingAmountList(res.data);
    }
  };

  useEffect(() => {
    getSubscriptionStatistics();
    getSubscriptionTypeStatistics();
    getBillingAmountStatistics();
  }, []);

  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AdminMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Monthly New Subscriber Trends</h3>
                    {subscriptionStatistics !== null && (
                      <Chart
                        options={subscriptionStatistics.options}
                        series={subscriptionStatistics.series}
                        type="line"
                        height={350}
                      />
                    )}
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Total Subscriber</h3>
                    <div className="table-responsive">
                      <table className="table table-hover align-middle">
                        <thead>
                          <tr className="table-dark">
                            <th>Assistant</th>
                            <th>Monthly</th>
                            <th>Yearly</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Wdot</td>
                            <td>
                              {subscriptionTypeStatistics &&
                                subscriptionTypeStatistics.wdot.M}
                            </td>
                            <td>
                              {subscriptionTypeStatistics &&
                                subscriptionTypeStatistics.wdot.Y}
                            </td>
                          </tr>
                          <tr>
                            <td>Idot</td>
                            <td>
                              {subscriptionTypeStatistics &&
                                subscriptionTypeStatistics.idot.M}
                            </td>
                            <td>
                              {subscriptionTypeStatistics &&
                                subscriptionTypeStatistics.idot.Y}
                            </td>
                          </tr>
                          <tr>
                            <td>Sdot</td>
                            <td>
                              {subscriptionTypeStatistics &&
                                subscriptionTypeStatistics.sdot.M}
                            </td>
                            <td>
                              {subscriptionTypeStatistics &&
                                subscriptionTypeStatistics.sdot.Y}
                            </td>
                          </tr>
                          <tr>
                            <td>W.I.S Package</td>
                            <td>
                              {subscriptionTypeStatistics &&
                                subscriptionTypeStatistics.p1.M}
                            </td>
                            <td>
                              {subscriptionTypeStatistics &&
                                subscriptionTypeStatistics.p1.Y}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Total Billing Amount By Month</h3>
                    <div className="table-responsive">
                      <table className="table table-hover align-middle">
                        <thead>
                          <tr className="table-dark">
                            <th>Year</th>
                            <th>Month</th>
                            <th>Total(KRW)</th>
                            <th>Total(USD)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billingAmountList.map((item, index) => (
                            <tr key={index}>
                              <td>{item.year}</td>
                              <td>{item.month}</td>
                              <td>
                                {$convertNumberFormat(item.total_amount_krw)}원
                              </td>
                              <td>
                                $
                                {$convertNumberFormat(
                                  item.total_amount_usd,
                                  "#,###.##"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SubscriptionStatistics;
