import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import { $post, $put, $upload, $get, $dateTimezone } from "../utils/common";
import Swal from "sweetalert2";
import AccountMenu from "../component/AccountMenu";

function AccountAssistant() {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const [assistantList, setAssistantList] = useState([]);

  const getUserAssistantList = async () => {
    const res = (await $get("/api/subscription")).data;
    setAssistantList(res);
  };

  useEffect(() => {
    getUserAssistantList();
  }, []);

  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AccountMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <h1 className="mb-0 h3">My Assistant</h1>
              </div>
              <div className="card border-0 shadow-sm mb-4">
                <div className="card-body p-lg-5">
                  {/* <div className="mb-5">
                    <h4 className="mb-1">Profile Picture</h4>
                    <p className="mb-0 fs-6">
                      Upload a picture to make your profile stand out and let
                      people recognize your activities easily!
                    </p>
                  </div> */}
                  <div className="row g-lg-7 gy-5">
                    <div className="col-lg-4 col-md-6">
                      <div className="text-center">
                        <a href="https://wdot.wonderslab.ai" target="_blank">
                          <div className="icon-xlg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle">
                            <img
                              src="./assets/images/logo/wdot-logo.png"
                              alt=""
                              style={{ height: "6rem" }}
                            />
                          </div>
                        </a>
                        {assistantList.find(
                          (x) =>
                            x.assistant_code === "wdot" ||
                            x.assistant_code === "p1"
                        ) ? (
                          <div>
                            <button className="btn btn-primary mt-3">
                              Active
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              className="btn btn-dark mt-3"
                              onClick={() => navigate("/account-subscription")}
                            >
                              Subscribe
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="text-center">
                        <a href="https://idot.wonderslab.ai" target="_blank">
                          <div className="icon-xlg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle">
                            <img
                              src="./assets/images/logo/idot-logo.png"
                              alt=""
                              style={{ height: "6rem" }}
                            />
                          </div>
                        </a>
                        {assistantList.find(
                          (x) =>
                            x.assistant_code === "idot" ||
                            x.assistant_code === "p1"
                        ) ? (
                          <div>
                            <button className="btn btn-primary mt-3">
                              Active
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              className="btn btn-dark mt-3"
                              onClick={() => navigate("/account-subscription")}
                            >
                              Subscribe
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="text-center">
                        <a href="https://sdot.wonderslab.ai" target="_blank">
                          <div className="icon-xlg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle">
                            <img
                              src="./assets/images/logo/sdot-logo.png"
                              alt=""
                              style={{ height: "6rem" }}
                            />
                          </div>
                        </a>
                        {assistantList.find(
                          (x) =>
                            x.assistant_code === "sdot" ||
                            x.assistant_code === "p1"
                        ) ? (
                          <div>
                            <button className="btn btn-primary mt-3">
                              Active
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              className="btn btn-dark mt-3"
                              onClick={() => navigate("/account-subscription")}
                            >
                              Subscribe
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="text-center">
                        <div className="icon-xlg icon-shape rounded bg-dark bg-opacity-10 border border-dark mb-5">
                          <img
                            src="./assets/images/logo/tdot-logo.png"
                            alt=""
                            style={{ height: "6rem" }}
                          />
                        </div>

                        <h4>Tdot(coming soon)</h4>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="text-center">
                        <div className="icon-xlg icon-shape rounded bg-dark bg-opacity-10 border border-dark mb-5">
                          <img
                            src="./assets/images/logo/cdot-logo.png"
                            alt=""
                            style={{ height: "6rem" }}
                          />
                        </div>

                        <h4>Cdot(coming soon)</h4>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="text-center">
                        <div className="icon-xlg icon-shape rounded bg-dark bg-opacity-10 border border-dark mb-5">
                          <img
                            src="./assets/images/logo/edot-logo.png"
                            alt=""
                            style={{ height: "6rem" }}
                          />
                        </div>

                        <h4>Edot(coming soon)</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AccountAssistant;
