import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import { $post } from "../utils/common";
import Swal from "sweetalert2";

function Footer() {
  const appUrl = process.env.REACT_APP_URL;
  const userLocalLang = navigator.language || navigator.userLanguage;
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [subscribeEmail, setSubscribeEmail] = useState("");

  const subscribeNewsletter = async () => {
    if (subscribeEmail === "") {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Email Required",
        text: "Please enter email.",
      });
    } else {
      // email validation
      const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailReg.test(subscribeEmail)) {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: "Invalid Email",
          text: "Please enter valid email.",
        });
        return;
      }
      const r = await $post("/api/user/newsletter", {
        email: subscribeEmail,
        lang: userLocalLang,
      });
      if (r.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "You have subscribed to our newsletter.",
        });
        setSubscribeEmail("");
      }
    }
  };

  return (
    <footer className="pt-7 border-top">
      <div className="container">
        <div className="row">
          <div className="col-xxl-5 col-lg-5 col-md-7">
            <div className="mb-7 mb-xl-0">
              <div className="mb-4">
                <a href="#">
                  <img
                    src="./assets/images/logo/wdot-logo.png"
                    style={{ height: "40px" }}
                  />
                </a>
              </div>
              <p className="mb-5">
                Unlock the secret to completing your tasks faster and more
                efficiently, join Wonderslab AI Assistant. We make your work
                routine simpler and more convenient.
              </p>
              {/* <ul>
                <li className="mb-1">
                  주식회사 원더스랩(WondersLab) | 대표: 한의선(Sunny Han)
                </li>
                <li className="mb-1">사업자등록번호: 4768701809</li>
                <li className="mb-1">
                  6, Seongnam-daero 779beon-gil, Bundang-gu, Seongnam-si,
                  Gyeonggi-do, Republic of Korea
                </li>
                <li className="">Tel: +82-10-9066-7352</li>
              </ul> */}

              <div className="needs-validation" noValidate>
                <h5 className="mb-3">Subscribe to our newsletter</h5>
                <div className="row g-2">
                  <div className="col-lg-9 col-8">
                    <label htmlFor="subscribeEmail" className="visually-hidden">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="subscribeEmail"
                      placeholder="Email address"
                      value={subscribeEmail}
                      onChange={(e) => setSubscribeEmail(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          subscribeNewsletter();
                        }
                      }}
                    />
                    <div className="invalid-feedback">Please enter email.</div>
                  </div>
                  <div className="col-lg-3 col-4">
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => subscribeNewsletter()}
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="offset-xxl-1 col-xxl-6 col-lg-6 offset-md-1 col-md-4">
            <div className="row" id="ft-links">
              <div className="col-lg-4 col-12">
                <div className="position-relative">
                  <div className="mb-3 pb-2 d-flex justify-content-between border-bottom border-bottom-lg-0">
                    <h5>AI Assistant</h5>
                    <a
                      className="d-block d-lg-none stretched-link text-inherit"
                      data-bs-toggle="collapse"
                      href="#collapseLanding"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseLanding"
                    >
                      <i className="bi bi-chevron-down"></i>
                    </a>
                  </div>
                  <div
                    className="collapse d-lg-block"
                    id="collapseLanding"
                    data-bs-parent="#ft-links"
                  >
                    <ul className="list-unstyled mb-0 py-3 py-lg-0">
                      <li className="mb-2">
                        <a
                          onClick={() => navigate("/service-wdot")}
                          target="_blank"
                          className="text-decoration-none text-reset notranslate"
                        >
                          Wdot
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          onClick={() => navigate("/service-idot")}
                          target="_blank"
                          className="text-decoration-none text-reset notranslate"
                        >
                          Idot
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          onClick={() => navigate("/service-sdot")}
                          target="_blank"
                          className="text-decoration-none text-reset notranslate"
                        >
                          Sdot
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-12">
                <div className="mb-3 pb-2 d-flex justify-content-between border-bottom border-bottom-lg-0 position-relative">
                  <h5>Accounts</h5>
                  <a
                    className="d-block d-lg-none stretched-link text-inherit"
                    data-bs-toggle="collapse"
                    href="#collapseAccounts"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseAccounts"
                  >
                    <i className="bi bi-chevron-down"></i>
                  </a>
                </div>
                <div
                  className="collapse d-lg-block"
                  id="collapseAccounts"
                  data-bs-parent="#ft-links"
                >
                  <ul className="list-unstyled mb-0 py-3 py-lg-0">
                    <li className="mb-2">
                      <a
                        onClick={() => navigate("/signup")}
                        className="text-decoration-none text-reset"
                      >
                        Register
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        onClick={() => navigate("/signin")}
                        className="text-decoration-none text-reset"
                      >
                        Login
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        onClick={() => navigate("/forget-password")}
                        className="text-decoration-none text-reset"
                      >
                        Forgot Password
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        onClick={() => navigate("/reset-password")}
                        className="text-decoration-none text-reset"
                      >
                        Reset Password
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        onClick={() => navigate("/account-profile")}
                        className="text-decoration-none text-reset"
                      >
                        Profile
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="col-lg-4 col-12">
                <div className="mb-3 pb-2 d-flex justify-content-between border-bottom border-bottom-lg-0 position-relative">
                  <h5>Event</h5>
                  <a
                    className="d-block d-lg-none stretched-link text-inherit"
                    data-bs-toggle="collapse"
                    href="#collapseResources"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseResources"
                  >
                    <i className="bi bi-chevron-down"></i>
                  </a>
                </div>
                <div
                  className="collapse d-lg-block"
                  id="collapseResources"
                  data-bs-parent="#ft-links"
                >
                  <ul className="list-unstyled mb-0 py-3 py-lg-0">
                    <li className="mb-2">
                      <a
                        onClick={() => navigate("/eventlist")}
                        className="text-decoration-none text-reset"
                      >
                        Events
                      </a>
                    </li>
                    {/* <li className="mb-2">
                      <a href="#!" className="text-decoration-none text-reset">
                        Help Center
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="mb-3 pb-2 d-flex justify-content-between border-bottom border-bottom-lg-0 position-relative">
                  <h5>Contact</h5>
                  <a
                    className="d-block d-lg-none stretched-link text-inherit"
                    data-bs-toggle="collapse"
                    href="#collapseResources"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseResources"
                  >
                    <i className="bi bi-chevron-down"></i>
                  </a>
                </div>
                <div
                  className="collapse d-lg-block"
                  id="collapseResources"
                  data-bs-parent="#ft-links"
                >
                  <ul className="list-unstyled mb-0 py-3 py-lg-0">
                    <li className="mb-2">
                      <a
                        onClick={() => navigate("/contact")}
                        className="text-decoration-none text-reset"
                      >
                        Contact Us
                      </a>
                    </li>
                    {/* <li className="mb-2">
                      <a href="#!" className="text-decoration-none text-reset">
                        Help Center
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-7 mb-3">
        <div className="row align-items-center">
          <div className="col-md-9">
            <div className="small mb-3 mb-md-0">
              Copyright © {currentYear}{" "}
              <span className="text-primary">
                <a href="#">Wonderslab</a>
              </span>
            </div>
          </div>
          {/* <div className="col-md-3">
            <div className="text-md-end d-flex align-items-center justify-content-md-end">
              <div className="ms-3 d-flex gap-2">
                <a href="#!" className="text-reset btn btn-social btn-icon">
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="#!" className="text-reset btn btn-social btn-icon">
                  <i className="fa-brands fa-square-facebook"></i>
                </a>
                <a href="#!" className="text-reset btn btn-social btn-icon">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
