import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
import Swal from "sweetalert2";

function Signup() {
  const appUrl = process.env.REACT_APP_URL;
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [terms, setTerms] = useState(false);

  const handleSignup = async () => {
    if (!email) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Email Required",
        text: "Please enter email",
      });
      return;
    }

    if (password.length < 8) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Password Length",
        text: "Password must be at least 8 characters",
      });
      return;
    }

    // Make sure it's at least 8 characters including a number and a uppercase letter.
    if (!password.match(/^(?=.*[A-Z])(?=.*\d).{8,}$/)) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Password Format",
        text: "Password must contain at least one uppercase letter and one number",
      });
      return;
    }

    if (password !== confirmPassword) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Password Mismatch",
        text: "Password and Confirm Password do not match",
      });
      return;
    }

    if (!terms) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Terms of Use",
        text: "Please agree to the terms of use",
      });
      return;
    }

    const data = {
      email,
      password,
    };
    const res = await $post("/api/user/signup", data);
    if (res.status === 200) {
      if (res.data.status === "fail") {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: "Signup Failed",
          text: res.data.message,
        });
      } else {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: "Signup Success",
          text: "Please check your email to verify your account",
        });

        navigate("/signin");
      }
    }
  };

  return (
    <main className="position-relative">
      <div className="pattern-square"></div>
      <section className="py-5 py-lg-8">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 offset-xl-4 col-md-12 col-12">
              <div className="text-center">
                <a href="index.html">
                  <img
                    src="./assets/images/logo/wdot-logo.png"
                    style={{ height: "80px" }}
                    alt="brand"
                    className="mb-3"
                  />
                </a>
                <h1 className="mb-1">Create Account</h1>
                <p className="mb-0">
                  Sign up now and get free account instant.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center mb-6">
            <div className="col-xl-5 col-lg-6 col-md-8 col-12">
              <div className="card shadow-sm mb-3">
                <div className="card-body">
                  <div className="needs-validation mb-6" noValidate>
                    <div className="mb-3">
                      <label htmlFor="signupEmailInput" className="form-label">
                        Email
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="signupEmailInput"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter email.
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="formSignUpPassword"
                        className="form-label"
                      >
                        Password
                        <span className="text-danger">*</span>
                      </label>
                      <div className="password-field position-relative">
                        <input
                          type="password"
                          className="form-control fakePassword"
                          id="formSignUpPassword"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          required
                        />

                        <span>
                          <i className="bi bi-eye-slash passwordToggler"></i>
                        </span>
                        <div className="invalid-feedback">
                          Please enter password.
                        </div>
                      </div>
                      <div className="form-text">
                        Make sure it's at least 8 characters including a number
                        and a uppercase letter.
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="formSignUpConfirmPassword"
                        className="form-label"
                      >
                        Confirm Password
                        <span className="text-danger">*</span>
                      </label>
                      <div className="password-field position-relative">
                        <input
                          type="password"
                          className="form-control fakePassword"
                          id="formSignUpConfirmPassword"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                        <span>
                          <i className="bi bi-eye-slash passwordToggler"></i>
                        </span>
                        <div className="invalid-feedback">
                          Please enter password.
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="mb-4 d-flex align-items-center justify-content-between">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="signupCheckTextCheckbox"
                            checked={terms}
                            onClick={() => setTerms(!terms)}
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="signupCheckTextCheckbox"
                          >
                            <a
                              href="https://wonderslab.notion.site/Terms-of-Service-a9a0ed72416b462eb313065e7f2e1bd7?pvs=4"
                              target="_blank"
                            >
                              Terms of Use
                            </a>{" "}
                            &{" "}
                            <a
                              href="https://wonderslab.notion.site/Privacy-Policy-9961c2e36d9c4c2487adc066fdd5588f?pvs=4"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="d-grid">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => handleSignup()}
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>

                  <span>Sign up with your social network.</span>
                  <div className="d-grid mt-3">
                    <a href="#" className="btn btn-google">
                      <span className="me-2">
                        <i className="fa-brands fa-google"></i>
                      </span>
                      Continue with Google
                    </a>
                  </div>
                  {/* <div className="d-grid mt-2">
                    <a href="#" className="btn btn-facebook">
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-facebook"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                        </svg>
                      </span>
                      Continue with Facebook
                    </a>
                  </div> */}
                </div>
              </div>

              <span>
                Already have an account?{" "}
                <a onClick={() => navigate("/signin")} className="text-primary">
                  Sign in here.
                </a>
              </span>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Signup;
