import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import { $post, $put, $get, $convertMarkdownToHtml } from "../utils/common";
import Swal from "sweetalert2";

function Header() {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const logoutWithKakao = async (accessToken) => {
    // const res = (await $get("/api/login-check")).data;
    // if (res.isLoggedIn) {
    fetch("https://kapi.kakao.com/v1/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        window.Kakao.Auth.setAccessToken(null);
        logout();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   console.log("Not logged in.");
    //   return;
    // }
  };

  const socialLogout = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      if (res.loginType === "KAKAO") {
        logout();
      } else if (res.loginType === "GOOGLE") {
        logout();
      } else if (res.loginType === "IDPW") {
        logout();
      }
    }
  };

  return (
    <header
      style={{
        position: "sticky",
        top: "0",
        zIndex: "999",
        backgroundColor: "white",
      }}
    >
      <nav className="navbar navbar-expand-lg navbar-light w-100">
        <div className="container px-3">
          <a className="navbar-brand" onClick={() => navigate("/")}>
            <img
              src="./assets/images/logo/wdot-logo.png"
              style={{ height: "40px" }}
            />
          </a>
          <button
            className="navbar-toggler offcanvas-nav-btn"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            onClick={() => setShowOffcanvas(true)}
          >
            <i className="bi bi-list"></i>
          </button>
          <div
            className="offcanvas offcanvas-start offcanvas-nav"
            id="offcanvasMenu"
            style={{ width: "20rem" }}
          >
            <div className="offcanvas-header">
              <a onClick={() => navigate("/")} className="text-inverse">
                <img
                  src="./assets/images/logo/wdot-logo.png"
                  style={{ height: "40px" }}
                />
              </a>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body pt-0 align-items-center">
              <ul className="navbar-nav mx-auto align-items-lg-center">
                <li className="nav-item ">
                  <a
                    className="nav-link"
                    onClick={() => navigate("/")}
                    role="button"
                    aria-expanded="false"
                  >
                    Home
                  </a>
                </li>

                <li className="nav-item dropdown dropdown-fullwidth">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    AI Assistant
                  </a>
                  <div className="dropdown-menu p-4">
                    <div className="row row-cols-xl-6 row-cols-lg-5 row-cols-1 gx-lg-4">
                      <div className="col">
                        <a
                          className="dropdown-item d-block px-0 mb-lg-3"
                          onClick={() => navigate("/service-wdot")}
                        >
                          <div className="rounded d-none d-lg-block mb-lg-2">
                            <img
                              className="w-100 rounded-2"
                              src="./assets/images/landings/overview/overview-wdot.jpg"
                            />
                          </div>
                          <span className="notranslate">Wdot</span>
                        </a>
                      </div>
                      <div className="col">
                        <a
                          className="dropdown-item d-block px-0 mb-lg-3"
                          onClick={() => navigate("/service-idot")}
                        >
                          <div className="rounded d-none d-lg-block mb-lg-2">
                            <img
                              className="w-100 rounded-2"
                              src="./assets/images/landings/overview/overview-idot.jpg"
                            />
                          </div>
                          <span className="notranslate">Idot</span>
                        </a>
                      </div>
                      <div className="col">
                        <a
                          className="dropdown-item d-block px-0 mb-lg-3"
                          onClick={() => navigate("/service-sdot")}
                        >
                          <div className="rounded d-none d-lg-block mb-lg-2">
                            <img
                              className="w-100 rounded-2"
                              src="./assets/images/landings/overview/overview-sdot.jpg"
                            />
                          </div>
                          <span className="notranslate">Sdot</span>
                        </a>
                      </div>
                      <div className="col">
                        <a className="dropdown-item d-block px-0 mb-lg-3">
                          <div className="rounded d-none d-lg-block mb-lg-2">
                            <img
                              className="w-100 rounded-2"
                              src="./assets/images/landings/overview/coming-soon.jpg"
                            />
                          </div>
                          <span className="notranslate">Tdot</span>
                        </a>
                      </div>
                      <div className="col">
                        <a className="dropdown-item d-block px-0 mb-lg-3">
                          <div className="rounded d-none d-lg-block mb-lg-2">
                            <img
                              className="w-100 rounded-2"
                              src="./assets/images/landings/overview/coming-soon.jpg"
                            />
                          </div>
                          <span className="notranslate">Cdot</span>
                        </a>
                      </div>
                      <div className="col">
                        <a className="dropdown-item d-block px-0 mb-lg-3">
                          <div className="rounded d-none d-lg-block mb-lg-2">
                            <img
                              className="w-100 rounded-2"
                              src="./assets/images/landings/overview/coming-soon.jpg"
                            />
                          </div>
                          <span className="notranslate">Edot</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                {isLoggedIn && (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Accounts
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/account-assistant")}
                        >
                          My Assistant
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/account-profile")}
                        >
                          Profile
                        </a>
                      </li>
                      {/* <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/account-security")}
                        >
                          Security
                        </a>
                      </li> */}
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/account-billing")}
                        >
                          Billing
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/account-subscription")}
                        >
                          Subscription
                        </a>
                      </li>
                    </ul>
                  </li>
                )}

                <li className="nav-item ">
                  <a
                    className="nav-link"
                    onClick={() => navigate("/pricing")}
                    role="button"
                    aria-expanded="false"
                  >
                    Pricing
                  </a>
                </li>
                {/* <li className="nav-item ">
                  <a
                    className="nav-link"
                    onClick={() => navigate("/eventlist")}
                    role="button"
                    aria-expanded="false"
                  >
                    Event
                  </a>
                </li> */}
                <li className="nav-item ">
                  <a
                    className="nav-link"
                    onClick={() => navigate("/latest-updates")}
                    role="button"
                    aria-expanded="false"
                  >
                    Latest Updates
                  </a>
                </li>
                <li className="nav-item ">
                  <a
                    className="nav-link"
                    onClick={() => navigate("/contact")}
                    role="button"
                    aria-expanded="false"
                  >
                    Contact
                  </a>
                </li>
                {userData && userData.role_level === 0 && (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Admin
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/admin-tokencost")}
                        >
                          Token Usage & Cost
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/admin-opportunity")}
                        >
                          Uncompleted Subscription
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/admin-user-statistics")}
                        >
                          User Statistics
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            navigate("/admin-subscription-statistics")
                          }
                        >
                          Subscription Statistics
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/admin-subscriberlist")}
                        >
                          Subscriber List
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/admin-billinglist")}
                        >
                          Billing List
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/admin-userlist")}
                        >
                          User List
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/admin-partnerlist")}
                        >
                          Partner List
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/admin-wdot-statistics")}
                        >
                          Wdot Statistics
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/admin-idot-statistics")}
                        >
                          Idot Statistics
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/admin-sdot-statistics")}
                        >
                          Sdot Statistics
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate("/admin-newsletterlist")}
                        >
                          Newsletter List
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
              {isLoggedIn ? (
                <div className="mt-3 mt-lg-0 d-flex align-items-center">
                  <a
                    onClick={() => socialLogout()}
                    className="btn btn-light mx-2"
                  >
                    Logout
                  </a>
                </div>
              ) : (
                <div className="mt-3 mt-lg-0 d-flex align-items-center">
                  <a
                    onClick={() => navigate("/signin")}
                    className="btn btn-light mx-2"
                  >
                    Login
                  </a>
                  {/* <a
                    onClick={() => navigate("/signup")}
                    className="btn btn-primary"
                  >
                    Create account
                  </a> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
