import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $get,
  $convertMarkdownToHtml,
  $convertNumberFormat,
} from "../utils/common";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import Loading from "../component/Loading";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

function Home() {
  const userLocalLang = navigator.language || navigator.userLanguage;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const naverClientID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoggedIn, userData, login, logout } = useAuth();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [priceType, setPriceType] = useState("yearly");
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [totalDocumentCount, setTotalDocumentCount] = useState(0);
  const [totalPostCount, setTotalPostCount] = useState(0);
  const [totalImageCount, setTotalImageCount] = useState(0);

  const getTotalStatistics = async () => {
    const res = await $get("/api/total-statistics");
    if (res.status === 200) {
      setTotalUserCount(res.data.total_user_count);
      setTotalDocumentCount(res.data.total_document_count);
      setTotalPostCount(res.data.total_post_count);
      setTotalImageCount(res.data.total_image_count);
    }
  };

  const loginWithKakao = () => {
    window.Kakao.Auth.authorize({
      redirectUri: appUrl,
      scope: "profile_nickname, profile_image, account_email",
      //   state: "userme",
      //   serviceTerms: "account_email",
    });
  };

  const requestUserInfo = async (tokenInfo) => {
    const res = await fetch("https://kapi.kakao.com/v2/user/me", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.Kakao.Auth.getAccessToken()}`,
      },
    });

    if (res.status === 401) {
      return;
    }

    const resJson = await res.json();

    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    }

    const res2 = await $post("/api/kakao-login", {
      email: resJson.kakao_account.email,
      nickname: resJson.kakao_account.profile.nickname,
      profile_image_url: resJson.kakao_account.profile.profile_image_url,
      access_token: tokenInfo.access_token,
      access_token_expires_in: tokenInfo.expires_in,
      refresh_token: tokenInfo.refresh_token,
      refresh_token_expires_in: tokenInfo.refresh_token_expires_in,
      lang: userLanguage,
    });

    const userInfo = res2.data[0];
    setLoading(false);

    if (res2.status === 200) {
      login({
        ...userInfo,
      });

      navigate("/work");

      // if (userInfo.role_level === 1) {
      //   if (userInfo.active_yn === "N") navigate("/waiting-list");
      // }
    }
  };

  const getToken = async (code) => {
    const res = await fetch("https://kauth.kakao.com/oauth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}&redirect_uri=${appUrl}&code=${code}`,
    });

    const resJson = await res.json();

    if (resJson.access_token) {
      window.Kakao.Auth.setAccessToken(resJson.access_token);
      requestUserInfo(resJson);
    }
  };

  const requestKaKaoInfo = async () => {
    const params = new URL(document.location.toString()).searchParams;
    const code = params.get("code");
    if (code) {
      setLoading(true);
      getToken(code);
    }
  };

  const logoutWithKakao = async (accessToken) => {
    // const res = (await $get("/api/login-check")).data;
    // if (res.isLoggedIn) {
    fetch("https://kapi.kakao.com/v1/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        window.Kakao.Auth.setAccessToken(null);
        logout();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   console.log("Not logged in.");
    //   return;
    // }
  };

  const unlinkKakao = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      fetch("https://kapi.kakao.com/v1/user/unlink", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${res.accessToken}`,
        },
      })
        .then((res) => {
          window.Kakao.Auth.setAccessToken(null);
          logout();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Not logged in.");
      return;
    }
  };

  const initKaKao = () => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }
  };

  const socialLogout = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      if (res.loginType === "KAKAO") {
        logoutWithKakao(res.accessToken);
      } else if (res.loginType === "GOOGLE") {
        logout();
      } else if (res.loginType === "NAVER") {
        logout();
      }
    }
  };

  const onGoogleSuccess = async (response) => {
    const resData = jwtDecode(response.credential);
    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    }

    const res = await $post("/api/google-login", {
      // tokenId: resData.azp,
      email: resData.email,
      nickname: resData.name,
      profile_image_url: resData.picture,
      access_token_expires_in: resData.exp,
      lang: userLanguage,
    });
    // 서버로 토큰 전송

    const userInfo = res.data[0];
    // setLoading(false);

    if (res.status === 200) {
      login({
        ...userInfo,
      });
    }
  };

  const onGoogleFailure = (response) => {
    console.log("Login Failed:", response);
  };

  const loginWithGoogle = async () => {
    console.log(`${serverUrl}/api/google-login`);
    let url = "https://accounts.google.com/o/oauth2/v2/auth";
    // client_id는 위 스크린샷을 보면 발급 받았음을 알 수 있음
    // 단, 스크린샷에 있는 ID가 아닌 당신이 직접 발급 받은 ID를 사용해야 함.
    url += `?client_id=${googleClientId}`;
    // 아까 등록한 redirect_uri
    // 로그인 창에서 계정을 선택하면 구글 서버가 이 redirect_uri로 redirect 시켜줌
    url += `&redirect_uri=${serverUrl}/api/google-login`;
    // 필수 옵션.
    url += "&response_type=code";
    // 구글에 등록된 유저 정보 email, profile을 가져오겠다 명시
    url += "&scope=email profile";
    // 완성된 url로 이동
    // 이 url이 위에서 본 구글 계정을 선택하는 화면임.

    // 브라우저 url을 이동
    document.location.href = url;
  };

  const init = () => {
    // fire window resize event to make the layout responsive
    window.dispatchEvent(new Event("resize"));
  };

  useEffect(() => {
    init();
    initKaKao();
    requestKaKaoInfo();
    i18n.changeLanguage(userLocalLang === "ko-KR" ? "KO" : "EN");
    // const modal = new window.bootstrap.Modal("#promptModal", {
    //   backdrop: "static",
    //   keyboard: false,
    // });
    // setPromptModal(modal);

    getTotalStatistics();

    const lightbox = GLightbox({
      selector: ".glightbox",
    });

    return () => lightbox.destroy();
  }, []);

  return (
    <main>
      <div className="pattern-square"></div>
      <section className="pt-lg-7 pt-5" data-cue="fadeIn">
        <div className="container">
          <div className="row align-items-center justify-content-lg-start justify-content-center flex-lg-nowrap">
            <div
              className="col-lg-5 col-12"
              data-cues="slideInDown"
              data-group="page-title"
              data-delay="700"
            >
              <div className="text-center text-lg-start" data-cue="zoomIn">
                <div className="mb-5">
                  <span className="badge align-items-center p-2 pe-3 ps-3 fs-6 text-primary border border-primary-subtle rounded-pill mb-4">
                    Accelerate Work Completion
                  </span>
                  <h1 className="mb-3 display-4">Get your work done fast.</h1>
                  <p className="lead mb-0">
                    Unlock the secret to completing your tasks faster and more
                    efficiently, join Wonderslab AI Assistant. We make your work
                    routine simpler and more convenient.
                  </p>
                </div>
                {/* <div
                  data-cues="slideInDown"
                  data-group="page-title-buttons"
                  data-delay="800"
                >
                  <a href="#" className="btn btn-primary me-2">
                    Try for Free
                  </a>
                  <a href="#" className="btn btn-light">
                    Subscribe
                  </a>
                </div> */}
              </div>
            </div>
            <div
              className="col-xxl-7 offset-xxl-1 col-lg-7 col-12 mt-5 mt-lg-0"
              data-cue="fadeIn"
              data-delay="1000"
            >
              <div
                className="position-relative z-1 pt-lg-9"
                data-cue="slideInRight"
              >
                <div className="position-relative">
                  <img
                    src="./assets/images/hero/wdot-youtube.jpg"
                    alt="video"
                    className="img-fluid rounded-3"
                    width="837"
                  />
                  <a
                    href="https://www.youtube.com/watch?v=ubx4ixcTYpg"
                    className="play-btn glightbox position-absolute top-50 start-50 translate-middle icon-shape icon-xl rounded-circle text-primary"
                    style={{ backgroundColor: "rgba(242, 41, 91, 0.2)" }}
                  >
                    <i className="bi bi-play-fill"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-xl-5 py-5 bg-light-subtle" data-cue="fadeIn">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
              <div className="text-center mb-6 mb-lg-9">
                <small className="text-primary text-uppercase ls-md fw-semibold">
                  features
                </small>
                <h2 className="my-3">Work Done? Wonderslab it Quickly</h2>
                <p className="mb-0 lead">
                  Achieve business innovation with Wonderslab's AI assistants.
                  Be prepared to introduce creative changes to your work.
                </p>
              </div>
            </div>
          </div>
          <div className="row g-lg-7 gy-5">
            <div className="col-lg-4 col-md-6">
              <div className="text-center text-md-start" data-cue="zoomIn">
                <div className="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                  <img
                    src="./assets/images/logo/wdot-logo.png"
                    alt=""
                    style={{ height: "30px" }}
                  />
                </div>

                <h4 className="notranslate">Wdot</h4>
                <p className="mb-0">
                  Wdot is an AI document assistant designed for professionals,
                  helping businesses swiftly and efficiently complete a variety
                  of specialized documents.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="text-center text-md-start" data-cue="zoomIn">
                <div className="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                  <img
                    src="./assets/images/logo/idot-logo.png"
                    alt=""
                    style={{ height: "30px" }}
                  />
                </div>

                <h4 className="notranslate">Idot</h4>
                <p className="mb-0">
                  Idot is an AI assistant that automatically generates
                  specialized prompts for image creation with just a keyword
                  input, making creative image production effortless.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="text-center text-md-start" data-cue="zoomIn">
                <div className="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                  <img
                    src="./assets/images/logo/sdot-logo.png"
                    alt=""
                    style={{ height: "30px" }}
                  />
                </div>

                <h4 className="notranslate">Sdot</h4>
                <p className="mb-0">
                  Sdot is an AI assistant that supports brand channel management
                  on Instagram, Facebook, LinkedIn, etc., by automatically
                  generating SNS feeds from keyword inputs and suggesting feed
                  topics, all with minimal effort.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="text-center text-md-start" data-cue="zoomIn">
                <div className="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                  <img
                    src="./assets/images/logo/tdot-logo.png"
                    alt=""
                    style={{ height: "30px" }}
                  />
                </div>

                <h4 className="notranslate">Tdot(coming soon)</h4>
                <p className="mb-0">
                  Tdot is an AI assistant that efficiently analyzes work tasks,
                  generates necessary materials, and manages task schedules,
                  maximizing work efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="text-center text-md-start" data-cue="zoomIn">
                <div className="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                  <img
                    src="./assets/images/logo/cdot-logo.png"
                    alt=""
                    style={{ height: "30px" }}
                  />
                </div>

                <h4 className="notranslate">Cdot(coming soon)</h4>
                <p className="mb-0">
                  Cdot is an AI assistant that identifies toxic clauses in
                  contracts and suggests precautions, aiding in secure contract
                  agreements.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="text-center text-md-start" data-cue="zoomIn">
                <div className="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                  <img
                    src="./assets/images/logo/edot-logo.png"
                    alt=""
                    style={{ height: "30px" }}
                  />
                </div>

                <h4 className="notranslate">Edot(coming soon)</h4>
                <p className="mb-0">
                  Edot is an AI assistant for business email composition,
                  helping you to easily craft professional and effective emails.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-xl-9 py-5 bg-pattern bg-primary">
        <div
          className="container "
          data-cue="fadeIn"
          data-show="true"
          style={{
            animationName: "fadeIn",
            animationDuration: "600ms",
            animationTimingFunction: "ease",
            animationDelay: "0ms",
            animationDirection: "normal",
            animationFillMode: "both",
          }}
        >
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-12">
              <div className="text-center mb-xl-7 mb-5">
                <h2 className="text-white-stable mb-3">
                  Why do over {$convertNumberFormat(totalUserCount)} users love?
                </h2>
              </div>
            </div>
          </div>
          <div className="row mb-7 pb-4 g-5 text-center text-lg-start">
            <div
              className="col-md-4"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: "fadeIn",
                animationDuration: "600ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both",
              }}
            >
              <h4 className="text-white-stable">
                {/* 빠른 문서 작업 완료 */}
                Fast document completion
              </h4>
              <p className="text-white-50 mb-0">
                {/* Wdot은 전문가들을 위한 AI 문서 어시스턴트로 다양한 특화 문서를 신속하고 효율적으로 완성할 수 있습니다. */}
                Wdot is an AI document assistant designed for professionals,
                helping businesses swiftly and efficiently complete a variety of
                specialized documents.
              </p>
            </div>
            <div
              className="col-md-4"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: "fadeIn",
                animationDuration: "600ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both",
              }}
            >
              <h4 className="text-white-stable">
                {/* SNS 피드 주제 고민은 이제 그만 */}
                No more worries about SNS feed topics
              </h4>
              <p className="text-white-50 mb-0">
                {/* Sdot은 키워드 입력만으로 SNS 피드를 자동 생성하고 피드 주제를 제안해주어 최소한의 노력으로 SNS 채널 관리를 지원하는 AI 어시스턴트입니다. */}
                Sdot is an AI assistant that supports brand channel management
                on Instagram, Facebook, LinkedIn, etc., by automatically
                generating SNS feeds from keyword inputs and suggesting feed
                topics, all with minimal effort.
              </p>
            </div>
            <div
              className="col-md-4"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: "fadeIn",
                animationDuration: "600ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both",
              }}
            >
              <h4 className="text-white-stable">
                {/* 더이상 이미지 찾지 말고, 바로 생성하자 */}
                No more searching for images, create them instantly
              </h4>
              <p className="text-white-50 mb-0">
                {/* Idot은 키워드 입력만으로 이미지 생성을 위한 특화 프롬프트를 자동 생성하여 창의적인 이미지 제작을 손쉽게 할 수 있습니다. */}
                Idot is an AI assistant that automatically generates specialized
                prompts for image creation with just a keyword input, making
                creative image production effortless.
              </p>
            </div>
          </div>
          <div
            className="row border-white border-top g-5 g-lg-0 text-center text-lg-start"
            data-cue="fadeIn"
            data-show="true"
            style={{
              animationName: "fadeIn",
              animationDuration: "600ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
          >
            <div
              className="col-lg-3 col-6 border-end-lg border-md-0 border-lg-white"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: "fadeIn",
                animationDuration: "600ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both",
              }}
            >
              <div className="p-lg-5">
                <h5 className="h1 text-white-stable mb-0">
                  {$convertNumberFormat(totalUserCount)}
                </h5>
                <span className="text-white-50">Users</span>
              </div>
            </div>
            <div
              className="col-lg-3 col-6 border-end-lg border-md-0 border-lg-white"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: "fadeIn",
                animationDuration: "600ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both",
              }}
            >
              <div className="p-lg-5">
                <h5 className="h1 text-white-stable mb-0">
                  {$convertNumberFormat(totalDocumentCount)}
                </h5>
                <span className="text-white-50">Documents</span>
              </div>
            </div>
            <div
              className="col-lg-3 col-6 border-end-lg border-md-0 border-lg-white"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: "fadeIn",
                animationDuration: "600ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both",
              }}
            >
              <div className="p-lg-5">
                <h5 className="h1 text-white-stable mb-0">
                  {$convertNumberFormat(totalPostCount)}
                </h5>
                <span className="text-white-50">Posts</span>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              data-cue="fadeIn"
              data-show="true"
              style={{
                animationName: "fadeIn",
                animationDuration: "600ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both",
              }}
            >
              <div className="p-lg-5">
                <h5 className="h1 text-white-stable mb-0">
                  {$convertNumberFormat(totalImageCount)}
                </h5>
                <span className="text-white-50">Images</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-xl-7 py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 col-12" data-cue="slideInLeft">
              <div data-cue="fadeIn">
                <small className="text-primary text-uppercase ls-md fw-semibold">
                  Maximize Work Efficiency
                </small>
                <div className="mb-5 mt-4">
                  <h2 className="mb-3">
                    Build the perfect workflow for every work.
                  </h2>
                  <p className="mb-0 lead">
                    AI Assistants boost your processing speed, enhancing your
                    competitive edge.
                  </p>
                </div>

                {/* <a href="#" className="icon-link icon-link-hover">
                  Learn More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </a> */}
              </div>
            </div>
            <div
              className="col-lg-6 offset-lg-1 col-md-6 col-12"
              data-cue="slideInRight"
            >
              <div
                className="position-relative rellax"
                data-rellax-percentage="1"
                data-rellax-speed="0.8"
                data-disable-parallax-down="md"
              >
                <figure>
                  <img
                    src="./assets/images/landings/landing01.jpg"
                    alt="landing"
                    className="img-fluid rounded-4"
                  />
                </figure>
                {/* <div className="flex-column position-absolute bottom-0 mx-lg-4 mb-4 d-flex align-items-center justify-content-between ms-2">
                  <div
                    className="w-100 d-flex align-items-center p-2 justify-content-between bg-white mb-2 rounded-4 ms-2 ms-lg-7 rellax"
                    data-rellax-percentage="0.4"
                    data-rellax-speed="0.4"
                    data-disable-parallax-down="md"
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="./assets/images/avatar/avatar-1.jpg"
                        alt="avatar"
                        className="avatar avatar-sm rounded-circle me-2"
                      />
                      <h6 className="mb-0 text-truncate">Jitu Doe</h6>
                    </div>
                    <div>
                      <div className="ms-lg-7 ps-lg-6">
                        <div className="bg-primary bg-opacity-25 fs-6 text-primary rounded-pill badge">
                          Researcher
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-100 d-flex align-items-center p-2 justify-content-between bg-white mb-2 rounded-4 rellax"
                    data-rellax-percentage="0.5"
                    data-rellax-speed="0.5"
                    data-disable-parallax-down="md"
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="./assets/images/avatar/avatar-2.jpg"
                        alt="avatar"
                        className="avatar avatar-sm rounded-circle me-2"
                      />
                      <h6 className="mb-0 text-truncate">Anita Par</h6>
                    </div>
                    <div>
                      <div className="ms-lg-7 ps-lg-6">
                        <div className="bg-info bg-opacity-25 text-info rounded-pill badge fs-6">
                          Analyst
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-100 d-flex align-items-center p-2 justify-content-between bg-white mb-2 rounded-4 rellax ms-4 ms-lg-7"
                    data-rellax-percentage="0.6"
                    data-rellax-speed="0.6"
                    data-disable-parallax-down="md"
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="./assets/images/avatar/avatar-4.jpg"
                        alt="avatar"
                        className="avatar avatar-sm rounded-circle me-2"
                      />
                      <h6 className="mb-0 text-truncate">Sandip</h6>
                    </div>
                    <div>
                      <div className="ms-lg-7 ps-lg-6">
                        <div className="bg-success bg-opacity-25 text-success rounded-pill badge fs-6">
                          Manager
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-xl-7 py-5">
        <div className="container pb-lg-5">
          <div className="row align-items-center">
            <div
              className="col-lg-6 col-md-6 col-12 order-lg-2 order-1"
              data-cue="slideInLeft"
            >
              <div
                className="position-relative rellax mb-7 mb-lg-0"
                data-rellax-percentage="1"
                data-rellax-speed="0.8"
                data-disable-parallax-down="md"
              >
                <figure>
                  <img
                    src="./assets/images/landings/landing02.jpg"
                    alt="landing"
                    className="img-fluid rounded-4"
                  />
                </figure>
              </div>
            </div>
            <div
              className="col-lg-5 offset-lg-1 col-md-6 col-12 order-lg-2"
              data-cue="slideInRight"
            >
              <div className="mt-md-5">
                <small className="text-primary text-uppercase ls-md fw-semibold">
                  Boost Creativity
                </small>
                <div className="mb-5 mt-4">
                  <h2 className="mb-3">
                    Channeling your team's creative energies into essential
                    projects.
                  </h2>
                  <p className="lead mb-0">
                    Delegate repetitive tasks to AI, focusing your team's
                    creativity on core activities.
                  </p>
                </div>
                <ul className="list-unstyled mb-5">
                  <li className="mb-2 d-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-circle-fill text-success mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <span className="ms-2">Stimulate Creative Thinking</span>
                  </li>
                  <li className="mb-2 d-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-circle-fill text-success mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <span className="ms-2">Enhance Focus</span>
                  </li>
                  <li className="mb-2 d-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-circle-fill text-success mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <span className="ms-2">Accelerate Innovative Projects</span>
                  </li>
                </ul>

                {/* <a href="#" className="icon-link icon-link-hover">
                  <span>Learn More</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="container py-lg-8 py-5 price-wrapper" data-cue="fadeIn">
        <div className="row justify-content-center mb-8">
          <div className="col-xl-6 col-lg-10 col-12">
            <div className="text-center d-flex flex-column gap-5">
              <div className="d-flex justify-content-center">
                <span className="bg-primary bg-opacity-10 text-primary border-primary border px-3 py-2 fs-6 rounded-pill lh-1 align-items-center d-flex">
                  <span className="ms-1 text-uppercase ls-md fw-semibold">
                    Pricing
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column gap-3 mx-lg-8">
                <h1 className="mb-0">Plan to unlock a new level of productivity</h1>
              </div>
              <div className="mb-5">
                <div className="price-switcher-wrapper switcher">
                  <p className="mb-0 pe-3">Monthly</p>
                  <div
                    className="price-switchers"
                    onClick={() =>
                      setPriceType(
                        priceType === "monthly" ? "yearly" : "monthly"
                      )
                    }
                  >
                    <div
                      className={
                        priceType === "monthly"
                          ? "price-switcher price-switcher-active"
                          : "price-switcher"
                      }
                    ></div>
                    <div
                      className={
                        priceType === "yearly"
                          ? "price-switcher price-switcher-active"
                          : "price-switcher"
                      }
                    ></div>
                    <div className="switcher-button bg-primary"></div>
                  </div>
                  <p className="mb-0 ps-3">Yearly</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-12">
            <div className="row align-items-center g-md-0 gy-4">
              <div className="col-md-6 col-12" data-cue="slideInLeft">
                <div className="card bg-light pricing rounded-end-md-0">
                  <div className="card-body d-flex flex-column gap-4">
                    <div>
                      <h3>Free</h3>
                      <p className="mb-0">
                        Participate in the survey and enjoy free access to the
                        service for 7 days.
                      </p>
                    </div>
                    <h2 className="mb-0 d-flex align-items-center">
                      <span>$0</span>
                    </h2>
                    <hr className="my-0" />
                    <div>
                      <h5 className="mb-3">What’s included</h5>
                      <ul className="list-unstyled flex-column d-flex gap-2">
                        <li className="d-flex align-items-start">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1710_19550)">
                                <path
                                  d="M8.5 15.6143C6.64348 15.6143 4.86301 14.8768 3.55025 13.564C2.2375 12.2513 1.5 10.4708 1.5 8.61426C1.5 6.75774 2.2375 4.97726 3.55025 3.66451C4.86301 2.35176 6.64348 1.61426 8.5 1.61426C10.3565 1.61426 12.137 2.35176 13.4497 3.66451C14.7625 4.97726 15.5 6.75774 15.5 8.61426C15.5 10.4708 14.7625 12.2513 13.4497 13.564C12.137 14.8768 10.3565 15.6143 8.5 15.6143ZM8.5 16.6143C10.6217 16.6143 12.6566 15.7714 14.1569 14.2711C15.6571 12.7708 16.5 10.736 16.5 8.61426C16.5 6.49253 15.6571 4.45769 14.1569 2.9574C12.6566 1.45711 10.6217 0.614258 8.5 0.614258C6.37827 0.614258 4.34344 1.45711 2.84315 2.9574C1.34285 4.45769 0.5 6.49253 0.5 8.61426C0.5 10.736 1.34285 12.7708 2.84315 14.2711C4.34344 15.7714 6.37827 16.6143 8.5 16.6143Z"
                                  fill="#64748B"
                                />
                                <path
                                  d="M11.4699 5.58405C11.4627 5.59095 11.456 5.5983 11.4499 5.60605L7.97685 10.0311L5.88385 7.93705C5.74168 7.80457 5.55363 7.73245 5.35933 7.73588C5.16503 7.7393 4.97964 7.81802 4.84223 7.95543C4.70482 8.09284 4.6261 8.27823 4.62268 8.47253C4.61925 8.66683 4.69137 8.85488 4.82385 8.99705L7.46985 11.6441C7.54113 11.7152 7.62601 11.7713 7.71943 11.8089C7.81285 11.8465 7.9129 11.865 8.0136 11.8631C8.11429 11.8612 8.21359 11.8391 8.30555 11.798C8.39751 11.757 8.48025 11.6978 8.54885 11.6241L12.5409 6.63405C12.6768 6.49138 12.7511 6.30095 12.7477 6.10392C12.7444 5.90689 12.6636 5.7191 12.5229 5.58114C12.3822 5.44319 12.1928 5.36616 11.9958 5.3667C11.7987 5.36725 11.6098 5.44532 11.4699 5.58405Z"
                                  fill="#64748B"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1710_19550">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5 0.614258)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span className="ms-2">Wdot - 3 documents</span>
                        </li>
                        <li className="d-flex align-items-start">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1710_19550)">
                                <path
                                  d="M8.5 15.6143C6.64348 15.6143 4.86301 14.8768 3.55025 13.564C2.2375 12.2513 1.5 10.4708 1.5 8.61426C1.5 6.75774 2.2375 4.97726 3.55025 3.66451C4.86301 2.35176 6.64348 1.61426 8.5 1.61426C10.3565 1.61426 12.137 2.35176 13.4497 3.66451C14.7625 4.97726 15.5 6.75774 15.5 8.61426C15.5 10.4708 14.7625 12.2513 13.4497 13.564C12.137 14.8768 10.3565 15.6143 8.5 15.6143ZM8.5 16.6143C10.6217 16.6143 12.6566 15.7714 14.1569 14.2711C15.6571 12.7708 16.5 10.736 16.5 8.61426C16.5 6.49253 15.6571 4.45769 14.1569 2.9574C12.6566 1.45711 10.6217 0.614258 8.5 0.614258C6.37827 0.614258 4.34344 1.45711 2.84315 2.9574C1.34285 4.45769 0.5 6.49253 0.5 8.61426C0.5 10.736 1.34285 12.7708 2.84315 14.2711C4.34344 15.7714 6.37827 16.6143 8.5 16.6143Z"
                                  fill="#64748B"
                                />
                                <path
                                  d="M11.4699 5.58405C11.4627 5.59095 11.456 5.5983 11.4499 5.60605L7.97685 10.0311L5.88385 7.93705C5.74168 7.80457 5.55363 7.73245 5.35933 7.73588C5.16503 7.7393 4.97964 7.81802 4.84223 7.95543C4.70482 8.09284 4.6261 8.27823 4.62268 8.47253C4.61925 8.66683 4.69137 8.85488 4.82385 8.99705L7.46985 11.6441C7.54113 11.7152 7.62601 11.7713 7.71943 11.8089C7.81285 11.8465 7.9129 11.865 8.0136 11.8631C8.11429 11.8612 8.21359 11.8391 8.30555 11.798C8.39751 11.757 8.48025 11.6978 8.54885 11.6241L12.5409 6.63405C12.6768 6.49138 12.7511 6.30095 12.7477 6.10392C12.7444 5.90689 12.6636 5.7191 12.5229 5.58114C12.3822 5.44319 12.1928 5.36616 11.9958 5.3667C11.7987 5.36725 11.6098 5.44532 11.4699 5.58405Z"
                                  fill="#64748B"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1710_19550">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5 0.614258)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span className="ms-2">Idot - 20 prompts</span>
                        </li>
                        <li className="d-flex align-items-start">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1710_19550)">
                                <path
                                  d="M8.5 15.6143C6.64348 15.6143 4.86301 14.8768 3.55025 13.564C2.2375 12.2513 1.5 10.4708 1.5 8.61426C1.5 6.75774 2.2375 4.97726 3.55025 3.66451C4.86301 2.35176 6.64348 1.61426 8.5 1.61426C10.3565 1.61426 12.137 2.35176 13.4497 3.66451C14.7625 4.97726 15.5 6.75774 15.5 8.61426C15.5 10.4708 14.7625 12.2513 13.4497 13.564C12.137 14.8768 10.3565 15.6143 8.5 15.6143ZM8.5 16.6143C10.6217 16.6143 12.6566 15.7714 14.1569 14.2711C15.6571 12.7708 16.5 10.736 16.5 8.61426C16.5 6.49253 15.6571 4.45769 14.1569 2.9574C12.6566 1.45711 10.6217 0.614258 8.5 0.614258C6.37827 0.614258 4.34344 1.45711 2.84315 2.9574C1.34285 4.45769 0.5 6.49253 0.5 8.61426C0.5 10.736 1.34285 12.7708 2.84315 14.2711C4.34344 15.7714 6.37827 16.6143 8.5 16.6143Z"
                                  fill="#64748B"
                                />
                                <path
                                  d="M11.4699 5.58405C11.4627 5.59095 11.456 5.5983 11.4499 5.60605L7.97685 10.0311L5.88385 7.93705C5.74168 7.80457 5.55363 7.73245 5.35933 7.73588C5.16503 7.7393 4.97964 7.81802 4.84223 7.95543C4.70482 8.09284 4.6261 8.27823 4.62268 8.47253C4.61925 8.66683 4.69137 8.85488 4.82385 8.99705L7.46985 11.6441C7.54113 11.7152 7.62601 11.7713 7.71943 11.8089C7.81285 11.8465 7.9129 11.865 8.0136 11.8631C8.11429 11.8612 8.21359 11.8391 8.30555 11.798C8.39751 11.757 8.48025 11.6978 8.54885 11.6241L12.5409 6.63405C12.6768 6.49138 12.7511 6.30095 12.7477 6.10392C12.7444 5.90689 12.6636 5.7191 12.5229 5.58114C12.3822 5.44319 12.1928 5.36616 11.9958 5.3667C11.7987 5.36725 11.6098 5.44532 11.4699 5.58405Z"
                                  fill="#64748B"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1710_19550">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5 0.614258)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span className="ms-2">Sdot - 30 posts</span>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12" data-cue="slideInRight">
                <div className="card bg-primary text-white pricing border-primary">
                  <div className="card-body d-flex flex-column gap-4 text-white-stable">
                    <div>
                      <h3 className="text-white-stable">Pro</h3>
                      <p className="mb-0 text-white-50">
                        Unlock a new level of your personal productivity.
                      </p>
                    </div>
                    <h2 className="d-flex align-items-center text-white-stable">
                      <div className="price-text">
                        <div
                          className={
                            priceType === "monthly"
                              ? "price price-show d-flex align-items-center"
                              : "price price-hide price-hidden d-flex align-items-center"
                          }
                        >
                          <span>$</span>
                          <span>79</span>
                          <span className="price-duration fs-6 ms-3">/month</span>
                        </div>
                        <div
                          className={
                            priceType === "yearly"
                              ? "price price-show d-flex align-items-center"
                              : "price price-hide price-hidden d-flex align-items-center"
                          }
                        >
                          <span>$</span>
                          <span>52</span>
                          <span className="price-duration fs-6 ms-3">/month</span>
                        </div>
                      </div>
                    </h2>
                    <div className="my-2">
                      <div className="border-top position-relative z-1 d-flex justify-content-center border-white">
                        <div className="lh-lg mt-n3 position-absolute top-0 z-2 bg-primary px-3">
                          {priceType === "monthly" ? "$948" : "$624"} billed
                          annually
                        </div>
                      </div>
                    </div>
                    <div className="text-white-stable">
                      <h5 className="mb-3 text-white-stable">What’s included</h5>
                      <ul className="list-unstyled flex-column d-flex gap-2">
                        <li className="d-flex align-items-start">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1710_19550)">
                                <path
                                  d="M8.5 15.6143C6.64348 15.6143 4.86301 14.8768 3.55025 13.564C2.2375 12.2513 1.5 10.4708 1.5 8.61426C1.5 6.75774 2.2375 4.97726 3.55025 3.66451C4.86301 2.35176 6.64348 1.61426 8.5 1.61426C10.3565 1.61426 12.137 2.35176 13.4497 3.66451C14.7625 4.97726 15.5 6.75774 15.5 8.61426C15.5 10.4708 14.7625 12.2513 13.4497 13.564C12.137 14.8768 10.3565 15.6143 8.5 15.6143ZM8.5 16.6143C10.6217 16.6143 12.6566 15.7714 14.1569 14.2711C15.6571 12.7708 16.5 10.736 16.5 8.61426C16.5 6.49253 15.6571 4.45769 14.1569 2.9574C12.6566 1.45711 10.6217 0.614258 8.5 0.614258C6.37827 0.614258 4.34344 1.45711 2.84315 2.9574C1.34285 4.45769 0.5 6.49253 0.5 8.61426C0.5 10.736 1.34285 12.7708 2.84315 14.2711C4.34344 15.7714 6.37827 16.6143 8.5 16.6143Z"
                                  fill="#ffffff"
                                />
                                <path
                                  d="M11.4699 5.58405C11.4627 5.59095 11.456 5.5983 11.4499 5.60605L7.97685 10.0311L5.88385 7.93705C5.74168 7.80457 5.55363 7.73245 5.35933 7.73588C5.16503 7.7393 4.97964 7.81802 4.84223 7.95543C4.70482 8.09284 4.6261 8.27823 4.62268 8.47253C4.61925 8.66683 4.69137 8.85488 4.82385 8.99705L7.46985 11.6441C7.54113 11.7152 7.62601 11.7713 7.71943 11.8089C7.81285 11.8465 7.9129 11.865 8.0136 11.8631C8.11429 11.8612 8.21359 11.8391 8.30555 11.798C8.39751 11.757 8.48025 11.6978 8.54885 11.6241L12.5409 6.63405C12.6768 6.49138 12.7511 6.30095 12.7477 6.10392C12.7444 5.90689 12.6636 5.7191 12.5229 5.58114C12.3822 5.44319 12.1928 5.36616 11.9958 5.3667C11.7987 5.36725 11.6098 5.44532 11.4699 5.58405Z"
                                  fill="#ffffff"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1710_19550">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5 0.614258)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span className="ms-2">
                            Wdot, Idot, Sdot all for free
                          </span>
                        </li>
                        <li className="d-flex align-items-start">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1710_19550)">
                                <path
                                  d="M8.5 15.6143C6.64348 15.6143 4.86301 14.8768 3.55025 13.564C2.2375 12.2513 1.5 10.4708 1.5 8.61426C1.5 6.75774 2.2375 4.97726 3.55025 3.66451C4.86301 2.35176 6.64348 1.61426 8.5 1.61426C10.3565 1.61426 12.137 2.35176 13.4497 3.66451C14.7625 4.97726 15.5 6.75774 15.5 8.61426C15.5 10.4708 14.7625 12.2513 13.4497 13.564C12.137 14.8768 10.3565 15.6143 8.5 15.6143ZM8.5 16.6143C10.6217 16.6143 12.6566 15.7714 14.1569 14.2711C15.6571 12.7708 16.5 10.736 16.5 8.61426C16.5 6.49253 15.6571 4.45769 14.1569 2.9574C12.6566 1.45711 10.6217 0.614258 8.5 0.614258C6.37827 0.614258 4.34344 1.45711 2.84315 2.9574C1.34285 4.45769 0.5 6.49253 0.5 8.61426C0.5 10.736 1.34285 12.7708 2.84315 14.2711C4.34344 15.7714 6.37827 16.6143 8.5 16.6143Z"
                                  fill="#ffffff"
                                />
                                <path
                                  d="M11.4699 5.58405C11.4627 5.59095 11.456 5.5983 11.4499 5.60605L7.97685 10.0311L5.88385 7.93705C5.74168 7.80457 5.55363 7.73245 5.35933 7.73588C5.16503 7.7393 4.97964 7.81802 4.84223 7.95543C4.70482 8.09284 4.6261 8.27823 4.62268 8.47253C4.61925 8.66683 4.69137 8.85488 4.82385 8.99705L7.46985 11.6441C7.54113 11.7152 7.62601 11.7713 7.71943 11.8089C7.81285 11.8465 7.9129 11.865 8.0136 11.8631C8.11429 11.8612 8.21359 11.8391 8.30555 11.798C8.39751 11.757 8.48025 11.6978 8.54885 11.6241L12.5409 6.63405C12.6768 6.49138 12.7511 6.30095 12.7477 6.10392C12.7444 5.90689 12.6636 5.7191 12.5229 5.58114C12.3822 5.44319 12.1928 5.36616 11.9958 5.3667C11.7987 5.36725 11.6098 5.44532 11.4699 5.58405Z"
                                  fill="#ffffff"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1710_19550">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5 0.614258)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span className="ms-2">
                            All upcoming AI assistants for free
                          </span>
                        </li>
                        <li className="d-flex align-items-start">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1710_19550)">
                                <path
                                  d="M8.5 15.6143C6.64348 15.6143 4.86301 14.8768 3.55025 13.564C2.2375 12.2513 1.5 10.4708 1.5 8.61426C1.5 6.75774 2.2375 4.97726 3.55025 3.66451C4.86301 2.35176 6.64348 1.61426 8.5 1.61426C10.3565 1.61426 12.137 2.35176 13.4497 3.66451C14.7625 4.97726 15.5 6.75774 15.5 8.61426C15.5 10.4708 14.7625 12.2513 13.4497 13.564C12.137 14.8768 10.3565 15.6143 8.5 15.6143ZM8.5 16.6143C10.6217 16.6143 12.6566 15.7714 14.1569 14.2711C15.6571 12.7708 16.5 10.736 16.5 8.61426C16.5 6.49253 15.6571 4.45769 14.1569 2.9574C12.6566 1.45711 10.6217 0.614258 8.5 0.614258C6.37827 0.614258 4.34344 1.45711 2.84315 2.9574C1.34285 4.45769 0.5 6.49253 0.5 8.61426C0.5 10.736 1.34285 12.7708 2.84315 14.2711C4.34344 15.7714 6.37827 16.6143 8.5 16.6143Z"
                                  fill="#ffffff"
                                />
                                <path
                                  d="M11.4699 5.58405C11.4627 5.59095 11.456 5.5983 11.4499 5.60605L7.97685 10.0311L5.88385 7.93705C5.74168 7.80457 5.55363 7.73245 5.35933 7.73588C5.16503 7.7393 4.97964 7.81802 4.84223 7.95543C4.70482 8.09284 4.6261 8.27823 4.62268 8.47253C4.61925 8.66683 4.69137 8.85488 4.82385 8.99705L7.46985 11.6441C7.54113 11.7152 7.62601 11.7713 7.71943 11.8089C7.81285 11.8465 7.9129 11.865 8.0136 11.8631C8.11429 11.8612 8.21359 11.8391 8.30555 11.798C8.39751 11.757 8.48025 11.6978 8.54885 11.6241L12.5409 6.63405C12.6768 6.49138 12.7511 6.30095 12.7477 6.10392C12.7444 5.90689 12.6636 5.7191 12.5229 5.58114C12.3822 5.44319 12.1928 5.36616 11.9958 5.3667C11.7987 5.36725 11.6098 5.44532 11.4699 5.58405Z"
                                  fill="#ffffff"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1710_19550">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5 0.614258)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span className="ms-2">
                            50% discount when attending Partner Day
                          </span>
                        </li>
                        <li className="d-flex align-items-start">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1710_19550)">
                                <path
                                  d="M8.5 15.6143C6.64348 15.6143 4.86301 14.8768 3.55025 13.564C2.2375 12.2513 1.5 10.4708 1.5 8.61426C1.5 6.75774 2.2375 4.97726 3.55025 3.66451C4.86301 2.35176 6.64348 1.61426 8.5 1.61426C10.3565 1.61426 12.137 2.35176 13.4497 3.66451C14.7625 4.97726 15.5 6.75774 15.5 8.61426C15.5 10.4708 14.7625 12.2513 13.4497 13.564C12.137 14.8768 10.3565 15.6143 8.5 15.6143ZM8.5 16.6143C10.6217 16.6143 12.6566 15.7714 14.1569 14.2711C15.6571 12.7708 16.5 10.736 16.5 8.61426C16.5 6.49253 15.6571 4.45769 14.1569 2.9574C12.6566 1.45711 10.6217 0.614258 8.5 0.614258C6.37827 0.614258 4.34344 1.45711 2.84315 2.9574C1.34285 4.45769 0.5 6.49253 0.5 8.61426C0.5 10.736 1.34285 12.7708 2.84315 14.2711C4.34344 15.7714 6.37827 16.6143 8.5 16.6143Z"
                                  fill="#ffffff"
                                />
                                <path
                                  d="M11.4699 5.58405C11.4627 5.59095 11.456 5.5983 11.4499 5.60605L7.97685 10.0311L5.88385 7.93705C5.74168 7.80457 5.55363 7.73245 5.35933 7.73588C5.16503 7.7393 4.97964 7.81802 4.84223 7.95543C4.70482 8.09284 4.6261 8.27823 4.62268 8.47253C4.61925 8.66683 4.69137 8.85488 4.82385 8.99705L7.46985 11.6441C7.54113 11.7152 7.62601 11.7713 7.71943 11.8089C7.81285 11.8465 7.9129 11.865 8.0136 11.8631C8.11429 11.8612 8.21359 11.8391 8.30555 11.798C8.39751 11.757 8.48025 11.6978 8.54885 11.6241L12.5409 6.63405C12.6768 6.49138 12.7511 6.30095 12.7477 6.10392C12.7444 5.90689 12.6636 5.7191 12.5229 5.58114C12.3822 5.44319 12.1928 5.36616 11.9958 5.3667C11.7987 5.36725 11.6098 5.44532 11.4699 5.58405Z"
                                  fill="#ffffff"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1710_19550">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5 0.614258)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span className="ms-2">
                            Free training for 2 hours with sign-ups of more than
                            10 people
                          </span>
                        </li>
                        <li className="d-flex align-items-start">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1710_19550)">
                                <path
                                  d="M8.5 15.6143C6.64348 15.6143 4.86301 14.8768 3.55025 13.564C2.2375 12.2513 1.5 10.4708 1.5 8.61426C1.5 6.75774 2.2375 4.97726 3.55025 3.66451C4.86301 2.35176 6.64348 1.61426 8.5 1.61426C10.3565 1.61426 12.137 2.35176 13.4497 3.66451C14.7625 4.97726 15.5 6.75774 15.5 8.61426C15.5 10.4708 14.7625 12.2513 13.4497 13.564C12.137 14.8768 10.3565 15.6143 8.5 15.6143ZM8.5 16.6143C10.6217 16.6143 12.6566 15.7714 14.1569 14.2711C15.6571 12.7708 16.5 10.736 16.5 8.61426C16.5 6.49253 15.6571 4.45769 14.1569 2.9574C12.6566 1.45711 10.6217 0.614258 8.5 0.614258C6.37827 0.614258 4.34344 1.45711 2.84315 2.9574C1.34285 4.45769 0.5 6.49253 0.5 8.61426C0.5 10.736 1.34285 12.7708 2.84315 14.2711C4.34344 15.7714 6.37827 16.6143 8.5 16.6143Z"
                                  fill="#ffffff"
                                />
                                <path
                                  d="M11.4699 5.58405C11.4627 5.59095 11.456 5.5983 11.4499 5.60605L7.97685 10.0311L5.88385 7.93705C5.74168 7.80457 5.55363 7.73245 5.35933 7.73588C5.16503 7.7393 4.97964 7.81802 4.84223 7.95543C4.70482 8.09284 4.6261 8.27823 4.62268 8.47253C4.61925 8.66683 4.69137 8.85488 4.82385 8.99705L7.46985 11.6441C7.54113 11.7152 7.62601 11.7713 7.71943 11.8089C7.81285 11.8465 7.9129 11.865 8.0136 11.8631C8.11429 11.8612 8.21359 11.8391 8.30555 11.798C8.39751 11.757 8.48025 11.6978 8.54885 11.6241L12.5409 6.63405C12.6768 6.49138 12.7511 6.30095 12.7477 6.10392C12.7444 5.90689 12.6636 5.7191 12.5229 5.58114C12.3822 5.44319 12.1928 5.36616 11.9958 5.3667C11.7987 5.36725 11.6098 5.44532 11.4699 5.58405Z"
                                  fill="#ffffff"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1710_19550">
                                  <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5 0.614258)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span className="ms-2">
                            Qualify to register as a sales partner
                          </span>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="py-5 py-lg-7 bg-light-subtle" data-cue="fadeIn">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mb-7">
                <small className="text-primary text-uppercase ls-md fw-semibold">
                  Testimonial
                </small>
                <h2 className="mt-3 mb-0">Our happy customer love us</h2>
              </div>
            </div>
          </div>
          <div className="row gy-4 gy-md-6">
            <div
              className="col-lg-4 col-md-6 col-12 flex-column d-flex gap-4 gap-md-6 mt-lg-8"
              data-cue="slideInLeft"
            >
              <div className="card bg-light card-lift">
                <div className="card-body text-body">
                  <p className="mb-0">
                    “Thanks to Wdot, drafting our business plan became much
                    easier. It saved us a lot of time and effort!”
                  </p>
                  {/* <div className="mt-4 d-flex align-items-center">
                    <img
                      src="./assets/images/avatar/avatar-1.jpg"
                      alt=""
                      className="avatar avatar-lg rounded-circle border p-1 bg-white"
                    />
                    <div className="ms-3 lh-1">
                      <h5 className="mb-0">Daniel Leon</h5>
                      <small>Time Management Consultant</small>
                    </div>
                  </div> */}
                </div>
                {/* <a href="#" className="stretched-link"></a> */}
              </div>
              <div className="card bg-light card-lift">
                <div className="card-body text-body">
                  <p className="mb-0">
                    “Creating amazing product images with Idot has simplified
                    our marketing. It's been a big help in boosting our sales!”
                  </p>
                  {/* <div className="mt-4 d-flex align-items-center">
                    <img
                      src="./assets/images/avatar/avatar-2.jpg"
                      alt=""
                      className="avatar avatar-lg rounded-circle border p-1 bg-white"
                    />
                    <div className="ms-3 lh-1">
                      <h5 className="mb-0">Hector Pratt</h5>
                      <small>Fitness Trainer</small>
                    </div>
                  </div> */}
                </div>
                {/* <a href="#" className="stretched-link"></a> */}
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-12 flex-column d-flex gap-4 gap-md-6"
              data-cue="zoomIn"
            >
              <div className="card bg-light card-lift">
                <div className="card-body text-body">
                  <p className="mb-0">
                    “Managing social media has never been easier with Sdot. It
                    saves a lot of time as I don't have to brainstorm content
                    daily.”
                  </p>
                  {/* <div className="mt-4 d-flex align-items-center">
                    <img
                      src="./assets/images/avatar/avatar-4.jpg"
                      alt=""
                      className="avatar avatar-lg rounded-circle border p-1 bg-white"
                    />
                    <div className="ms-3 lh-1">
                      <h5 className="mb-0">Adrian Lopez</h5>
                      <small>Travel Advisor</small>
                    </div>
                  </div> */}
                </div>
                {/* <a href="#" className="stretched-link"></a> */}
              </div>
              <div className="card bg-light card-lift">
                <div className="card-body text-body">
                  <p className="mb-0">
                    “Ever since using Wdot, complex market analysis reports are
                    much easier to write. It significantly reduced the time
                    spent on data analysis and report drafting.”
                  </p>
                  {/* <div className="mt-4 d-flex align-items-center">
                    <img
                      src="./assets/images/avatar/avatar-3.jpg"
                      alt=""
                      className="avatar avatar-lg rounded-circle border p-1 bg-white"
                    />
                    <div className="ms-3 lh-1">
                      <h5 className="mb-0">Erica Nixon</h5>
                      <small>Event Planner</small>
                    </div>
                  </div> */}
                </div>
                {/* <a href="#" className="stretched-link"></a> */}
              </div>
            </div>
            <div
              className="col-lg-4 col-md-12 col-12 flex-column flex-md-row flex-lg-column d-flex gap-4 gap-md-6 mt-lg-8"
              data-cue="slideInRight"
            >
              <div className="card bg-light card-lift">
                <div className="card-body text-body">
                  <p className="mb-0">
                    “My artwork's visual expression has greatly improved thanks
                    to Idot. I can easily create illustrations in various
                    styles.”
                  </p>
                  {/* <div className="mt-4 d-flex align-items-center">
                    <img
                      src="./assets/images/avatar/avatar-6.jpg"
                      alt=""
                      className="avatar avatar-lg rounded-circle border p-1 bg-white"
                    />
                    <div className="ms-3 lh-1">
                      <h5 className="mb-0">Virginia Santiago</h5>
                      <small>Career Counselor</small>
                    </div>
                  </div> */}
                </div>
                {/* <a href="#" className="stretched-link"></a> */}
              </div>
              <div className="card bg-light card-lift">
                <div className="card-body text-body">
                  <p className="mb-0">
                    “Since using Sdot, our brand's social media activities have
                    become more vibrant and professional. We've seen a
                    noticeable increase in followers!”
                  </p>
                  {/* <div className="mt-4 d-flex align-items-center">
                    <img
                      src="./assets/images/avatar/avatar-5.jpg"
                      alt=""
                      className="avatar avatar-lg rounded-circle border p-1 bg-white"
                    />
                    <div className="ms-3 lh-1">
                      <h5 className="mb-0">Amin Matthams</h5>
                      <small>Cybersecurity Expert</small>
                    </div>
                  </div> */}
                </div>
                {/* <a href="#" className="stretched-link"></a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container" data-cues="zoomIn">
          <div className="row bg-pattern bg-primary-gradient rounded-3 p-7 g-0">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 col-12">
              <div className="position-relative z-1 my-lg-5">
                <div className="mb-5 text-center">
                  <h3 className="h2 text-white-stable mb-1">
                    Accelerate Work Completion
                  </h3>
                  <p className="mb-0 text-white-stable">
                    Wonderslab helps you to complete your work faster and more
                  </p>
                </div>
                <div className="row g-2 needs-validation d-flex mx-lg-7">
                  {/* <div className="col-md-7 col-xl-7 col-12">
                    <label
                      htmlFor="notificationEmail"
                      className="visually-hidden"
                    ></label>
                    <input
                      type="email"
                      id="notificationEmail"
                      className="form-control"
                      placeholder="Enter your business email"
                      aria-label="Enter your business email"
                      required
                    />
                    <div className="invalid-feedback text-start">
                      Please choose a email.
                    </div>
                  </div> */}
                  {/* <div className="col-md-5 col-xl-5 col-12"> */}
                  <div className="col-12">
                    <div className="d-grid">
                      <button
                        className="btn btn-dark"
                        type="button"
                        onClick={() => navigate("/contact")}
                      >
                        Contact Us
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
