import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import AccountMenu from "../component/AccountMenu";

function AccountTeam() {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AccountMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <h1 className="mb-0 h3">Teams</h1>
              </div>
              <div className="card border-0 shadow-sm mb-4">
                <div className="card-body p-lg-5">
                  <div className="mb-5">
                    <h4 className="mb-1">Add team members</h4>
                    <p className="fs-6 mb-0">
                      Invite as many team members as you need to help run this
                      account. Learn More
                    </p>
                  </div>
                  <form className="row g-3 needs-validation" noValidate>
                    <div className="col-lg-6 col-md-12">
                      <label
                        htmlFor="formGroupEmailInput"
                        className="form-label"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="formGroupEmailInput"
                        placeholder="Team member’s email"
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter an email.
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <label
                        htmlFor="formGroupRoleInput"
                        className="form-label"
                      >
                        Role
                      </label>
                      <select
                        className="form-select"
                        id="formGroupRoleInput"
                        required
                      >
                        <option selected disabled value="">
                          Role
                        </option>
                        <option value="Owner">Owner</option>
                        <option value="Front End Developer">
                          Front End Developer
                        </option>
                        <option value="Full Stack Developer">
                          Full Stack Developer
                        </option>
                      </select>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary" type="submit">
                        Send Invitation
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card border-0 mb-4 shadow-sm">
                <div className="card-body p-lg-5">
                  <div className="mb-5">
                    <h4 className="mb-1">Team members</h4>
                    <p className="mb-0 fs-6">
                      List of member are in your team with its roles.
                    </p>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-centered td table-centered th table-lg text-nowrap">
                      <thead>
                        <tr>
                          <th scope="col">
                            <div className="fs-6 text-dark fw-semibold">
                              Member
                            </div>
                          </th>
                          <th scope="col">
                            <div className="fs-6 text-dark fw-semibold">
                              Role
                            </div>
                          </th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src="./assets/images/avatar/avatar-1.jpg"
                                alt="avatar"
                                className="avatar avatar-lg rounded-circle"
                              />
                              <div className="ms-3">
                                <div className="fs-5 fw-semibold text-dark">
                                  Jitu Chauhan
                                </div>
                                <small>anitaexample@block.com</small>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span>Owner</span>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="d-flex align-items-center">
                              <img
                                src="./assets/images/avatar/avatar-2.jpg"
                                alt="avatar"
                                className="avatar avatar-lg rounded-circle"
                              />
                              <div className="ms-3">
                                <div className="fs-5 fw-semibold text-dark">
                                  Anita parmar
                                </div>
                                <small>anitaexample@block.com</small>
                              </div>
                            </div>
                          </th>
                          <td>
                            <span>Front End Developer</span>
                          </td>
                          <td></td>
                          <td>
                            <a href="#" className="btn btn-sm btn-dark me-2">
                              Edit
                            </a>
                            <a href="#" className="btn btn-sm btn-light">
                              Remove
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="d-flex align-items-center">
                              <img
                                src="./assets/images/avatar/avatar-9.jpg"
                                alt="avatar"
                                className="avatar avatar-lg rounded-circle"
                              />
                              <div className="ms-3">
                                <div className="fs-5 fw-semibold text-dark">
                                  Sandip Chauhan
                                </div>
                                <small>sandipexample@block.com</small>
                              </div>
                            </div>
                          </th>
                          <td>
                            <span>Full Stack Developer</span>
                          </td>
                          <td></td>
                          <td>
                            <a href="#" className="btn btn-sm btn-dark me-2">
                              Edit
                            </a>
                            <a href="#" className="btn btn-sm btn-light">
                              Remove
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AccountTeam;
