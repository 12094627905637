import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { $post, $get, $convertMarkdownToHtml } from "../utils/common";
import { useTranslation } from "react-i18next";

function EventList() {
  const userLocalLang = navigator.language || navigator.userLanguage;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <main>
      <div className="pattern-square"></div>
      <section className="py-5 py-lg-8">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-12">
              <div className="text-center">
                <h1 className="mb-3">
                  The Dawn of Next Technology: Embarking Together
                </h1>
                <p className="mb-0">
                  Meet with our technology or business teams to discuss building
                  AI assistants and to participate in a Generative AI training
                  workshop.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-xl-9 my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="card shadow-sm overflow-hidden">
                <div className="row g-0">
                  <div className="col-xl-6 col-md-6">
                    <div className="card-body h-100 d-flex align-items-start flex-column p-lg-7">
                      <div className="mb-3">
                        <small className="text-uppercase fw-semibold ls-md">
                          Offline
                        </small>
                        <h2 className="mb-0 mt-3">
                          <a
                            onClick={() => navigate("/event/1")}
                            className="text-reset"
                          >
                            Generative AI and Prompt Engineering in the Real
                            Estate Industry
                          </a>
                        </h2>
                      </div>
                      <div className="mb-1">
                        <small className="me-2">
                          Mar 18, 2024 ~ Mar 22, 2024
                        </small>
                        {/* <small>4:00PM (Local time)</small> */}
                      </div>
                      <div className="mb-5">
                        <small className="me-2">
                          13-15A Nguyen Thi Huynh, Ward 8, Phu Nhuan District,
                          Ho Chi Minh City, Vietnam
                        </small>
                      </div>
                      <div className="mt-auto">
                        <a
                          onClick={() => navigate("/event/1")}
                          className="icon-link icon-link-hover card-link"
                        >
                          Details
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    style={{
                      backgroundImage:
                        "url(./assets/images/event/event-nibc.jpg)",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      minHeight: "15rem",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="mb-xl-9 my-5">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-12">
              <div className="mb-4">
                <h3 className="mb-4">All Events</h3>
              </div>
            </div>
            <div className="col-lg-6 col-md-10 col-12">
              <div className="row g-3 align-items-center">
                <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="eventList" className="form-label visually-hidden">
                    Search Category
                  </label>
                  <select className="form-select" id="eventList">
                    <option selected disabled value="">
                      Type of event
                    </option>
                    <option value="Conferences">Conferences</option>
                    <option value="Online">Online</option>
                    <option value="Livestream">Livestream</option>
                    <option value="Video">Video</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-5">
            <div className="col-md-6">
              <div className="card shadow-sm h-100 border-0 card-lift overflow-hidden">
                <div className="row h-100 g-0">
                  <a
                    href="./event-single.html"
                    className="col-lg-5 col-md-12"
                    // style="
                    //              background-image: url(./assets/images/event/event-img-2.jpg);
                    //              background-size: cover;
                    //              background-repeat: no-repeat;
                    //              background-position: center;
                    //              min-height: 13rem;
                    //           "
                  ></a>
                  <div className="col-lg-7 col-md-12">
                    <div className="card-body h-100 d-flex align-items-start flex-column border rounded-end-lg-3 rounded-bottom-3 rounded-top-0 rounded-start-lg-0 border-start-lg-0 border-top-0 border-top-lg">
                      <div className="mb-5">
                        <small className="text-uppercase fw-semibold ls-md">
                          Conference
                        </small>
                        <h4 className="my-2">
                          <a href="./event-single.html" className="text-reset">
                            How to build a blog with Astro and Contentful
                          </a>
                        </h4>
                        <small>July 2, 2024</small>
                      </div>
                      <div className="mt-auto">
                        <small className="me-2">9:00AM EDT</small>
                        <small>Germany</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card shadow-sm h-100 border-0 card-lift overflow-hidden">
                <div className="row g-0 h-100">
                  <a
                    href="./event-single.html"
                    className="col-lg-5 col-md-12"
                    // style="
                    //              background-image: url(./assets/images/event/event-img-3.jpg);
                    //              background-size: cover;
                    //              background-repeat: no-repeat;
                    //              background-position: center;
                    //              min-height: 13rem;
                    //           "
                  ></a>
                  <div className="col-lg-7 col-md-12">
                    <div className="card-body h-100 d-flex align-items-start flex-column border rounded-end-lg-3 rounded-bottom-3 rounded-top-0 rounded-start-lg-0 border-start-lg-0 border-top-0 border-top-lg">
                      <div className="mb-5">
                        <small className="text-uppercase fw-semibold ls-md">
                          Conference
                        </small>
                        <h4 className="my-2">
                          <a href="event-single.html" className="text-reset">
                            A look at building with Astro template
                          </a>
                        </h4>
                        <small>June 28, 2024</small>
                      </div>
                      <div className="mt-auto">
                        <small className="me-2">9:00AM EDT</small>
                        <small>India</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card shadow-sm h-100 border-0 card-lift overflow-hidden">
                <div className="row h-100 g-0">
                  <a
                    href="./event-single.html"
                    className="col-lg-5 col-md-12"
                    // style="
                    //              background-image: url(./assets/images/event/event-img-4.jpg);
                    //              background-size: cover;
                    //              background-repeat: no-repeat;
                    //              background-position: center;
                    //              min-height: 13rem;
                    //           "
                  ></a>
                  <div className="col-lg-7 col-md-12">
                    <div className="card-body h-100 d-flex align-items-start flex-column border rounded-end-lg-3 rounded-bottom-3 rounded-top-0 rounded-start-lg-0 border-start-lg-0 border-top-0 border-top-lg">
                      <div className="mb-5">
                        <small className="text-uppercase fw-semibold ls-md">
                          Online
                        </small>
                        <h4 className="my-2">
                          <a href="event-single.html" className="text-reset">
                            Make a blog with Next.js, React, and Sanity
                          </a>
                        </h4>
                        <small>June 26, 2024</small>
                      </div>
                      <div className="mt-auto">
                        <small className="me-2">9:00AM EDT</small>
                        <small>Dubai</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card shadow-sm h-100 border-0 card-lift overflow-hidden">
                <div className="row g-0 h-100">
                  <a
                    href="./event-single.html"
                    className="col-lg-5 col-md-12"
                    // style="
                    //              background-image: url(./assets/images/event/event-img-5.jpg);
                    //              background-size: cover;
                    //              background-repeat: no-repeat;
                    //              background-position: center;
                    //              min-height: 13rem;
                    //           "
                  ></a>
                  <div className="col-lg-7 col-md-12">
                    <div className="card-body h-100 d-flex align-items-start flex-column border rounded-end-lg-3 rounded-bottom-3 rounded-top-0 rounded-start-lg-0 border-start-lg-0 border-top-0 border-top-lg">
                      <div className="mb-5">
                        <small className="text-uppercase fw-semibold ls-md">
                          Livestream
                        </small>
                        <h4 className="my-2">
                          <a href="./event-single.html" className="text-reset">
                            Using Contentful CMS with Next.js
                          </a>
                        </h4>
                        <small>June 22, 2024</small>
                      </div>
                      <div className="mt-auto">
                        <small className="me-2">9:00AM EDT</small>
                        <small>London</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-xl-9 my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="mb-4">Webinar Video on-demand</h3>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <div className="card border-0 shadow-sm h-100 card-lift">
                <figure>
                  <a href="./event-single.html">
                    <img
                      src="./assets/images/event/event-img-2.jpg"
                      alt="event"
                      className="card-img-top"
                    />
                  </a>
                </figure>

                <div className="card-body h-100 d-flex align-items-start flex-column border rounded-bottom-3 border-top-0">
                  <div className="mb-5">
                    <small className="text-uppercase fw-semibold ls-md">
                      Webinar
                    </small>
                    <h4 className="my-2">
                      <a href="./event-single.html" className="text-reset">
                        How to build a blog with Astro and Contentful
                      </a>
                    </h4>
                    <small>June 22, 2024</small>
                  </div>
                  <div className="d-flex justify-content-between w-100 mt-auto">
                    <small>9:00AM EDT</small>
                    <small>Germany</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card border-0 shadow-sm h-100 card-lift">
                <figure>
                  <a href="./event-single.html">
                    <img
                      src="./assets/images/event/event-img-5.jpg"
                      alt="event"
                      className="card-img-top"
                    />
                  </a>
                </figure>

                <div className="card-body h-100 d-flex align-items-start flex-column border rounded-bottom-3 border-top-0">
                  <div className="mb-5">
                    <small className="text-uppercase fw-semibold ls-md">
                      Webinar
                    </small>
                    <h4 className="my-2">
                      <a href="./event-single.html" className="text-reset">
                        Astro components & the basic building blocks
                      </a>
                    </h4>
                    <small>June 22, 2024</small>
                  </div>
                  <div className="d-flex justify-content-between w-100 mt-auto">
                    <small>9:00AM EDT</small>
                    <small>Germany</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card border-0 shadow-sm h-100 card-lift">
                <figure>
                  <a href="./event-single.html">
                    <img
                      src="./assets/images/event/event-img-4.jpg"
                      alt="event"
                      className="card-img-top"
                    />
                  </a>
                </figure>

                <div className="card-body h-100 d-flex align-items-start flex-column border rounded-bottom-3 border-top-0">
                  <div className="mb-5">
                    <small className="text-uppercase fw-semibold ls-md">
                      Webinar
                    </small>
                    <h4 className="my-2">
                      <a href="./event-single.html" className="text-reset">
                        Using Contentful CMS with Next.js
                      </a>
                    </h4>
                    <small>June 22, 2024</small>
                  </div>
                  <div className="d-flex justify-content-between w-100 mt-auto">
                    <small>9:00AM EDT</small>
                    <small>Germany</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
}

export default EventList;
