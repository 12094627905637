import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  $get,
  $post,
  $put,
  $convertNumberFormat,
  $dateTimezone,
} from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import Chart from "react-apexcharts";
import AdminMenu from "../component/AdminMenu";
import Swal from "sweetalert2";

function SubscriberList() {
  const appUrl = process.env.REACT_APP_URL;
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const { isLoggedIn, userData, login, logout, checkLogin } = useAuth();

  const [selectedSubscription, setSelectedSubscription] = useState({});
  const nextPaymentModalRef = useRef();
  const modalCloseRef = useRef();
  const refundModalRef = useRef();

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    const res = await $get(`/api/subscription/${pageNo}/${pageLimit}`);
    if (res.status === 200) {
      setSubscriptionList(res.data.subscriptionList);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();

      // modal reset
      setSelectedSubscription({});
    }
  };

  const changeNextPayment = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the next payment date?",
      iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/subscription/next_payment_datetime/${selectedSubscription.id}`,
          {
            next_payment_datetime: selectedSubscription.next_payment_datetime,
          }
        );
        if (res.status === 200) {
          modalCloseRef.current.click();
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "Success",
            text: "Next payment date has been updated.",
            showConfirmButton: false,
            timer: 1500,
          });
          changePagination();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to update next payment date.",
          });
        }
      }
    });
  };

  const refundPayment = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to refund the payment?",
      iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(
          `/api/subscription/refund/${selectedSubscription.id}`,
          {
            active_yn: selectedSubscription.active_yn,
            active_end_datetime: selectedSubscription.active_end_datetime,
          }
        );
        if (res.status === 200) {
          modalCloseRef.current.click();
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "Success",
            text: "Payment has been refunded.",
            showConfirmButton: false,
            timer: 1500,
          });
          changePagination();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to refund payment.",
          });
        }
      }
    });
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AdminMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Subscriber List</h3>
                    <div className="table-responsive">
                      <table className="table table-hover align-middle">
                        <thead>
                          <tr className="table-dark">
                            <th>Email</th>
                            <th>Plan</th>
                            <th>Curr.</th>
                            <th>Amount</th>
                            <th>Last Payment</th>
                            <th>Next Payment</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {subscriptionList.map((item, idx) => (
                            <tr key={idx}>
                              <td>{item.email}</td>
                              <td>
                                {item.comments.replace(
                                  "WondersLab AI Assistant -",
                                  ""
                                )}
                              </td>
                              <td>{item.currency}</td>
                              <td>
                                {item.currency === "USD"
                                  ? $convertNumberFormat(
                                      item.billing_amount,
                                      "#,###.##"
                                    )
                                  : $convertNumberFormat(item.billing_amount)}
                              </td>
                              <td>
                                {$dateTimezone(item.last_payment_datetime)}
                              </td>
                              <td>
                                {item.active_yn === "Y"
                                  ? $dateTimezone(item.next_payment_datetime)
                                  : "Canceled Plan"}
                              </td>
                              <td>
                                {item.active_yn === "Y" && (
                                  <button
                                    className="btn btn-sm btn-primary me-1"
                                    onClick={() => {
                                      setSelectedSubscription(item);
                                      nextPaymentModalRef.current.click();
                                    }}
                                    title="Edit Next Payment"
                                  >
                                    <i className="fa-solid fa-edit"></i>
                                  </button>
                                )}
                                {userData &&
                                  userData.email === "seungwon.go@gmail.com" &&
                                  item.active_yn === "N" && (
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        setSelectedSubscription(item);
                                        refundModalRef.current.click();
                                      }}
                                      title="Refund Payment"
                                    >
                                      <i className="fa-solid fa-user-xmark"></i>
                                    </button>
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {totalPage > 1 && (
                        <div className="d-flex justify-content-center">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              {pageNo > 5 && (
                                <li className="page-item">
                                  <a
                                    className="page-link"
                                    onClick={(e) => setPageNo(pageNo - 1)}
                                  >
                                    <i className="fa-solid fa-arrow-left"></i>
                                  </a>
                                </li>
                              )}

                              {pageRange.map((page) => (
                                <li key={page}>
                                  <a
                                    className={
                                      pageNo === page
                                        ? "page-link active"
                                        : "page-link"
                                    }
                                    onClick={(e) => setPageNo(page)}
                                  >
                                    {page}
                                  </a>
                                </li>
                              ))}

                              {lastPage < totalPage && (
                                <li className="page-item">
                                  <a
                                    className="page-link"
                                    onClick={(e) => setPageNo(pageNo + 1)}
                                  >
                                    <i className="fa-solid fa-arrow-right"></i>
                                  </a>
                                </li>
                              )}
                            </ul>
                          </nav>
                        </div>
                      )}
                    </div>
                    {/* modal edit next payment */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <button
        ref={nextPaymentModalRef}
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#modalEditNextPayment"
      ></button>
      <button
        ref={refundModalRef}
        style={{ display: "none" }}
        data-bs-toggle="modal"
        data-bs-target="#modalRefund"
      ></button>
      {pageNo > 0 && (
        <div
          className="modal fade"
          id="modalEditNextPayment"
          tabIndex="-1"
          aria-labelledby="modalEditNextPaymentLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalEditNextPaymentLabel">
                  Edit Next Payment
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {selectedSubscription.email && (
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedSubscription.email}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="assistantCode">Plan</label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedSubscription.comments}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="nextPayment">Next Payment</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={
                        // 2024-05-04 14:56:42 to 2024-05-04T14:56:42
                        $dateTimezone(
                          selectedSubscription.next_payment_datetime
                        ).replace(" ", "T")
                      }
                      onChange={(e) => {
                        setSelectedSubscription({
                          ...selectedSubscription,
                          next_payment_datetime: new Date(e.target.value)
                            .toISOString()
                            .substring(0, 19)
                            .replace("T", " "),
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="modal-footer">
                <button
                  ref={modalCloseRef}
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    changeNextPayment();
                  }}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {pageNo > 0 && (
        <div
          className="modal fade"
          id="modalRefund"
          tabIndex="-1"
          aria-labelledby="modalRefundLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalRefundLabel">
                  Refund
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {selectedSubscription.email && (
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedSubscription.email}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="assistantCode">Plan</label>
                    <input
                      type="text"
                      className="form-control"
                      value={selectedSubscription.comments}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="assistantCode">Activate</label>
                    <select
                      className="form-select"
                      onChange={(e) =>
                        setSelectedSubscription({
                          ...selectedSubscription,
                          active_yn: e.target.value,
                        })
                      }
                    >
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="nextPayment">Active End Date</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={
                        // 2024-05-04 14:56:42 to 2024-05-04T14:56:42
                        $dateTimezone(
                          selectedSubscription.next_payment_datetime
                        ).replace(" ", "T")
                      }
                      onChange={(e) => {
                        setSelectedSubscription({
                          ...selectedSubscription,
                          active_end_datetime: new Date(e.target.value)
                            .toISOString()
                            .substring(0, 19)
                            .replace("T", " "),
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="modal-footer">
                <button
                  ref={modalCloseRef}
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    refundPayment();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

export default SubscriberList;
