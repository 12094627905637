function LatestUpdates() {
  return (
    <div class="container py-lg-7 py-5">
      <div class="row">
        <div class="col-lg-10 col-12">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="mb-5">
                <h1 class="mb-2">Latest Updates</h1>
                <p>
                  Stay up to date with all of the latest additions and
                  improvements we've made to WondersLab AI Assistants.
                </p>
              </div>
              <div class="docs-example row mt-7">
                <div class="col-lg-4 col-12">
                  <div id="initial">
                    <h4 class="mb-3 fw-semi-bold">
                      <code>May 16, 2024</code>
                    </h4>
                  </div>
                </div>
                <div class="col-md-8 col-12">
                  <div class="d-flex flex-column gap-2">
                    <div>
                      <span class="badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill me-2">
                        New
                      </span>
                      Wdot - YouTube Summary Feature
                      <div class="ms-7 mt-3">
                        <ul>
                          <li>Convert YouTube content to an article</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="docs-example row mt-7">
                <div class="col-lg-4 col-12">
                  <div id="initial">
                    <h4 class="mb-3 fw-semi-bold">
                      <code>May 13, 2024</code>
                    </h4>
                  </div>
                </div>
                <div class="col-md-8 col-12">
                  <div class="d-flex flex-column gap-2">
                    <div>
                      <span class="badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill me-2">
                        New
                      </span>
                      Wdot - Translation Feature
                      <div class="ms-7 mt-3">
                        <ul>
                          <li>Translate selected text to 9 languages</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="docs-example row mt-7">
                <div class="col-lg-4 col-12">
                  <div id="initial">
                    <h4 class="mb-3 fw-semi-bold">
                      <code>May 2, 2024</code>
                    </h4>
                  </div>
                </div>
                <div class="col-md-8 col-12">
                  <div class="d-flex flex-column gap-2">
                    <div>
                      <span class="badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill me-2">
                        New
                      </span>
                      Sdot - Image Generation
                      <div class="ms-7 mt-3">
                        <ul>
                          <li>Added Image Generation Feature</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="docs-example row mt-7">
                <div class="col-lg-4 col-12">
                  <div id="initial">
                    <h4 class="mb-3 fw-semi-bold">
                      <code>Apr 28, 2024</code>
                    </h4>
                  </div>
                </div>
                <div class="col-md-8 col-12">
                  <div class="d-flex flex-column gap-2">
                    <div>
                      <span class="badge bg-info-subtle border border-info-subtle text-info-emphasis rounded-pill me-2">
                        Updated
                      </span>
                      Sdot - Tone and Voice Updates
                      <div class="ms-7 mt-3">
                        <ul>
                          <li>Added 9 New Tone and Voice Options</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="docs-example row mt-7">
                <div class="col-lg-4 col-12">
                  <div id="initial">
                    <h4 class="mb-3 fw-semi-bold">
                      <code>Mar 29, 2024</code>
                    </h4>
                  </div>
                </div>
                <div class="col-md-8 col-12">
                  <div class="d-flex flex-column gap-2">
                    <div>
                      <span class="badge bg-info-subtle border border-info-subtle text-info-emphasis rounded-pill me-2">
                        Updated
                      </span>
                      Sdot - Updated Prompt and UI
                      <div class="ms-7 mt-3">
                        <ul>
                          <li>Today's Topics</li>
                          <li>Mobile UI</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="docs-example row mt-7">
                <div class="col-lg-4 col-12">
                  <div id="initial">
                    <h4 class="mb-3 fw-semi-bold">
                      <code>Mar 23, 2024</code>
                    </h4>
                  </div>
                </div>
                <div class="col-md-8 col-12">
                  <div class="d-flex flex-column gap-2">
                    <div>
                      <span class="badge bg-success-subtle border border-success-subtle text-success-emphasis rounded-pill me-2">
                        New
                      </span>
                      Idot - Added New Image Categories
                      <div class="ms-7 mt-3">
                        <ul>
                          <li>Interior</li>
                          <li>Exterior</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestUpdates;
