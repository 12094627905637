import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import AdminMenu from "../component/AdminMenu";

function PartnerList() {
  const navigate = useNavigate();
  const appUrl = process.env.REACT_APP_URL;

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [partnerList, setPartnerList] = useState([]);
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState({
    partner_name: "",
    partner_key: "",
    partner_type: "discount",
  });

  const location = useLocation();
  const scrollRef = useRef(null);
  const closeModalRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    const res = await $get(`/api/partner/${pageNo}/${pageLimit}`);
    // console.log(res);
    if (res.status === 200) {
      setPartnerList(res.data.users);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const insertPartner = async () => {
    Swal.fire({
      iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      title: "Insert Partner",
      text: "Do you want to insert this partner?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $post("/api/partner", partner);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "Insert Partner",
            text: "Partner has been inserted.",
            showConfirmButton: false,
            timer: 1500,
          });
          closeModalRef.current.click();
          setPartner({
            partner_name: "",
            partner_key: "",
            partner_type: "discount",
          });
          changePagination();
        } else {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "Insert Partner",
            text: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };

  const deletePartner = async (partner_id) => {
    Swal.fire({
      iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      title: "Delete Partner",
      text: "Do you want to delete this partner?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/partner/${partner_id}`);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "Delete Partner",
            text: "Partner has been deleted.",
            showConfirmButton: false,
            timer: 1500,
          });
          changePagination();
        } else {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "Delete Partner",
            text: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    if (userData && userData.role_level !== 0) {
      navigate("/401");
    }
  }, [userData]);

  useEffect(() => {
    // checkLogin();
    // getUserList();
  }, []);

  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AdminMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Partner List</h3>
                    <div className="table-responsive">
                      <table className="table table-hover table-striped table-bordered table-nowrap">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Partner Key</th>
                            <th>Type</th>
                            <th>Date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {partnerList &&
                            partnerList.map((partner, idx) => (
                              <tr key={`user-${idx}`}>
                                <td>{partner.partner_name}</td>
                                <td>{partner.partner_key}</td>
                                <td>{partner.partner_type}</td>
                                <td>
                                  {$dateTimezone(partner.create_datetime)}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-sm btn-danger ms-1"
                                    onClick={() => deletePartner(partner.id)}
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {totalPage > 1 && (
                        <div className="d-flex justify-content-center">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              {pageNo > 5 && (
                                <li className="page-item">
                                  <a
                                    className="page-link"
                                    onClick={(e) => setPageNo(pageNo - 1)}
                                  >
                                    <i className="fa-solid fa-arrow-left"></i>
                                  </a>
                                </li>
                              )}

                              {pageRange.map((page) => (
                                <li key={page}>
                                  <a
                                    className={
                                      pageNo === page
                                        ? "page-link active"
                                        : "page-link"
                                    }
                                    onClick={(e) => setPageNo(page)}
                                  >
                                    {page}
                                  </a>
                                </li>
                              ))}

                              {lastPage < totalPage && (
                                <li className="page-item">
                                  <a
                                    className="page-link"
                                    onClick={(e) => setPageNo(pageNo + 1)}
                                  >
                                    <i className="fa-solid fa-arrow-right"></i>
                                  </a>
                                </li>
                              )}
                            </ul>
                          </nav>
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#partnerModal"
                      >
                        Insert Partner
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="partnerModal"
        tabIndex="-1"
        aria-labelledby="partnerModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="partnerModalLabel">
                Insert Partner
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeModalRef}
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label htmlFor="parnerName" className="form-label">
                    Partner Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="parnerName"
                    value={partner.partner_name}
                    onChange={(e) =>
                      setPartner({ ...partner, partner_name: e.target.value })
                    }
                    aria-describedby="emailHelp"
                  />
                  <div id="partnerHelp" className="form-text">
                    Please enter the partner name.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="parnerName" className="form-label">
                    Partner Type
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) =>
                      setPartner({ ...partner, partner_type: e.target.value })
                    }
                  >
                    <option
                      value="discount"
                      selected={partner.partner_type === "discount"}
                    >
                      Discount
                    </option>
                    <option
                      value="sales"
                      selected={partner.partner_type === "sales"}
                    >
                      Sales
                    </option>
                  </select>
                </div>

                <button
                  type="button"
                  className="btn btn-primary"
                  // data-bs-dismiss="modal"
                  onClick={() => insertPartner()}
                >
                  Insert
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PartnerList;
