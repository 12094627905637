import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  $get,
  $post,
  $convertNumberFormat,
  $dateTimezone,
} from "../utils/common";
import AdminMenu from "../component/AdminMenu";

function Opportunity() {
  const [opportunity, setOpportunity] = useState([]);
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const changePagination = async () => {
    const res = await $get(
      `/api/subscription/opportunity/${pageNo}/${pageLimit}`
    );
    if (res.status === 200) {
      setOpportunity(res.data.opportunityList);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    }
  };

  //   const getOpportunity = async () => {
  //     const res = await $get("/api/subscription/opportunity");
  //     if (res.status === 200) {
  //       setOpportunity(res.data);
  //     }
  //   };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    // getOpportunity();
  }, []);
  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AdminMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Uncompleted Subscription Payments</h3>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th>Subscription Plan</th>
                            <th>Type</th>
                            <th>Currency</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {opportunity.map((item, index) => (
                            <tr key={index}>
                              <td>{item.email}</td>
                              <td>{item.assistant_title}</td>
                              <td>{item.subscribe_type}</td>
                              <td>{item.currency}</td>
                              <td>{item.create_datetime}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {totalPage > 1 && (
                        <div className="d-flex justify-content-center">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              {pageNo > 5 && (
                                <li className="page-item">
                                  <a
                                    className="page-link"
                                    onClick={(e) => setPageNo(pageNo - 1)}
                                  >
                                    <i className="fa-solid fa-arrow-left"></i>
                                  </a>
                                </li>
                              )}

                              {pageRange.map((page) => (
                                <li key={page}>
                                  <a
                                    className={
                                      pageNo === page
                                        ? "page-link active"
                                        : "page-link"
                                    }
                                    onClick={(e) => setPageNo(page)}
                                  >
                                    {page}
                                  </a>
                                </li>
                              ))}

                              {lastPage < totalPage && (
                                <li className="page-item">
                                  <a
                                    className="page-link"
                                    onClick={(e) => setPageNo(pageNo + 1)}
                                  >
                                    <i className="fa-solid fa-arrow-right"></i>
                                  </a>
                                </li>
                              )}
                            </ul>
                          </nav>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Opportunity;
