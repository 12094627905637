import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import {
  $post,
  $delete,
  $put,
  $upload,
  $get,
  $dateTimezone,
} from "../utils/common";
import Swal from "sweetalert2";
import AccountMenu from "../component/AccountMenu";

function AccountProfile() {
  const appUrl = process.env.REACT_APP_URL;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout, setUserData } = useAuth();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(userData.first_name);
  const [lastName, setLastName] = useState(userData.last_name);
  const [nickname, setNickname] = useState(userData.nickname);
  const [phone, setPhone] = useState(userData.phone);
  const [partnerKey, setPartnerKey] = useState(null);
  const [partnerKeyValid, setPartnerKeyValid] = useState(false);

  const fileRef = useRef();

  const uploadImage = async (file) => {
    const res = await $upload("/api/upload/image", file);
    if (res.status === 200) {
      const res2 = await $put(`/api/user`, {
        profile_image_url: `/static/images/${res.data.filename}`,
      });

      if (res2.status === 200) {
        setUserData({
          ...userData,
          profile_image_url: `/static/images/${res.data.filename}`,
        });
      }
    } else {
      Swal.fire({
        iconHtml: `<img src="./assets/images/Logo_brown.png" class="swal-custom-icon" />`,
        title: "이미지 업로드 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const saveProfile = async () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      nickname,
      phone,
    };
    const res = await $put("/api/user", data);
    if (res.status === 200) {
      if (res.data.status === "success") {
        setUserData({
          ...userData,
          first_name: firstName,
          last_name: lastName,
          nickname,
          phone,
        });
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: "Profile Updated",
          text: "Your profile has been updated successfully",
        });
      } else {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: "Profile Update Failed",
        });
      }
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Profile Update Failed",
        text: "Please try again",
      });
    }
  };

  const deleteAccount = async () => {
    Swal.fire({
      iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete("/api/user");
        if (res.status === 200) {
          if (res.data.status === "success") {
            Swal.fire({
              iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
              title: "Account Deleted",
              text: "Your account has been deleted successfully",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              logout();
              navigate("/login");
            }, 2000);
          } else {
            Swal.fire({
              iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
              title: "Account Delete Failed",
              text: "Please try again",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "Account Delete Failed",
            text: "Please try again",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const checkPartnerKey = async () => {
    if (partnerKey === null || partnerKey === "") {
      // Do you want to save empty partner key?
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Error",
        text: "Do you want to save empty partner key?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            partner_key: "",
          };
          const res = await $put("/api/user", data);
          if (res.status === 200) {
            if (res.data.status === "success") {
              setUserData({
                ...userData,
                partner_key: partnerKey,
              });
              Swal.fire({
                iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                title: "Success",
                text: "Partner key is saved.",
              });
            } else {
              Swal.fire({
                iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                title: "Error",
                text: "Partner key not saved.",
              });
            }
          }
        }
      });
    } else {
      const result = await $get("/api/partner/partner_key/" + partnerKey);
      if (result.data.length > 0) {
        setPartnerKeyValid(true);
        const data = {
          partner_key: partnerKey,
        };
        const res = await $put("/api/user", data);
        if (res.status === 200) {
          if (res.data.status === "success") {
            setUserData({
              ...userData,
              partner_key: partnerKey,
            });
            Swal.fire({
              iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
              title: "Success",
              text: "Partner key is valid.",
            });
          } else {
            Swal.fire({
              iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
              title: "Error",
              text: "Partner key not saved.",
            });
          }
        }
      } else {
        setPartnerKeyValid(false);
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: "Error",
          text: "Partner key is invalid.",
        });
      }
    }
  };

  useEffect(() => {
    setFirstName(userData.first_name || "");
    setLastName(userData.last_name || "");
    setNickname(userData.nickname || "");
    setPhone(userData.phone || "");
    setPartnerKey(userData.partner_key || "");
  }, [userData]);

  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AccountMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <h1 className="mb-0 h3">Profile</h1>
              </div>
              <div className="card border-0 shadow-sm mb-4">
                <div className="card-body p-lg-5">
                  <div className="mb-5">
                    <h4 className="mb-1">Profile Picture</h4>
                    <p className="mb-0 fs-6">
                      Upload a picture to make your profile stand out and let
                      people recognize your activities easily!
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        userData.profile_image_url &&
                        userData.profile_image_url.startsWith("/static/images")
                          ? `${serverUrl}${userData.profile_image_url}`
                          : userData.profile_image_url !== null &&
                            userData.profile_image_url !== ""
                          ? userData.profile_image_url
                          : "./assets/images/avatar/fallback.jpg"
                      }
                      alt="avatar"
                      className="avatar avatar-lg rounded-circle"
                      style={{ cursor: "pointer" }}
                      onClick={() => fileRef.current.click()}
                    />
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      ref={fileRef}
                      style={{ display: "none" }}
                      onChange={(e) => uploadImage(e.target.files[0])}
                    />
                    <div className="ms-4">
                      <h5 className="mb-0">Your photo</h5>
                      <small>
                        Allowed *.jpeg, *.jpg, *.png max size of 4 MB
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card border-0 shadow-sm mb-4">
                <div className="card-body p-lg-5">
                  <div className="mb-5">
                    <h4 className="mb-1">Account Information</h4>
                    <p className="mb-0 fs-6">Edit your personal information.</p>
                  </div>
                  <div className="row g-3 needs-validation" noValidate>
                    <div className="col-lg-6 col-md-12">
                      <label
                        htmlFor="profileFirstNameInput"
                        className="form-label"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="profileFirstNameInput"
                        name="first_name"
                        value={firstName || ""}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">Please enter name.</div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <label
                        htmlFor="profileLastNameInput"
                        className="form-label"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        id="profileLastNameInput"
                        value={lastName || ""}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">Please enter name.</div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <label
                        htmlFor="profileNicknameInput"
                        className="form-label"
                      >
                        Nickname
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="profileNicknameInput"
                        value={nickname || ""}
                        onChange={(e) => setNickname(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter nickname.
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="profilePhoneInput" className="form-label">
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control input-phone"
                        id="profilePhoneInput"
                        placeholder="+1 4XX XXX XXXX"
                        value={phone || ""}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter phone.
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <button
                        className="btn btn-primary me-2"
                        type="button"
                        onClick={(e) => saveProfile()}
                      >
                        Save Changes
                      </button>
                      {/* <button className="btn btn-light" type="submit">
                        Cancel
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card border-0 shadow-sm mb-4">
                <div className="card-body p-lg-5">
                  <div className="mb-5">
                    <h4 className="mb-1">Partner Information</h4>
                  </div>
                  <div className="row g-3 needs-validation" noValidate>
                    <div className="col-12">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Partner key"
                          aria-label="Partner key"
                          aria-describedby="button-addon2"
                          value={partnerKey}
                          onChange={(e) => setPartnerKey(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              checkPartnerKey();
                            }
                          }}
                        />
                        <button
                          className="btn btn-primary"
                          type="button"
                          id="button-addon2"
                          onClick={() => {
                            checkPartnerKey();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card border-danger bg-danger bg-opacity-10 mb-4 shadow-sm">
                <div className="card-body p-lg-5">
                  <div className="mb-4">
                    <h5 className="mb-0">Danger Zone</h5>
                    <small>Deleting your account will</small>
                  </div>
                  <ul className="list-unstyled mb-3">
                    <li className="mb-2">
                      1. Permanently delete your profile, along with your
                      authentication associations.
                    </li>
                    <li className="mb-2">
                      2. Permanently delete all your content. (Wdot, Idot, Sdot)
                    </li>
                  </ul>
                  <p className="mb-0">
                    Important: deleting your account is unrecoverable and cannot
                    be undone. Feel free to contact{" "}
                    <a href="mailto:wyou@wonderslab.kr" className="text-danger">
                      wyou@wonderslab.kr
                    </a>{" "}
                    with any questions.
                  </p>
                  <div className="mt-3">
                    <a
                      onClick={() => {
                        deleteAccount();
                      }}
                      className="btn btn-danger"
                    >
                      Delete Account
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AccountProfile;
