import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import AccountMenu from "../component/AccountMenu";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

function ServiceWdot() {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const lightbox = GLightbox({
      selector: ".glightbox",
    });

    return () => lightbox.destroy();
  }, []);

  return (
    <main>
      <div className="pattern-square"></div>
      <section className="py-5 py-lg-6">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-12">
              <div className="d-md-flex align-items-center justify-content-between mb-6">
                <div className="d-flex align-items-center mb-4 mb-md-0">
                  <div className="icon-shape icon-xxl border rounded p-4 bg-white">
                    <img
                      src="./assets/images/logo/idot-logo.png"
                      alt=""
                      style={{ height: "30px" }}
                    />
                  </div>
                  <div className="ms-3">
                    <h1 className="mb-0 h2 notranslate">Idot</h1>
                    <span className="small">
                      Image AI Assistant for your team
                    </span>
                  </div>
                </div>
                <div className="d-grid">
                  <a
                    href="https://idot.wonderslab.ai"
                    target="_blank"
                    className="btn btn-dark"
                  >
                    Go to Idot
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
              <div className="mb-md-7 mb-5 text-center">
                <img
                  src="./assets/images/service/idot-youtube.png"
                  alt="video"
                  class="img-fluid rounded-3"
                  width="100%"
                />
                <a
                  href="https://youtu.be/Mk3TjYFADu8?si=iL4ljbW6R2C_aO9w"
                  class="play-btn glightbox position-absolute top-50 start-50 translate-middle icon-shape icon-xl rounded-circle text-primary"
                  style={{ backgroundColor: "rgba(242, 41, 91, 0.2)" }}
                >
                  <i class="bi bi-play-fill"></i>
                </a>
              </div>
              <div className="mb-5 mb-md-4">
                <h2>Overview</h2>
                <p className="lead">
                  Idot is an AI assistant designed to simplify the process of
                  creating professional image prompts. Users only need to input
                  basic keywords, and Idot takes care of the rest, determining
                  the most suitable scenario, camera settings, lens, filters,
                  and view for those keywords. It facilitates the creation of
                  optimized prompts for around 50 types of specialized images,
                  including general photography, mockups, products, models,
                  interior photography, logo design, pattern design, and
                  illustrations.
                </p>
              </div>
              <div className="mb-5 mb-md-4">
                <h3>How it works</h3>
                <ol>
                  <li>
                    <b>Selecting Image Type</b>: Users begin by selecting the
                    type of image they desire. Options include general
                    photography, mockups, products, models, interior
                    photography, logo design, pattern design, illustrations, and
                    more.
                  </li>
                  <li>
                    <b>Choosing Image Size</b>: Based on the selected type,
                    users choose the size of the image, which varies depending
                    on the purpose and application of the image.
                  </li>
                  <li>
                    <b>Entering Key Keywords</b>: Users input key keywords that
                    reflect what they want in the image. These could encompass
                    the theme, colors, mood, etc., of the desired image.
                  </li>
                  <li>
                    <b>Prompt Generation and Selection</b>: Based on the
                    provided information, Idot generates three different
                    prompts. Users can then choose the prompt they find most
                    suitable for their needs.
                  </li>
                </ol>
                <p>
                  Through this process, Idot assists users in quickly and easily
                  creating the precise images they envision.
                </p>
                <p>
                  <a
                    href="https://wondersaiguideen.oopy.io/cc740a2e-90b8-4338-b6f3-5e5b4b6aa7be"
                    target="_blank"
                  >
                    <i className="fa-solid fa-arrow-right"></i> go to User Guide
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-xl-9 my-5">
        <div className="container">
          <div className="row gy-4 gx-4">
            <div className="col-md-12">
              <div className="mb-lg-4">
                <h2 className="mb-0">You might be interested in</h2>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <a
                onClick={() => navigate("/service-wdot")}
                className="card h-100 card-lift bg-gray-100"
              >
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-shape icon-lg border rounded p-3 bg-white">
                      <img
                        src="./assets/images/logo/wdot-logo.png"
                        style={{ height: "50px" }}
                        alt="integration"
                      />
                    </div>
                    <h4 className="mb-0 ms-3 notranslate">Wdot</h4>
                  </div>
                  <p className="mb-0">Document AI Assistant for your team.</p>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-3">
              <a
                onClick={() => navigate("/service-sdot")}
                className="card h-100 card-lift bg-gray-100"
              >
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-shape icon-lg border rounded p-3 bg-white">
                      <img
                        src="./assets/images/logo/sdot-logo.png"
                        style={{ height: "50px" }}
                        alt="integration"
                      />
                    </div>
                    <h4 className="mb-0 ms-3 notranslate">Sdot</h4>
                  </div>
                  <p className="mb-0">SNS AI Assistant for your team.</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ServiceWdot;
