import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import AdminMenu from "../component/AdminMenu";

function BillingList() {
  const navigate = useNavigate();
  const appUrl = process.env.REACT_APP_URL;

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [userList, setUserList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [billing, setBilling] = useState({
    email: "",
    assistant_code: "p1",
    comments: "",
    total_amount: 0,
    currency: "KRW",
    api_id: "manually",
    reference: "",
  });

  const location = useLocation();
  const scrollRef = useRef(null);
  const closeModalRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const getUserList = async () => {
    setLoading(true);

    const res = await $get(`/api/statistics/billing/${pageNo}/${pageLimit}`);

    // console.log(res);
    if (res.status === 200) {
      setUserList(res.data.users);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "사용자 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const changePagination = async () => {
    const res = await $get(`/api/statistics/billing/${pageNo}/${pageLimit}`);
    // console.log(res);
    if (res.status === 200) {
      setUserList(res.data.users);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "사용자 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  const insertBilling = async () => {
    if (!billing.email) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter the email address.",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    if (!billing.total_amount || billing.total_amount <= 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter the amount.",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to insert this billing?",
      iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, insert it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // close modal
        closeModalRef.current.click();
        const res = await $post("/api/billing", {
          ...billing,
          comments: `WondersLab AI Assistant - ${billing.assistant_code}`,
          api_date: new Date().toISOString(),
          submit_time_utc: new Date().toISOString(),
        });
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "Success",
            text: "Billing inserted successfully.",
            showConfirmButton: false,
            timer: 1000,
          });
          getUserList();
          setBilling({
            email: "",
            assistant_code: "p1",
            comments: "",
            total_amount: 0,
            currency: "KRW",
            api_id: "manually",
            reference: "",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  const deleteBilling = async (id) => {
    // Are you sure?
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this billing?",
      iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // close modal
        const res = await $delete(`/api/billing/${id}`);
        if (res.status === 200) {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "Success",
            text: "Billing deleted successfully.",
            showConfirmButton: false,
            timer: 1000,
          });
          getUserList();
        } else {
          Swal.fire({
            iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
            title: "Error",
            text: res.message,
            showConfirmButton: false,
            timer: 1000,
          });
        }
      }
    });
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    if (userData && userData.role_level !== 0) {
      navigate("/401");
    }
  }, [userData]);

  useEffect(() => {
    // checkLogin();
    // getUserList();
  }, []);

  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AdminMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Billing List</h3>
                    <div className="table-responsive">
                      <table className="table table-hover table-striped table-bordered table-nowrap">
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th>Subscription</th>
                            <th>Amount</th>
                            <th>Curr.</th>
                            <th>Date</th>
                            <th>Manually</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userList &&
                            userList.map((user, idx) => (
                              <tr key={`user-${idx}`}>
                                <td>{user.email}</td>
                                <td>
                                  {user.comments.replace(
                                    "WondersLab AI Assistant - ",
                                    ""
                                  )}
                                </td>
                                <td>
                                  {user.currency === "KRW"
                                    ? $convertNumberFormat(user.total_amount)
                                    : $convertNumberFormat(
                                        user.total_amount,
                                        "#,###.##"
                                      )}
                                </td>
                                <td>{user.currency}</td>
                                <td>{$dateTimezone(user.create_datetime)}</td>
                                <td>
                                  {user.api_id === "manually" && (
                                    <a
                                      onClick={() => {
                                        if (!user.reference) return;
                                        // Swal창으로 user.reference 정보 보여주기
                                        Swal.fire({
                                          title: "Reference",
                                          text: user.reference,
                                          // iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                                          showConfirmButton: false,
                                          showCloseButton: true,
                                        });
                                      }}
                                      className="btn btn-sm btn-primary"
                                    >
                                      Y
                                    </a>
                                  )}
                                  {user.api_id === "manually" && (
                                    <button
                                      className="btn btn-sm btn-danger ms-1"
                                      onClick={() => deleteBilling(user.id)}
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {totalPage > 1 && (
                        <div className="d-flex justify-content-center">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              {pageNo > 5 && (
                                <li className="page-item">
                                  <a
                                    className="page-link"
                                    onClick={(e) => setPageNo(pageNo - 1)}
                                  >
                                    <i className="fa-solid fa-arrow-left"></i>
                                  </a>
                                </li>
                              )}

                              {pageRange.map((page) => (
                                <li key={page}>
                                  <a
                                    className={
                                      pageNo === page
                                        ? "page-link active"
                                        : "page-link"
                                    }
                                    onClick={(e) => setPageNo(page)}
                                  >
                                    {page}
                                  </a>
                                </li>
                              ))}

                              {lastPage < totalPage && (
                                <li className="page-item">
                                  <a
                                    className="page-link"
                                    onClick={(e) => setPageNo(pageNo + 1)}
                                  >
                                    <i className="fa-solid fa-arrow-right"></i>
                                  </a>
                                </li>
                              )}
                            </ul>
                          </nav>
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#billingModal"
                      >
                        Insert Billing
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* modal - insert billing */}
      <div
        className="modal fade"
        id="billingModal"
        tabIndex="-1"
        aria-labelledby="billingModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="billingModalLabel">
                Insert Billing
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeModalRef}
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={billing.email}
                    onChange={(e) =>
                      setBilling({ ...billing, email: e.target.value })
                    }
                    aria-describedby="emailHelp"
                  />
                  <div id="emailHelp" className="form-text">
                    Please enter the email address of the user.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="subscription" className="form-label">
                    Subscription
                  </label>
                  <select
                    className="form-select"
                    id="subscription"
                    value={billing.assistant_code}
                    onChange={(e) =>
                      setBilling({ ...billing, assistant_code: e.target.value })
                    }
                  >
                    <option value="p1">Package</option>
                    <option value="wdot">Wdot</option>
                    <option value="idot">Idot</option>
                    <option value="sdot">Sdot</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="amount" className="form-label">
                    Amount
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="amount"
                    aria-describedby="amountHelp"
                    value={billing.total_amount}
                    onChange={(e) =>
                      setBilling({ ...billing, total_amount: e.target.value })
                    }
                  />
                  <div id="amountHelp" className="form-text">
                    Please enter the amount of the subscription.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="currency" className="form-label">
                    Currency
                  </label>
                  <select
                    className="form-select"
                    id="currency"
                    value={billing.currency}
                    onChange={(e) =>
                      setBilling({ ...billing, currency: e.target.value })
                    }
                  >
                    <option value="KRW">KRW</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="reference" className="form-label">
                    Reference
                  </label>
                  <textarea
                    className="form-control"
                    id="reference"
                    value={billing.reference}
                    onChange={(e) =>
                      setBilling({ ...billing, reference: e.target.value })
                    }
                    aria-describedby="referenceHelp"
                  ></textarea>
                  <div id="referenceHelp" className="form-text">
                    Please enter the reference of the subscription.
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  // data-bs-dismiss="modal"
                  onClick={() => insertBilling()}
                >
                  Insert
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default BillingList;
