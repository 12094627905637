import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { $get, $post, $convertNumberFormat } from "../utils/common";
import AdminMenu from "../component/AdminMenu";

function TokenCost() {
  const [tokenUsageByMonthWdot, setTokenUsageByMonthWdot] = useState([]);
  const [tokenUsageByMonthIdot, setTokenUsageByMonthIdot] = useState([]);
  const [tokenUsageByMonthSdot, setTokenUsageByMonthSdot] = useState([]);
  const getTokenUsageByMonth = async (assistant_code) => {
    try {
      const resWdot = await $get(`/api/token/month/wdot`);

      if (resWdot.status === 200) {
        setTokenUsageByMonthWdot(resWdot.data);
      }

      const resIdot = await $get(`/api/token/month/idot`);
      if (resIdot.status === 200) {
        setTokenUsageByMonthIdot(resIdot.data);
      }

      const resSdot = await $get(`/api/token/month/sdot`);
      if (resSdot.status === 200) {
        setTokenUsageByMonthSdot(resSdot.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getTokenUsageByMonth();
  }, []);
  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AdminMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Wdot</h3>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Year</th>
                            <th>Month</th>
                            <th>Prompt Tokens</th>
                            <th>Completion Tokens</th>
                            <th>API Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tokenUsageByMonthWdot.map((item, index) => (
                            <tr key={index}>
                              <td>{item.year}</td>
                              <td>{item.month}</td>
                              <td>
                                {$convertNumberFormat(item.total_prompt_tokens)}
                              </td>
                              <td>
                                {$convertNumberFormat(
                                  item.total_completion_tokens
                                )}
                              </td>
                              <td>
                                {item.year > 2024 ||
                                (item.year === 2024 && item.month > 4)
                                  ? `$${$convertNumberFormat(
                                      ((item.total_prompt_tokens * 5 +
                                        item.total_completion_tokens * 15) /
                                        1000000) *
                                        1.2,
                                      "#,###.##"
                                    )}`
                                  : `$${$convertNumberFormat(
                                      ((item.total_prompt_tokens * 10 +
                                        item.total_completion_tokens * 30) /
                                        1000000) *
                                        1.2,
                                      "#,###.##"
                                    )}`}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Idot</h3>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Year</th>
                            <th>Month</th>
                            <th>Prompt Tokens</th>
                            <th>Completion Tokens</th>
                            <th>API Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tokenUsageByMonthIdot.map((item, index) => (
                            <tr key={index}>
                              <td>{item.year}</td>
                              <td>{item.month}</td>
                              <td>
                                {$convertNumberFormat(item.total_prompt_tokens)}
                              </td>
                              <td>
                                {$convertNumberFormat(
                                  item.total_completion_tokens
                                )}
                              </td>
                              <td>
                                {/* $10 per 1M prompt tokens, $30 per 1M completion tokens */}
                                {item.year > 2024 ||
                                (item.year === 2024 && item.month > 4)
                                  ? `$${$convertNumberFormat(
                                      ((item.total_prompt_tokens * 5 +
                                        item.total_completion_tokens * 15) /
                                        1000000) *
                                        1.2,
                                      "#,###.##"
                                    )}`
                                  : `$${$convertNumberFormat(
                                      ((item.total_prompt_tokens * 10 +
                                        item.total_completion_tokens * 30) /
                                        1000000) *
                                        1.2,
                                      "#,###.##"
                                    )}`}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Sdot</h3>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th rowSpan={2}>Year</th>
                            <th rowSpan={2}>Month</th>
                            <th colSpan={3}>GPT</th>
                            <th colSpan={3}>CLAUDE</th>
                          </tr>
                          <tr>
                            <th>Prompt</th>
                            <th>Completion</th>
                            <th>API Cost</th>
                            <th>Prompt</th>
                            <th>Completion</th>
                            <th>API Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tokenUsageByMonthSdot.map((item, index) => (
                            <tr key={index}>
                              <td>{item.year}</td>
                              <td>{item.month}</td>
                              <td>
                                {$convertNumberFormat(item.total_prompt_tokens)}
                              </td>
                              <td>
                                {$convertNumberFormat(
                                  item.total_completion_tokens
                                )}
                              </td>
                              <td>
                                {item.year > 2024 ||
                                (item.year === 2024 && item.month > 4)
                                  ? `$${$convertNumberFormat(
                                      ((item.total_prompt_tokens * 5 +
                                        item.total_completion_tokens * 15) /
                                        1000000) *
                                        1.2,
                                      "#,###.##"
                                    )}`
                                  : `$${$convertNumberFormat(
                                      ((item.total_prompt_tokens * 10 +
                                        item.total_completion_tokens * 30) /
                                        1000000) *
                                        1.2,
                                      "#,###.##"
                                    )}`}
                              </td>
                              <td>
                                {$convertNumberFormat(
                                  item.claude_total_prompt_tokens
                                )}
                              </td>
                              <td>
                                {$convertNumberFormat(
                                  item.claude_total_completion_tokens
                                )}
                              </td>
                              <td>
                                {`$${$convertNumberFormat(
                                  ((item.claude_total_prompt_tokens * 3 +
                                    item.claude_total_completion_tokens * 15) /
                                    1000000) *
                                    1.2,
                                  "#,###.##"
                                )}`}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    // <main>
    //   <div className="pattern-square"></div>
    //   <h2 className="text-center py-5 py-lg-8">Token Usage & Cost by Month</h2>
    //   <section className="mb-xl-9">
    //     <div className="container">
    //       <h3 className="">Wdot</h3>
    //       <div className="table-responsive">
    //         <table className="table table-bordered table-striped">
    //           <thead>
    //             <tr>
    //               <th>Year</th>
    //               <th>Month</th>
    //               <th>Prompt Tokens</th>
    //               <th>Completion Tokens</th>
    //               <th>API Cost</th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {tokenUsageByMonthWdot.map((item, index) => (
    //               <tr key={index}>
    //                 <td>{item.year}</td>
    //                 <td>{item.month}</td>
    //                 <td>{$convertNumberFormat(item.total_prompt_tokens)}</td>
    //                 <td>
    //                   {$convertNumberFormat(item.total_completion_tokens)}
    //                 </td>
    //                 <td>
    //                   {/* $10 per 1M prompt tokens, $30 per 1M completion tokens */}
    //                   {`$${$convertNumberFormat(
    //                     ((item.total_prompt_tokens * 10 +
    //                       item.total_completion_tokens * 30) /
    //                       1000000) *
    //                       1.2,
    //                     "#,###.##"
    //                   )}`}
    //                 </td>
    //               </tr>
    //             ))}
    //           </tbody>
    //         </table>
    //       </div>
    //     </div>
    //   </section>
    //   <section className="mb-xl-9">
    //     <div className="container">
    //       <h3 className="">Idot</h3>
    //       <div className="table-responsive">
    //         <table className="table table-bordered table-striped">
    //           <thead>
    //             <tr>
    //               <th>Year</th>
    //               <th>Month</th>
    //               <th>Prompt Tokens</th>
    //               <th>Completion Tokens</th>
    //               <th>API Cost</th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {tokenUsageByMonthIdot.map((item, index) => (
    //               <tr key={index}>
    //                 <td>{item.year}</td>
    //                 <td>{item.month}</td>
    //                 <td>{$convertNumberFormat(item.total_prompt_tokens)}</td>
    //                 <td>
    //                   {$convertNumberFormat(item.total_completion_tokens)}
    //                 </td>
    //                 <td>
    //                   {/* $10 per 1M prompt tokens, $30 per 1M completion tokens */}
    //                   {`$${$convertNumberFormat(
    //                     ((item.total_prompt_tokens * 10 +
    //                       item.total_completion_tokens * 30) /
    //                       1000000) *
    //                       1.2,
    //                     "#,###.##"
    //                   )}`}
    //                 </td>
    //               </tr>
    //             ))}
    //           </tbody>
    //         </table>
    //       </div>
    //     </div>
    //   </section>
    //   <section className="mb-xl-9">
    //     <div className="container">
    //       <h3 className="">Sdot</h3>
    //       <div className="table-responsive">
    //         <table className="table table-bordered table-striped">
    //           <thead>
    //             <tr>
    //               <th>Year</th>
    //               <th>Month</th>
    //               <th>Prompt Tokens</th>
    //               <th>Completion Tokens</th>
    //               <th>API Cost</th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {tokenUsageByMonthSdot.map((item, index) => (
    //               <tr key={index}>
    //                 <td>{item.year}</td>
    //                 <td>{item.month}</td>
    //                 <td>{$convertNumberFormat(item.total_prompt_tokens)}</td>
    //                 <td>
    //                   {$convertNumberFormat(item.total_completion_tokens)}
    //                 </td>
    //                 <td>
    //                   {`$${$convertNumberFormat(
    //                     ((item.total_prompt_tokens * 10 +
    //                       item.total_completion_tokens * 30) /
    //                       1000000) *
    //                       1.2,
    //                     "#,###.##"
    //                   )}`}
    //                 </td>
    //               </tr>
    //             ))}
    //           </tbody>
    //         </table>
    //       </div>
    //     </div>
    //   </section>
    // </main>
  );
}

export default TokenCost;
