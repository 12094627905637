import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { $get, $put } from "../utils/common";
import Swal from "sweetalert2";
import { BeatLoader } from "react-spinners";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";

function EmailVerification() {
  const appUrl = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { email, ticket } = useParams();
  const { isLoggedIn, userData, login, logout } = useAuth();

  const verifyEmail = async () => {
    const res = await $get(`/api/user/verify-email/${email}/${ticket}`);
    if (res.status === 200) {
      if (res.data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Email Verified",
          text: "Your email has been verified",
        });

        navigate("/signin");
      } else {
        Swal.fire({
          icon: "error",
          title: "Email Verification Failed",
          text: res.data.message,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Email Verification Failed",
        text: "Please try again",
      });
    }
  };

  const sendVerifyEmail = async () => {
    const res = await $put(`/api/user/send-verify-email`, {
      email,
    });
    if (res.status === 200) {
      if (res.data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Verification Email Sent",
          text: "Verification email has been sent to your email address",
        });

        navigate("/signin");
      } else {
        Swal.fire({
          icon: "error",
          title: "Email Verification Failed",
          text: res.data.message,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Email Verification Failed",
        text: "Please try again",
      });
    }
  };

  useEffect(() => {
    verifyEmail();
  }, [ticket]);

  return (
    <main className="position-relative">
      <div className="pattern-square"></div>
      <section className="py-5 py-lg-8">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 offset-xl-4 col-md-12 col-12">
              <div className="text-center">
                <a href="index.html">
                  <img
                    src="./assets/images/logo/wdot-logo.png"
                    style={{ height: "80px" }}
                    alt="brand"
                    className="mb-3"
                  />
                </a>
                <h1 className="mb-1">Email Verification</h1>
                <p className="mb-0">
                  Please wait while we verify your email address
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center mb-6">
            <div className="col-xl-5 col-lg-6 col-md-8 col-12">
              <div className="card shadow-sm mb-3">
                <div className="card-body">
                  <div className="needs-validation mb-6" noValidate>
                    <div className="mb-3">
                      <label htmlFor="signupEmailInput" className="form-label">
                        Email
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="signupEmailInput"
                        value={email}
                        disabled
                      />
                      <div className="invalid-feedback">
                        Please enter email.
                      </div>
                    </div>
                    <div className="d-grid">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => sendVerifyEmail()}
                      >
                        Send Verification Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default EmailVerification;
