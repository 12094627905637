import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import AccountMenu from "../component/AccountMenu";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";

function AccountBilling() {
  const appUrl = process.env.REACT_APP_URL;
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const [billingList, setBillingList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);

  const getBillingList = async () => {
    const res = await $get("/api/billing");
    if (res.data) {
      setBillingList(res.data);
    }
  };

  const getSubscriptionList = async () => {
    const res = await $get("/api/subscription");
    if (res.data) {
      setSubscriptionList(res.data);
    }
  };

  const cancelPlan = async (assistantCode, nextPaymentDate) => {
    Swal.fire({
      title: "Are you sure to cancel subscription?",
      text: `Your subscription will be active until ${nextPaymentDate}.`,
      iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      showCancelButton: true,
      confirmButtonText: "Yes, cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/subscription/cancel/${assistantCode}`);
        if (res.data) {
          Swal.fire({
            title: "Cancelled!",
            text: `Your subscription has been cancelled. Your subscription will be active until ${nextPaymentDate}.`,
            iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          });
          getSubscriptionList();
        }
      }
    });
  };

  const detectCardType = (cardNumber) => {
    const visaPattern = /^4/;
    const masterCardPattern = /^(5[1-5]|2[2-7][0-2][0-9])/;

    if (visaPattern.test(cardNumber)) {
      return "Visa";
    } else if (masterCardPattern.test(cardNumber)) {
      return "Mastercard";
    } else {
      return "Unknown";
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      getSubscriptionList();
    }
  }, [isLoggedIn]);

  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AccountMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <h1 className="mb-0 h3">Billing</h1>
                <p>
                  Please refer to the{" "}
                  <a
                    href="https://wonderslab.notion.site/Refund-Policy-081c23f58fe34015997f870f1085fe23"
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    link
                  </a>{" "}
                  for our refund policy regarding subscription cancellations. If
                  you want to refund your subscription, please contact us at{" "}
                  <a href="mailto:wyou@wonderslab.kr">wyou@wonderslab.kr</a>.
                  Refunds are only possible within 24 hours after payment.
                </p>
              </div>

              {subscriptionList.map((item, index) => (
                <div className="card border-0 mb-4 shadow-sm">
                  <div className="card-body p-lg-5">
                    <div className="mb-6 d-lg-flex align-items-center justify-content-between">
                      <h4 className="mb-0 text-capitalize">{item.comments}</h4>
                      <div className="mt-3 mt-lg-0 d-md-flex">
                        <a
                          onClick={() =>
                            cancelPlan(
                              item.assistant_code,
                              item.next_payment_datetime.substring(0, 10)
                            )
                          }
                          className="btn btn-outline-danger me-2"
                        >
                          Cancel plan
                        </a>
                      </div>
                    </div>
                    <div className="row gx-4">
                      <div className="col-lg-6">
                        <div className="card border-0 mb-4 mb-lg-0 bg-light-subtle">
                          <div className="card-body py-lg-3 px-lg-4">
                            <div className="mb-5">
                              <h6>Current monthly bill</h6>
                              {item.currency === "USD" ? (
                                <h4>${item.billing_amount}</h4>
                              ) : (
                                <h4>
                                  {$convertNumberFormat(item.billing_amount)}원
                                </h4>
                              )}
                            </div>

                            {/* <a
                              href="#"
                              className="icon-link icon-link-hover text-primary"
                            >
                              Switch to{" "}
                              {item.subscription_type === "Y"
                                ? "monthly"
                                : "yearly"}{" "}
                              billing
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="currentColor"
                                className="bi bi-arrow-right"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                ></path>
                              </svg>
                            </a> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="card border-0 mb-4 mb-lg-0 bg-light-subtle">
                          <div className="card-body py-lg-3 px-lg-4">
                            <div className="mb-5">
                              <h6>Next payment due</h6>
                              <h4>
                                {item.next_payment_datetime.substring(0, 10)}
                              </h4>
                            </div>

                            {/* <a
                              href="#"
                              className="icon-link icon-link-hover text-primary"
                            >
                              View payment history
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="currentColor"
                                className="bi bi-arrow-right"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                ></path>
                              </svg>
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="paymentModal"
        tabindex="-1"
        aria-labelledby="paymentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center d-flex">
              <h4 className="modal-title" id="paymentModalLabel">
                Add New Payment Method
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row mb-4 needs-validation" noValidate>
                <div className="mb-3 col-12 col-md-12 mb-4">
                  <h5 className="mb-3">Credit / Debit card</h5>
                  <div className="d-inline-flex">
                    <div className="form-check me-2">
                      <input
                        type="radio"
                        id="paymentRadioOne"
                        name="paymentRadioOne"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="paymentRadioOne"
                      >
                        <img
                          src="./assets/images/landings/account/americanexpress.svg"
                          alt="card"
                        />
                      </label>
                    </div>
                    <div className="form-check me-2">
                      <input
                        type="radio"
                        id="paymentRadioTwo"
                        name="paymentRadioOne"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="paymentRadioTwo"
                      >
                        <img
                          src="./assets/images/landings/account/mastercard.svg"
                          alt="card"
                        />
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        type="radio"
                        id="paymentRadioFour"
                        name="paymentRadioOne"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="paymentRadioFour"
                      >
                        <img
                          src="./assets/images/landings/account/visa.svg"
                          alt="card"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-3 col-12 col-md-4">
                  <label htmlFor="nameoncard" className="form-label">
                    Name on card
                  </label>
                  <input
                    id="nameoncard"
                    type="text"
                    className="form-control"
                    name="nameoncard"
                    placeholder="Name"
                    required=""
                  />
                </div>

                <div className="mb-3 col-12 col-md-4">
                  <label className="form-label" htmlFor="month">
                    Month
                  </label>
                  <select className="form-select" id="month" required>
                    <option selected disabled value="">
                      Choose...
                    </option>
                    <option value="Month">Month</option>
                    <option value="Jan">Jan</option>
                    <option value="Feb">Feb</option>
                    <option value="Mar">Mar</option>
                    <option value="Apr">Apr</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="Aug">Aug</option>
                    <option value="Sep">Sep</option>
                    <option value="Oct">Oct</option>
                    <option value="Nov">Nov</option>
                    <option value="Dec">Dec</option>
                  </select>
                </div>

                <div className="mb-3 col-12 col-md-4">
                  <label className="form-label" htmlFor="year">
                    Year
                  </label>
                  <select className="form-select" id="year" required>
                    <option selected disabled value="">
                      Choose...
                    </option>
                    <option value="Year">Year</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                  </select>
                </div>

                <div className="mb-3 col-md-8 col-12">
                  <label htmlFor="cc-mask" className="form-label">
                    Card Number
                  </label>
                  <input
                    type="text"
                    className="form-control input-credit-card"
                    id="cc-mask"
                    placeholder="xxxx-xxxx-xxxx-xxxx"
                    required=""
                  />
                </div>
                <div className="mb-3 col-md-4 col-12">
                  <div className="mb-3">
                    <label htmlFor="cvv" className="form-label">
                      CVV Code
                      <i
                        className="bi bi-question-circle ms-1"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                        aria-label="A 3 - digit number, typically printed on the back of a card."
                        data-bs-original-title="A 3 - digit number, typically printed on the back of a card."
                      ></i>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="cvv"
                      id="cvv"
                      placeholder="xxx"
                      maxlength="3"
                      inputmode="numeric"
                      required=""
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <button className="btn btn-primary" type="submit">
                    Add New Card
                  </button>
                  <button
                    className="btn btn-light"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
              <span>
                <span className="fw-bold">Note:</span>
                that you can later remove your card at the account setting page.
              </span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AccountBilling;
