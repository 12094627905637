import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import AccountMenu from "../component/AccountMenu";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

function ServiceWdot() {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const lightbox = GLightbox({
      selector: ".glightbox",
    });

    return () => lightbox.destroy();
  }, []);
  return (
    <main>
      <div className="pattern-square"></div>
      <section className="py-5 py-lg-6">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-12">
              <div className="d-md-flex align-items-center justify-content-between mb-6">
                <div className="d-flex align-items-center mb-4 mb-md-0">
                  <div className="icon-shape icon-xxl border rounded p-4 bg-white">
                    <img
                      src="./assets/images/logo/wdot-logo.png"
                      alt=""
                      style={{ height: "30px" }}
                    />
                  </div>
                  <div className="ms-3">
                    <h1 className="mb-0 h2 notranslate">Wdot</h1>
                    <span className="small">
                      Document AI Assistant for your team
                    </span>
                  </div>
                </div>
                <div className="d-grid">
                  <a
                    href="https://wdot.wonderslab.ai"
                    target="_blank"
                    className="btn btn-dark"
                  >
                    Go to Wdot
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
              <div className="mb-md-7 mb-5 text-center">
                <img
                  src="./assets/images/service/wdot-youtube.png"
                  alt="video"
                  class="img-fluid rounded-3"
                  width="100%"
                />
                <a
                  href="https://www.youtube.com/watch?v=ubx4ixcTYpg"
                  class="play-btn glightbox position-absolute top-50 start-50 translate-middle icon-shape icon-xl rounded-circle text-primary"
                  style={{ backgroundColor: "rgba(242, 41, 91, 0.2)" }}
                >
                  <i class="bi bi-play-fill"></i>
                </a>
              </div>
              <div className="mb-5 mb-md-4">
                <h2>Overview</h2>
                <p className="lead">
                  Wdot is an AI assistant tailored for professional document
                  creation. It assists in drafting business plans, proposals,
                  market analysis reports, educational materials, and more. By
                  simply inputting basic information, Wdot can automatically
                  generate outlines, suggest titles, continue writing sections,
                  elaborate on or simplify content, create lists, and visualize
                  data in tables. It also features research capabilities, link
                  analysis, and file analysis to solve bottlenecks in document
                  creation, significantly reducing the time spent on such tasks.
                </p>
                <h3>Problems with existing services</h3>
                <ul>
                  <li>
                    Previously, the process of collecting and organizing data
                    through search engines and AI tools (e.g., Google Gemini,
                    Microsoft Bing) was inefficient.
                  </li>
                  <li>Documentation is scattered and time-consuming.</li>
                </ul>
                <h3>Benefits of Wdot</h3>
                <ul>
                  <li>
                    Automatically generates outlines, suggests titles, and
                    continues writing sections.
                  </li>
                  <li>
                    Elaborates on or simplifies content, creates lists, and
                    visualizes data in tables.
                  </li>
                  <li>
                    Features research capabilities, link analysis, and file
                    analysis to solve bottlenecks in document creation.
                  </li>
                </ul>
              </div>
              <div className="mb-5 mb-md-4">
                <h3>How it works</h3>
                <p>
                  Users input basic information into Wdot, which then identifies
                  the required type of document and automatically generates a
                  relevant outline. If users need more detailed information on a
                  specific part of the document, Wdot can expand that section
                  with comprehensive details. Conversely, when a concise version
                  is needed, it employs its summarization feature to distill the
                  essence. Additionally, Wdot conducts online research to
                  provide necessary data and statistics for the document, and
                  analyzes related links and files for supplementary
                  information.
                </p>
                <p>
                  <a
                    href="https://wondersaiguideen.oopy.io/57f2ca60-d272-4d2c-81fd-0868fa931981"
                    target="_blank"
                  >
                    <i className="fa-solid fa-arrow-right"></i> go to User Guide
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-xl-9 my-5">
        <div className="container">
          <div className="row gy-4 gx-4">
            <div className="col-md-12">
              <div className="mb-lg-4">
                <h2 className="mb-0">You might be interested in</h2>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <a
                onClick={() => navigate("/service-idot")}
                className="card h-100 card-lift bg-gray-100"
              >
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-shape icon-lg border rounded p-3 bg-white">
                      <img
                        src="./assets/images/logo/idot-logo.png"
                        style={{ height: "50px" }}
                        alt="integration"
                      />
                    </div>
                    <h4 className="mb-0 ms-3 notranslate">Idot</h4>
                  </div>
                  <p className="mb-0">Image AI Assistant for your team.</p>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-3">
              <a
                onClick={() => navigate("/service-sdot")}
                className="card h-100 card-lift bg-gray-100"
              >
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-shape icon-lg border rounded p-3 bg-white">
                      <img
                        src="./assets/images/logo/sdot-logo.png"
                        style={{ height: "50px" }}
                        alt="integration"
                      />
                    </div>
                    <h4 className="mb-0 ms-3 notranslate">Sdot</h4>
                  </div>
                  <p className="mb-0">SNS AI Assistant for your team.</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ServiceWdot;
