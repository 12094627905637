import "./App.css";
import React, { useState } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import AccountProfile from "./pages/AccountProfile";
import AccountSecurity from "./pages/AccountSecurity";
import AccountBilling from "./pages/AccountBilling";
import AccountTeam from "./pages/AccountTeam";
import AccountSession from "./pages/AccountSession";
import AccountAssistant from "./pages/AccountAssistant";
import AccountSubscription from "./pages/AccountSubscription";
import AccountSubscriptionPartner from "./pages/AccountSubscriptionPartner";
import ServiceWdot from "./pages/ServiceWdot";
import ServiceIdot from "./pages/ServiceIdot";
import ServiceSdot from "./pages/ServiceSdot";
import Contact from "./pages/Contact";
import EmailVerification from "./pages/EmailVerification";
import EventList from "./pages/EventList";
import Event from "./pages/Event";
import Pricing from "./pages/Pricing";
import LatestUpdates from "./pages/LatestUpdates";
import UserList from "./pages/UserList";
import SubscriberList from "./pages/SubscriberList";
import NewsletterList from "./pages/NewsletterList";
import PartnerList from "./pages/PartnerList";
import TokenCost from "./pages/TokenCost";
import Opportunity from "./pages/Opportunity";
import UserStatistics from "./pages/UserStatistics";
import SubscriptionStatistics from "./pages/SubscriptionStatistics";
import WdotStatistics from "./pages/WdotStatistics";
import IdotStatistics from "./pages/IdotStatistics";
import SdotStatistics from "./pages/SdotStatistics";
import BillingList from "./pages/BillingList";
import PageNotFound from "./pages/404";
import NotAuthorized from "./pages/401";
import InternalServerError from "./pages/500";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./login/AuthProvider";

import "./locales/i18n";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/account-assistant" element={<AccountAssistant />} />
            <Route path="/account-profile" element={<AccountProfile />} />
            <Route path="/account-security" element={<AccountSecurity />} />
            <Route path="/account-billing" element={<AccountBilling />} />
            <Route path="/account-team" element={<AccountTeam />} />
            <Route path="/account-session" element={<AccountSession />} />
            <Route
              path="/account-subscription"
              element={<AccountSubscription />}
            />
            <Route
              path="/account-subscription-partner"
              element={<AccountSubscriptionPartner />}
            />
            <Route path="/service-wdot" element={<ServiceWdot />} />
            <Route path="/service-idot" element={<ServiceIdot />} />
            <Route path="/service-sdot" element={<ServiceSdot />} />
            <Route path="/eventlist" element={<EventList />} />
            <Route path="/event/:eventId" element={<Event />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/email-verification/:email/:ticket"
              element={<EmailVerification />}
            />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/latest-updates" element={<LatestUpdates />} />
            <Route path="/admin-tokencost" element={<TokenCost />} />
            <Route path="/admin-opportunity" element={<Opportunity />} />
            <Route path="/admin-user-statistics" element={<UserStatistics />} />
            <Route path="/admin-userlist" element={<UserList />} />
            <Route path="/admin-subscriberlist" element={<SubscriberList />} />
            <Route
              path="/admin-subscription-statistics"
              element={<SubscriptionStatistics />}
            />
            <Route path="/admin-wdot-statistics" element={<WdotStatistics />} />
            <Route path="/admin-idot-statistics" element={<IdotStatistics />} />
            <Route path="/admin-sdot-statistics" element={<SdotStatistics />} />
            <Route path="/admin-newsletterlist" element={<NewsletterList />} />
            <Route path="/admin-billinglist" element={<BillingList />} />
            <Route path="/admin-partnerlist" element={<PartnerList />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/401" element={<NotAuthorized />} />
            <Route path="/500" element={<InternalServerError />} />
          </Routes>
          <Footer />
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
