// AuthProvider.js
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "./AuthContext";
import { $get } from "../utils/common";
import { useTranslation } from "react-i18next";

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});

  const login = (data) => {
    setIsLoggedIn(true);
    setUserData(data);
    i18n.changeLanguage(data.lang);
  };

  const logout = async () => {
    setIsLoggedIn(false);
    setUserData(null);
    const res = (await $get("/api/logout")).data;
    if (res.success) {
      navigate("/");
      // refresh
      window.location.reload();
    }
  };

  const checkLogin = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      const user = await $get(`/api/user/${res.email}`);
      login(user.data);
      // if (user.data.active_yn === "N") {
      //   navigate("/waiting-list");
      // }
    } else {
      if (location.pathname.startsWith("/account")) {
        setIsLoggedIn(false);
        setUserData(null);
        navigate("/");
      }
    }
  };

  useEffect(() => {
    // if (location.pathname.startsWith("/account")) {
    checkLogin();
    // }
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith("/account")) {
      checkLogin();
    } else if (location.pathname.startsWith("/admin")) {
      checkLogin();
      if (userData.role_level !== 0) {
        navigate("/");
      }
    }
  }, [location]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        userData,
        setUserData,
        login,
        logout,
        checkLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
