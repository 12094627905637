import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
import Swal from "sweetalert2";
import AccountMenu from "../component/AccountMenu";

function Contact() {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [inquiry, setInquiry] = useState("");
  const [message, setMessage] = useState("");

  const sendInquiry = async () => {
    if (name === "" || email === "" || inquiry === "" || message === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill out all required fields.",
        showCancelButton: false,
        timer: 1500,
      });

      return;
    }

    const res = await $post("/api/contact/send", {
      name,
      email,
      phone,
      inquiry,
      message,
    });

    if (res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your inquiry has been sent.",
        showCancelButton: false,
        timer: 1500,
      });
      setName("");
      setEmail("");
      setPhone("");
      setInquiry("");
      setMessage("");
    }
  };
  return (
    <main>
      <div className="pattern-square"></div>
      <section className="bg-info bg-opacity-10 pt-xl-9 pb-xl-0 py-5">
        <div className="container">
          <div className="row g-xl-7 gy-5">
            <div className="col-lg-6 col-12">
              <div className="me-xl-7">
                <div className="mb-5">
                  <h1 className="mb-3">Contact our sales team</h1>
                  <p>
                    Connect with us today to learn more about how Wonderslab AI
                    assistant can help you grow your business.
                  </p>
                </div>
                <div className="mb-5">
                  <h4>AI Assistant Implementation Inquiry</h4>
                  <ul>
                    <li>
                      Inquiry Details: Interested in implementing AI assistants
                      like Wdot, Idot, Sdot into your business? If you want to
                      learn more about how to integrate AI assistants into your
                      operations, please feel free to reach out.
                    </li>
                    <li>
                      How to Inquire: Fill out the contact form below or send us
                      an email directly at [email address]. We will respond as
                      soon as possible.
                    </li>
                  </ul>
                  <h4>Sales Partner Registration Inquiry</h4>
                  <ul>
                    <li>
                      Inquiry Details: Interested in selling our products? If
                      you want to know more about the opportunities for growth
                      as a sales partner, please contact us at the information
                      below.
                    </li>
                    <li>
                      How to Inquire: Inform us of your interest in becoming a
                      sales partner by emailing [email address], or use the form
                      below to send us your inquiry. We will get back to you
                      promptly.
                    </li>
                  </ul>
                  <h4>Trial Usage Inquiry</h4>
                  <ul>
                    <li>
                      Inquiry Details: Interested in a free trial of Wdot, Idot,
                      Sdot? If you'd like to experience the features and
                      benefits of our products firsthand, apply for a free
                      trial.
                    </li>
                    <li>
                      How to Inquire: Fill out the contact form below or send us
                      an email at [email address] expressing your interest in a
                      free trial. We will provide you with detailed information
                      and instructions on how to access the trial.
                    </li>
                  </ul>

                  <p className="mb-0">
                    Our sales team is always striving to provide customized
                    solutions tailored to your business needs. Feel free to
                    reach out with any questions or inquiries.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 mb-lg-n9">
              <div className="card shadow-sm">
                <div className="card-body">
                  <div className="row g-3 needs-validation" noValidate>
                    <div className="col-md-12">
                      <label
                        htmlFor="contactFirstNameInput"
                        className="form-label"
                      >
                        Name
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="contactFirstNameInput"
                        value={name || ""}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">Please enter name.</div>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="contactEmailInput" className="form-label">
                        Email
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="contactEmailInput"
                        value={email || ""}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter email.
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="contactPhoneInput" className="form-label">
                        Phone
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="contactPhoneInput"
                        value={phone || ""}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter phone.
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="contactInquiry" className="form-label">
                        Inquiry
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        id="contactInquiry"
                        value={inquiry}
                        onChange={(e) => setInquiry(e.target.value)}
                      >
                        <option selected disabled value="">
                          Choose...
                        </option>
                        <option value="AI Assistant Implementation Inquiry">
                          AI Assistant Implementation Inquiry
                        </option>
                        <option value="Sales Partner Registration Inquiry">
                          Sales Partner Registration Inquiry
                        </option>
                        <option value="Trial Usage Inquiry">
                          Trial Usage Inquiry
                        </option>
                        <option value="Trial Usage Inquiry">
                          General Inquiry
                        </option>
                      </select>
                      <div className="invalid-feedback">
                        Please enter company name.
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="contactTextarea" className="form-label">
                        Message
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="contactTextarea"
                        rows="4"
                        value={message || ""}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Write to us"
                        required
                      ></textarea>
                      <div className="invalid-feedback">
                        Please enter a message.
                      </div>
                    </div>
                    <div className="d-grid">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => sendInquiry()}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="py-lg-10 py-5">
        <div className="container">
          <div className="row g-xl-7">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="text-center text-md-start mb-5">
                <h2 className="mb-0">Community Resources</h2>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="text-center text-md-start p-4 p-md-0">
                <div className="mb-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-chat-dots text-dark"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                  </svg>
                </div>
                <div className="mb-4">
                  <h4>Chat Dashboard</h4>
                  <p className="mb-0 pe-lg-3">
                    Log in to the Chat Dashboard to get assistance
                  </p>
                </div>

                <a href="#!" className="icon-link icon-link-hover">
                  Start Chat
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="text-center text-md-start p-4 p-md-0">
                <div className="mb-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-slack text-dark"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.362 10.11c0 .926-.756 1.681-1.681 1.681S0 11.036 0 10.111C0 9.186.756 8.43 1.68 8.43h1.682v1.68zm.846 0c0-.924.756-1.68 1.681-1.68s1.681.756 1.681 1.68v4.21c0 .924-.756 1.68-1.68 1.68a1.685 1.685 0 0 1-1.682-1.68v-4.21zM5.89 3.362c-.926 0-1.682-.756-1.682-1.681S4.964 0 5.89 0s1.68.756 1.68 1.68v1.682H5.89zm0 .846c.924 0 1.68.756 1.68 1.681S6.814 7.57 5.89 7.57H1.68C.757 7.57 0 6.814 0 5.89c0-.926.756-1.682 1.68-1.682h4.21zm6.749 1.682c0-.926.755-1.682 1.68-1.682.925 0 1.681.756 1.681 1.681s-.756 1.681-1.68 1.681h-1.681V5.89zm-.848 0c0 .924-.755 1.68-1.68 1.68A1.685 1.685 0 0 1 8.43 5.89V1.68C8.43.757 9.186 0 10.11 0c.926 0 1.681.756 1.681 1.68v4.21zm-1.681 6.748c.926 0 1.682.756 1.682 1.681S11.036 16 10.11 16s-1.681-.756-1.681-1.68v-1.682h1.68zm0-.847c-.924 0-1.68-.755-1.68-1.68 0-.925.756-1.681 1.68-1.681h4.21c.924 0 1.68.756 1.68 1.68 0 .926-.756 1.681-1.68 1.681h-4.21z" />
                  </svg>
                </div>
                <div className="mb-4">
                  <h4>Slack</h4>
                  <p className="mb-0 pe-lg-3">
                    Join our Codescandy Community Slack channel
                  </p>
                </div>

                <a href="#!" className="icon-link icon-link-hover">
                  Join Slack
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="text-center text-md-start p-4 p-md-0">
                <div className="mb-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-github text-dark"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                  </svg>
                </div>
                <div className="mb-4">
                  <h4>Github</h4>
                  <p className="mb-0">Ask questions in Github Discussions</p>
                </div>

                <a href="#!" className="icon-link icon-link-hover">
                  Discussion
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
}

export default Contact;
