import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import AccountMenu from "../component/AccountMenu";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

function ServiceWdot() {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const lightbox = GLightbox({
      selector: ".glightbox",
    });

    return () => lightbox.destroy();
  }, []);

  return (
    <main>
      <div className="pattern-square"></div>
      <section className="py-5 py-lg-6">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-12">
              <div className="d-md-flex align-items-center justify-content-between mb-6">
                <div className="d-flex align-items-center mb-4 mb-md-0">
                  <div className="icon-shape icon-xxl border rounded p-4 bg-white">
                    <img
                      src="./assets/images/logo/sdot-logo.png"
                      alt=""
                      style={{ height: "30px" }}
                    />
                  </div>
                  <div className="ms-3">
                    <h1 className="mb-0 h2 notranslate">Sdot</h1>
                    <span className="small">
                      SNS AI Assistant for your team
                    </span>
                  </div>
                </div>
                <div className="d-grid">
                  <a
                    href="https://sdot.wonderslab.ai"
                    target="_blank"
                    className="btn btn-dark"
                  >
                    Go to Sdot
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
              <div className="mb-md-7 mb-5 text-center">
                <img
                  src="./assets/images/service/sdot-youtube.png"
                  alt="video"
                  class="img-fluid rounded-3"
                  width="100%"
                />
                <a
                  href="https://youtu.be/LTVBMbDbl18?si=vr3Ak1z0egG4KxL6"
                  class="play-btn glightbox position-absolute top-50 start-50 translate-middle icon-shape icon-xl rounded-circle text-primary"
                  style={{ backgroundColor: "rgba(242, 41, 91, 0.2)" }}
                >
                  <i class="bi bi-play-fill"></i>
                </a>
              </div>
              <div className="mb-5 mb-md-4">
                <h2>Overview</h2>
                <p className="lead">
                  Sdot is an AI assistant for social media channels, optimized
                  for creating brand-specific content for businesses and
                  individuals on platforms like Instagram, Facebook, and
                  LinkedIn. It proposes five main feed topics daily based on
                  brand information and provides additional detailed subtopics
                  for the chosen main topic. Sdot generates feeds tailored to
                  each social media platform's style and allows users to further
                  customize the feeds with additional features like 'Expand
                  Writing', 'Shorten Writing', and 'Translate into Another
                  Language'.
                </p>
              </div>
              <div className="mb-5 mb-md-4">
                <h3>How it works</h3>
                <ol>
                  <li>
                    <b>Brand Information Analysis</b>: Sdot begins by analyzing
                    the initial data based on the user's brand information.
                  </li>
                  <li>
                    <b>Main Feed Topic Suggestions</b>: It suggests five main
                    feed topics daily to the user.
                  </li>
                  <li>
                    <b>Selecting Detailed Feed Topics</b>: Once a user selects a
                    main topic, Sdot provides five detailed subtopics related to
                    it.
                  </li>
                  <li>
                    <b>Automatic Feed Creation</b>: Based on the chosen detailed
                    topic, feeds are automatically generated, taking into
                    account the tone and manner appropriate for each social
                    media channel like Instagram, Facebook, and LinkedIn.
                  </li>
                  <li>
                    <b>Feed Modification Options</b>: Users can modify the
                    generated feeds with options like 'Write More', 'Write
                    Less', or 'Translate into Another Language'.
                  </li>
                  <li>
                    <b>User-Input Topics</b>: Users have the option to input
                    their own topics for creating feeds.
                  </li>
                </ol>
                <p>
                  <a
                    href="https://wondersaiguideen.oopy.io/3e41302a-b5fd-4c0c-8a9b-5abacfabb038"
                    target="_blank"
                  >
                    <i className="fa-solid fa-arrow-right"></i> go to User Guide
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-xl-9 my-5">
        <div className="container">
          <div className="row gy-4 gx-4">
            <div className="col-md-12">
              <div className="mb-lg-4">
                <h2 className="mb-0">You might be interested in</h2>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <a
                onClick={() => navigate("/service-wdot")}
                className="card h-100 card-lift bg-gray-100"
              >
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-shape icon-lg border rounded p-3 bg-white">
                      <img
                        src="./assets/images/logo/wdot-logo.png"
                        style={{ height: "50px" }}
                        alt="integration"
                      />
                    </div>
                    <h4 className="mb-0 ms-3 notranslate">Wdot</h4>
                  </div>
                  <p className="mb-0">Document AI Assistant for your team.</p>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-3">
              <a
                onClick={() => navigate("/service-idot")}
                className="card h-100 card-lift bg-gray-100"
              >
                <div className="card-body">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-shape icon-lg border rounded p-3 bg-white">
                      <img
                        src="./assets/images/logo/idot-logo.png"
                        style={{ height: "50px" }}
                        alt="integration"
                      />
                    </div>
                    <h4 className="mb-0 ms-3 notranslate">Idot</h4>
                  </div>
                  <p className="mb-0">Image AI Assistant for your team.</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ServiceWdot;
