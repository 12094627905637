import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import AdminMenu from "../component/AdminMenu";

function NewsletterList() {
  const navigate = useNavigate();
  const appUrl = process.env.REACT_APP_URL;

  const { isLoggedIn, userData, checkLogin } = useAuth();

  const [userList, setUserList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setPageLimit] = useState(15);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageRange, setPageRange] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const scrollRef = useRef(null);

  const paging = () => {
    const pages = [];

    const pageFirst =
      pageNo % 5 === 0 ? pageNo - 4 : Math.floor(pageNo / 5) * 5 + 1;
    let pageLast = pageFirst + 4;

    if (pageLast > totalPage) pageLast = totalPage;
    setLastPage(pageLast);

    for (let i = pageFirst; i <= pageLast; i++) {
      pages.push(i);
    }
    setPageRange(pages);
  };

  const getUserList = async () => {
    setLoading(true);

    const res = await $get(`/api/user/newsletter/${pageNo}/${pageLimit}`);

    // console.log(res);
    if (res.status === 200) {
      setUserList(res.data.users);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "사용자 조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  const changePagination = async () => {
    const res = await $get(`/api/user/newsletter/${pageNo}/${pageLimit}`);
    // console.log(res);
    if (res.status === 200) {
      setUserList(res.data.users);
      setTotalPage(Math.ceil(res.data.total_count / pageLimit));
      paging();
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "조회 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    paging();
  }, [totalPage]);

  useEffect(() => {
    changePagination(pageNo);
  }, [pageNo]);

  useEffect(() => {
    if (userData && userData.role_level !== 0) {
      navigate("/401");
    }
  }, [userData]);

  useEffect(() => {
    // checkLogin();
    // getUserList();
  }, []);

  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AdminMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <section className="mb-xl-9">
                  <div className="container">
                    <h3 className="">Newsletter Subscribe List</h3>
                    <div className="table-responsive">
                      <table className="table table-hover table-striped table-bordered table-nowrap">
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th>Lang</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userList &&
                            userList.map((user, idx) => (
                              <tr key={`user-${idx}`}>
                                <td>{user.email}</td>
                                <td>{user.lang}</td>
                                <td>{$dateTimezone(user.create_datetime)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {totalPage > 1 && (
                        <div className="d-flex justify-content-center">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              {pageNo > 5 && (
                                <li className="page-item">
                                  <a
                                    className="page-link"
                                    onClick={(e) => setPageNo(pageNo - 1)}
                                  >
                                    <i className="fa-solid fa-arrow-left"></i>
                                  </a>
                                </li>
                              )}

                              {pageRange.map((page) => (
                                <li key={page}>
                                  <a
                                    className={
                                      pageNo === page
                                        ? "page-link active"
                                        : "page-link"
                                    }
                                    onClick={(e) => setPageNo(page)}
                                  >
                                    {page}
                                  </a>
                                </li>
                              ))}

                              {lastPage < totalPage && (
                                <li className="page-item">
                                  <a
                                    className="page-link"
                                    onClick={(e) => setPageNo(pageNo + 1)}
                                  >
                                    <i className="fa-solid fa-arrow-right"></i>
                                  </a>
                                </li>
                              )}
                            </ul>
                          </nav>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default NewsletterList;
