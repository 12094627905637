import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
import Swal from "sweetalert2";
import AccountMenu from "../component/AccountMenu";

function AccountSecurity() {
  const appUrl = process.env.REACT_APP_URL;
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const changePassword = async () => {
    if (!password) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Old Password Required",
        text: "Please enter old password",
      });
      return;
    }

    if (newPassword.length < 8) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Password Length",
        text: "Password must be at least 8 characters",
      });
      return;
    }

    // Make sure it's at least 8 characters including one number and one uppercase letter.
    if (!newPassword.match(/^(?=.*[A-Z])(?=.*\d).{8,}$/)) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Password Format",
        text: "Password must contain at least one uppercase letter and one number",
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Password Mismatch",
        text: "Password and Confirm Password do not match",
      });
      return;
    }

    const data = {
      password,
      newPassword,
    };
    const res = await $put("/api/user/change-password", data);
    if (res.status === 200) {
      if (res.data.status === "fail") {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: res.data.message,
        });
      } else {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: "Change Password Success",
          text: "Your password has been changed",
        });
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/logo/wdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "Change Password Failed",
        text: "Please try again",
      });
    }
  };
  return (
    <main>
      <section className="py-lg-7 py-5 bg-light-subtle">
        <div className="container">
          <div className="row">
            <AccountMenu />
            <div className="col-lg-9 col-md-8">
              <div className="mb-4">
                <h1 className="mb-0 h3">Security</h1>
              </div>
              <div className="card border-0 mb-4 shadow-sm">
                <div className="card-body p-lg-5">
                  <div className="mb-5">
                    <h4 className="mb-1">Change Password</h4>
                    <p className="mb-0 fs-6">
                      We will email you a confirmation when changing your
                      password, so please expect that email after submitting.
                    </p>
                  </div>
                  <div className="row gy-3 needs-validation" noValidate>
                    <div className="col-lg-7">
                      <label
                        htmlFor="securityOldPasswordInput"
                        className="form-label"
                      >
                        Old Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="securityOldPasswordInput"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter old password.
                      </div>
                    </div>

                    <div className="col-lg-7">
                      <label
                        htmlFor="securityNewPasswordInput"
                        className="form-label"
                      >
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="securityNewPasswordInput"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter new password.
                      </div>
                      <div className="form-text">
                        Make sure it's at least 8 characters including a number
                        and a uppercase letter.
                      </div>
                    </div>

                    <div className="col-lg-7">
                      <label
                        htmlFor="securityConfirmPasswordInput"
                        className="form-label"
                      >
                        Confirm New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="securityConfirmPasswordInput"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter confirm password.
                      </div>
                      <div className="form-text">
                        Make sure match with above new password
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary me-2"
                        type="button"
                        onClick={() => changePassword()}
                      >
                        Save Changes
                      </button>
                      {/* <button className="btn btn-light" type="submit">
                        Cancel
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card border-danger bg-danger bg-opacity-10 mb-4 shadow-sm">
                <div className="card-body p-lg-5">
                  <div className="mb-4">
                    <h5 className="mb-0">Danger Zone</h5>
                    <small>Deleting your account will</small>
                  </div>
                  <ul className="list-unstyled mb-3">
                    <li className="mb-2">
                      1. Permanently delete your profile, along with your
                      authentication associations.
                    </li>
                    <li className="mb-2">
                      2. Permanently delete all your content.
                    </li>
                  </ul>
                  <p className="mb-0">
                    Important: deleting your account is unrecoverable and cannot
                    be undone. Feel free to contact{" "}
                    <a href="#" className="text-danger">
                      support@wonderslab.ai
                    </a>{" "}
                    with any questions.
                  </p>
                  <div className="mt-3">
                    <a href="#" className="btn btn-danger">
                      Delete Account
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AccountSecurity;
