import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { $get, $post } from "../utils/common";
import Swal from "sweetalert2";
import { BeatLoader } from "react-spinners";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";

function Event() {
  const appUrl = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { eventId } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");

  const sendInquiry = async () => {
    if (
      name === "" ||
      email === "" ||
      company === "" ||
      location === "" ||
      message === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill out all required fields.",
        showCancelButton: false,
        timer: 1500,
      });

      return;
    }

    const res = await $post("/api/event/send", {
      name,
      email,
      phone,
      company,
      location,
      message,
    });

    if (res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your inquiry has been sent.",
        showCancelButton: false,
        timer: 1500,
      });
      setName("");
      setEmail("");
      setPhone("");
      setCompany("");
      setLocation("");
      setMessage("");
    }
  };

  useEffect(() => {
    if (!eventId || eventId !== "1") {
      navigate("/eventlist");
    }
  }, [eventId]);

  return (
    <main style={{ display: eventId && eventId === "1" ? "" : "block" }}>
      <div className="pattern-square"></div>
      <section className="py-5 py-lg-8">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <a
                onClick={() => navigate("/eventlist")}
                className="text-reset icon-link icon-link-hover"
              >
                <i className="fa-solid fa-arrow-left"></i>
                back to events
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-xl-9">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-6">
                <h1 className="mb-0">
                  Generative AI and Prompt Engineering in the Real Estate
                  Industry
                </h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-7 col-md-12">
              <figure>
                <img
                  src={`${appUrl}/assets/images/event/event-nibc.jpg`}
                  className="img-fluid rounded-3 shadow-sm"
                  alt="events"
                />
              </figure>
            </div>
            <div className="col-lg-4 offset-lg-1 col-md-12">
              <div className="d-flex pt-4 pt-lg-0 pb-5">
                <div className="icon-md icon-shape rounded-circle bg-body-tertiary bg-opacity-50 border mb-3 flex-shrink-0">
                  <i className="fa-solid fa-location-dot"></i>
                </div>

                <div className="ms-3">
                  <h5>Location</h5>
                  <p className="mb-0">
                    13-15A Nguyen Thi Huynh, Ward 8, Phu Nhuan District, Ho Chi
                    Minh City, Vietnam
                  </p>
                </div>
              </div>

              <div className="d-flex border-1 border-top-dashed border-secondary border-opacity-25 py-5">
                <div className="icon-md icon-shape rounded-circle bg-body-tertiary bg-opacity-50 border mb-3 flex-shrink-0">
                  <i className="fa-solid fa-calendar-days"></i>
                </div>
                <div className="ms-3">
                  <h5>Date and time</h5>
                  <p className="mb-0">
                    Mar 18 ~ Mar 22, 2024, 4:00 PM - 7:00 PM
                  </p>
                </div>
              </div>
              {/* <div className="d-flex border-1 border-top-dashed border-secondary border-opacity-25 py-5">
                <div className="icon-md icon-shape rounded-circle bg-body-tertiary bg-opacity-50 border mb-3 flex-shrink-0">
                  <i className="fa-solid fa-ticket"></i>
                </div>
                <div className="ms-3">
                  <h5>Admission</h5>
                  <p className="mb-0">
                    $79 per person Registration opens May 17, 2024
                  </p>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-12 order-2">
              <div className="mb-6 mt-4">
                <h2 className="mb-4">About</h2>
                <p className="mb-3">
                  Wonderslab, in collaboration with the Vietnam NIBC Group, is
                  conducting a 4-day workshop on 'Generative AI and Prompt
                  Engineering in Real Estate'. The workshop aims to delve into
                  how AI technology can be innovatively utilized in the real
                  estate sector, offering in-depth analysis and practical
                  application methods.
                </p>
                <p className="mb-0">
                  The Vietnam NIBC Group, established in 2009, has grown into a
                  leading consulting group in Asia, providing services in
                  various fields including real estate development, design,
                  sales, and marketing. This workshop represents a synergy of
                  NIBC Group's domain expertise and Wonderslab's proficiency in
                  AI technology, promising to offer participants new
                  perspectives and solutions.
                </p>
              </div>

              <h3 className="mb-4">Event Schedule</h3>
              <ul className="list-group list-group-flush mb-0">
                <li className="list-group-item pb-4 px-0">
                  <h5>
                    <span>Day 1</span>
                    <span className="ms-3">
                      Introduction to Generative AI and Prompt Engineering
                    </span>
                  </h5>
                  <p className="mb-0">
                    Introducing the basics of Generative AI, followed by an
                    exploration of prompt engineering, and concludes with
                    strategies for effectively using ChatGPT prompts. This
                    session sets the foundational understanding necessary for
                    more advanced topics in the following days.
                  </p>
                </li>
                <li className="list-group-item py-4 px-0">
                  <h5>
                    <span>Day 2</span>
                    <span className="ms-3">
                      Exploring AI in Art and Photography
                    </span>
                  </h5>
                  <p className="mb-0">
                    Diving deeper into the world of AI, specifically focusing on
                    image generation. It starts with the basics of MidJourney
                    prompts for image creation, then moves into the application
                    of this technology in the field of photography. Participants
                    will learn about various aspects such as photo themes and
                    styles, choosing the right time of day, camera selection,
                    and lighting. The day progresses to cover illustrations,
                    painter styles, and various drawing styles, broadening the
                    scope of creative AI use.
                  </p>
                </li>
                <li className="list-group-item py-4 px-0">
                  <h5>
                    <span>Day 3</span>
                    <span className="ms-3">Creative Writing with ChatGPT</span>
                  </h5>
                  <p className="mb-0">
                    The third day is dedicated to writing with ChatGPT,
                    encompassing a wide array of applications. Over three hours,
                    participants will engage with creating content for blogs,
                    focusing on company branding and promotion, and crafting
                    Instagram feeds for advertising purposes. The session also
                    covers the development of business plans, specifically for a
                    project on constructing townhouse complexes for Cambodian
                    residents. The day extends into the realm of creative
                    writing, including short form scenarios for advertisements,
                    drama scripts with time-based elements, children's books,
                    general book writing, and an introduction to MyGPTs.
                  </p>
                </li>
                <li className="list-group-item py-4 border-bottom px-0">
                  <h5>
                    <span>Day 4</span>
                    <span className="ms-3">
                      AI in Real Estate and Monetization Strategies
                    </span>
                  </h5>
                  <p className="mb-0">
                    The final day wraps up the workshop with a focus on applying
                    AI in the real estate sector. This three-hour session
                    demonstrates how to use MidJourney in various aspects of
                    real estate, such as concept drawing, creating images for
                    interior and exterior designs, and landscaping. The day also
                    delves into the monetization aspect, exploring avenues like
                    creating children's books, pattern design, and coloring
                    books. The workshop concludes with an introduction to the
                    Video Generation AI - SORA, and a presentation of the
                    Wonderslab AI Assistant.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 offset-lg-1 col-md-12 order-lg-2">
              <div className="card shadow-sm">
                <div className="card-body">
                  <div className="mb-6 text-center">
                    <h3 className="mb-0">
                      Request for our next live group workshop
                    </h3>
                  </div>
                  <div className="row g-3 needs-validation" noValidate="">
                    <div className="col-md-12">
                      <label
                        htmlFor="eventSingleNameInput"
                        className="form-label"
                      >
                        Name
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="eventSingleNameInput"
                        value={name || ""}
                        onChange={(e) => setName(e.target.value)}
                        required=""
                      />
                      <div className="invalid-feedback">Please enter name.</div>
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="eventSingleEmailInput"
                        className="form-label"
                      >
                        Email
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="eventSingleEmailInput"
                        value={email || ""}
                        onChange={(e) => setEmail(e.target.value)}
                        required=""
                      />
                      <div className="invalid-feedback">
                        Please enter email.
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label
                        htmlFor="eventSinglePhoneInput"
                        className="form-label"
                      >
                        Phone Number
                        <span className="text-body">(optional)</span>
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="eventSinglePhoneInput"
                        value={phone || ""}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        Please enter phone.
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="eventCompanyInput" className="form-label">
                        Company
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="eventCompanyInput"
                        value={company || ""}
                        onChange={(e) => setCompany(e.target.value)}
                        required=""
                      />
                      <div className="invalid-feedback">
                        Please enter company.
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="eventCompanyInput" className="form-label">
                        Location
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="eventLocationInput"
                        value={location || ""}
                        onChange={(e) => setLocation(e.target.value)}
                        required=""
                      />
                      <div className="invalid-feedback">
                        Please enter location.
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="eventTextarea" className="form-label">
                        Message
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="eventTextarea"
                        rows="4"
                        value={message || ""}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Write to us"
                        required
                      ></textarea>
                      <div className="invalid-feedback">
                        Please enter a message.
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="blockCheck"
                          required
                        />
                        <label
                          className="form-check-label ms-2 fs-6"
                          htmlFor="blockCheck"
                        >
                          By continuing, you agree to Black
                          <a href="#">Terms of Use</a>
                          Read our
                          <a href="#">Privacy Policy</a>
                        </label>
                        <div className="invalid-feedback">
                          You must agree before submitting.
                        </div>
                      </div>
                    </div> */}
                    <div className="d-grid">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => sendInquiry()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="my-xl-9 my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="mb-4">Webinar Video on-demand</h3>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <div className="card border-0 shadow-sm h-100 card-lift">
                <figure>
                  <a href="./event-single.html">
                    <img
                      src="./assets/images/event/event-img-2.jpg"
                      alt="event"
                      className="card-img-top"
                    />
                  </a>
                </figure>

                <div className="card-body h-100 d-flex align-items-start flex-column border rounded-bottom-3 border-top-0">
                  <div className="mb-5">
                    <small className="text-uppercase fw-semibold ls-md">
                      Webinar
                    </small>
                    <h4 className="my-2">
                      <a href="./event-single.html" className="text-reset">
                        How to Build a blog with Astro and contentful
                      </a>
                    </h4>
                    <small>June 22, 2024</small>
                  </div>
                  <div className="d-flex justify-content-between w-100 mt-auto">
                    <small>9:00AM EDT</small>
                    <small>Germany</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card border-0 shadow-sm h-100 card-lift">
                <figure>
                  <a href="./event-single.html">
                    <img
                      src="./assets/images/event/event-img-5.jpg"
                      alt="event"
                      className="card-img-top"
                    />
                  </a>
                </figure>

                <div className="card-body h-100 d-flex align-items-start flex-column border rounded-bottom-3 border-top-0">
                  <div className="mb-5">
                    <small className="text-uppercase fw-semibold ls-md">
                      Webinar
                    </small>
                    <h4 className="my-2">
                      <a href="./event-single.html" className="text-reset">
                        Astro components & the basic building blocks
                      </a>
                    </h4>
                    <small>June 22, 2024</small>
                  </div>
                  <div className="d-flex justify-content-between w-100 mt-auto">
                    <small>9:00AM EDT</small>
                    <small>Germany</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card border-0 shadow-sm h-100 card-lift">
                <figure>
                  <a href="./event-single.html">
                    <img
                      src="./assets/images/event/event-img-4.jpg"
                      alt="event"
                      className="card-img-top"
                    />
                  </a>
                </figure>

                <div className="card-body h-100 d-flex align-items-start flex-column border rounded-bottom-3 border-top-0">
                  <div className="mb-5">
                    <small className="text-uppercase fw-semibold ls-md">
                      Webinar
                    </small>
                    <h4 className="my-2">
                      <a href="./event-single.html" className="text-reset">
                        Using Contentful CMS with Next.js
                      </a>
                    </h4>
                    <small>June 22, 2024</small>
                  </div>
                  <div className="d-flex justify-content-between w-100 mt-auto">
                    <small>9:00AM EDT</small>
                    <small>Germany</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
}

export default Event;
