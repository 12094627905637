import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

function AdminMenu() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="col-lg-3 col-md-4">
      <div className="d-flex align-items-center mb-4 justify-content-center justify-content-md-start">
        <img
          src={
            userData &&
            userData.profile_image_url &&
            userData.profile_image_url.startsWith("/static/images")
              ? `${serverUrl}${userData.profile_image_url}`
              : userData.profile_image_url !== null &&
                userData.profile_image_url !== ""
              ? userData.profile_image_url
              : "./assets/images/avatar/fallback.jpg"
          }
          alt="avatar"
          className="avatar avatar-lg rounded-circle"
        />
        <div className="ms-3">
          <h5 className="mb-0">
            {userData.nickname ? userData.nickname : userData.email}
          </h5>
          <small>
            {userData.role_level === 0 ? "Admin" : "Personal"} account
          </small>
          {/* <small>Personal account</small> */}
        </div>
      </div>
      <div className="d-md-none text-center d-grid">
        <button
          className="btn btn-light mb-3 d-flex align-items-center justify-content-between"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseAccountMenu"
          aria-expanded="false"
          aria-controls="collapseAccountMenu"
        >
          Admin Menu
          <i className="bi bi-chevron-down ms-2"></i>
        </button>
      </div>
      <div className="collapse d-md-block" id="collapseAccountMenu">
        <ul className="nav flex-column nav-account">
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-tokencost"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-tokencost")}
            >
              <i className="align-bottom bx bx-message-dots"></i>
              <span className="ms-2">Token Usage & Cost</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-opportunity"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-opportunity")}
            >
              <i className="align-bottom bx bx-wallet"></i>
              <span className="ms-2">Uncompleted Subscription</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-user-statistics"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-user-statistics")}
            >
              <i className="align-bottom bx bx-user"></i>
              <span className="ms-2">User Statistics</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-subscription-statistics"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-subscription-statistics")}
            >
              <i className="align-bottom bx bx-dollar"></i>
              <span className="ms-2">Subscription Statistics</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-subscriberlist"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-subscriberlist")}
            >
              <i className="align-bottom bx bxs-user-account"></i>
              <span className="ms-2">Subscriber List</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-billinglist"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-billinglist")}
            >
              <i className="align-bottom bx bxs-user-account"></i>
              <span className="ms-2">Billing List</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-userlist"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-userlist")}
            >
              <i className="align-bottom bx bxs-user-account"></i>
              <span className="ms-2">User List</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-partnerlist"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-partnerlist")}
            >
              <i className="align-bottom bx bxs-user-account"></i>
              <span className="ms-2">Partner List</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-wdot-statistics"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-wdot-statistics")}
            >
              <i className="align-bottom bx bxs-file-blank"></i>
              <span className="ms-2">Wdot Statistics</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-idot-statistics"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-idot-statistics")}
            >
              <i className="align-bottom bx bxs-file-image"></i>
              <span className="ms-2">Idot Statistics</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-sdot-statistics"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-sdot-statistics")}
            >
              <i className="align-bottom bx bxl-instagram"></i>
              <span className="ms-2">Sdot Statistics</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                location.pathname === "/admin-newsletterlist"
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={() => navigate("/admin-newsletterlist")}
            >
              <i className="align-bottom bx bxl-instagram"></i>
              <span className="ms-2">Newsletter List</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AdminMenu;
